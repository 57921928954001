import { CurrencyConfigs, CurrencyWithSymbols } from '../const'

import currency from 'currency.js'
import { ICurrency } from '../types/rrule'

export interface FormatCurrencyProps {
  symbol?: string
  separator?: string
  precision?: number
}

const defaultFormatCurrencyProps: FormatCurrencyProps = {
  symbol: '$',
  separator: ',',
  precision: 2,
}
export const formatCurrencyWithProps = (
  amount: number,
  config: FormatCurrencyProps = {}
) => {
  return currency(amount, { ...defaultFormatCurrencyProps, ...config }).format()
}

/**
 * Returns a utility object for working with currencies.
 * @param currency - The currency code.
 * @param currencyDetails - Optional object containing currency details.
 * @returns An object with utility functions for working with currencies.
 */
export const getCurrencyUtil = (
  currency: string,
  currencyDetails?: { [key: string]: ICurrency }
) => {
  /**
   * Returns the currency code.
   * @returns The currency code.
   */
  const getCurrency = () => currency

  /**
   * Returns the currency symbol for the given currency code.
   * @param currencyCode - Optional currency code. If not provided, the current currency code will be used.
   * @returns The currency symbol.
   */
  const getCurrencySymbol = (currencyCode?: string) => {
    const code = currencyCode || getCurrency()
    if (currencyDetails && currencyDetails?.[code?.toUpperCase()]) {
      return currencyDetails?.[code.toUpperCase()]?.symbol
    }
    if (CurrencyWithSymbols.includes(code)) {
      return CurrencyConfigs[code].symbol
    }
    return CurrencyConfigs[code]?.code
  }

  /**
   * Formats the given amount with the currency symbol.
   * @param amount - The amount to format.
   * @param currencySymbol - Optional currency symbol. If not provided, the symbol for the current currency code will be used.
   * @returns The formatted currency string.
   */
  const formatCurrency = (amount: number, currencySymbol?: string) => {
    return formatCurrencyWithProps(amount, {
      symbol: currencySymbol || getCurrencySymbol(getCurrency()),
    })
  }

  return {
    getCurrency,
    formatCurrency,
    getCurrencySymbol,
  }
}
