export function calculatePercentage(
  total: number,
  percentage: number,
  options?: {
    floor: boolean
  }
): number {
  if (total === 0) {
    throw new Error('Total cannot be zero.')
  }
  const result = (percentage / 100) * total
  if (options?.floor) {
    return Math.floor(result)
  }
  return result
}
