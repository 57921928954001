<script lang="ts" setup>
import { recipientThumbnail, formatLastViewedTime } from '../../utils'
import { UIAvatar, UITag } from '@gohighlevel/ghl-ui'
import { PropType } from 'vue'
import { IRecipient, RecipientRolesEnum } from '../../types'
import { UITextXsRegular, UIPopover } from '@gohighlevel/ghl-ui'
import { getColorFromString } from '@gohighlevel/ghl-ui'
import { useTypography } from '../../composition'
import i18n from '../../locales'

const { toCapitalize } = useTypography()

const props = defineProps({
  recipient: { type: Object as PropType<IRecipient> },
  showRecipientTag: { type: Boolean, default: true },
  userId: { type: String },
  routerInstance: { type: Object },
  locationId: { type: String },
  showViewed: { type: Boolean, default: false },
  viewedTime: { type: String, default: '' },
})

const onClickCustomer = () => {
  if (props.recipient?.id) {
    const contactId = props.recipient?.id
    if (props?.routerInstance) {
      const redirectRoute = redirectParentRoute('contact_detail-v2', {
        locationId: props.locationId as string,
        contactId: contactId,
      })
      const routeUrl = props.routerInstance.resolve(redirectRoute)
      window.open(routeUrl.href, '_blank')
    }
  }
}

const redirectParentRoute = (pathName: string, params: any) => {
  try {
    const parentRoutes = [
      {
        path: '/v2/location/:locationId/contacts/detail/:contactId',
        name: 'contact_detail-v2',
      },
    ]
    const routePath = [...parentRoutes].find(r => r.name === pathName)
    let routeFullPath = routePath?.path
    if (params) {
      Object.keys(params).forEach(key => {
        routeFullPath = routeFullPath?.replace(`:${key}`, params[key])
      })
    }
    return routeFullPath
  } catch (error) {
    console.error(error)
  }
}
</script>

<template>
  <div class="flex" v-if="recipient?.id">
    <div class="flex p-2 cursor-pointer">
      <div class="w-8 mr-4">
        <UIAvatar
          :style="{
            backgroundColor: getColorFromString(
              toCapitalize(
                `${recipient?.contactName}` ||
                  `${recipient.firstName} ${recipient.lastName}`
              ).trim()
            ),
          }"
        >
          {{ recipientThumbnail(recipient.firstName, recipient.lastName) }}
        </UIAvatar>
      </div>
      <div class="flex flex-col">
        <div class="text-sm w-40">
          <div class="font-semibold truncate capitalize">
            <a
              v-if="recipient.entityName === 'contacts'"
              class="cursor-pointer avatar-name"
              @click.prevent="onClickCustomer()"
            >
              {{ recipient.firstName }} {{ recipient.lastName }}
              {{
                props.userId === recipient.id ? i18n.global.t('common.you') : ''
              }}
            </a>
            <a v-else class="pointer-events-none avatar-name">
              {{ recipient.firstName }} {{ recipient.lastName }}
              {{
                props.userId === recipient.id ? i18n.global.t('common.you') : ''
              }}
            </a>
          </div>
        </div>
        <div class="flex items-end text-gray-500 font-light w-40">
          <UIPopover
            trigger="hover"
            content-style="background-color: rgb(3 7 18)"
            arrow-style="background-color: rgb(3 7 18)"
            style="padding: 0"
          >
            <template #trigger>
              <UITextXsRegular class="truncate w-40">
                {{ recipient.email }}
              </UITextXsRegular>
            </template>
            <div class="font-light p-2 text-white">
              <UITextXsRegular class="capitalize">
                {{ recipient.firstName }} {{ recipient.lastName }}
                {{
                  props.userId === recipient.id
                    ? i18n.global.t('common.you')
                    : ''
                }}
              </UITextXsRegular>
              <UITextXsRegular>
                {{ recipient.email }}
              </UITextXsRegular>
            </div>
          </UIPopover>
        </div>
        <div v-if="showViewed && viewedTime">
          <p class="text-xs text-gray-500">
            {{ i18n.global.t('common.lastViewed') }}
            {{ formatLastViewedTime(viewedTime) }}
          </p>
        </div>
        <div class="flex gap-2 mt-2" v-if="showRecipientTag">
          <UITag
            v-if="recipient.isPrimary"
            type="success"
            size="small"
            class="text-[8px]"
          >
            {{ i18n.global.t('common.primary') }}
          </UITag>
          <div v-if="recipient?.role" class="flex grow gap-x-2">
            <UITag
              class="text-[8px]"
              size="small"
              v-if="recipient?.role === RecipientRolesEnum.USER"
              type="warning"
            >
              {{ i18n.global.t('common.cc') }}
            </UITag>
            <UITag
              class="text-[8px]"
              size="small"
              v-if="recipient?.role === RecipientRolesEnum.SIGNER"
              :type="recipient.imgUrl ? 'success' : 'error'"
            >
              {{
                recipient.imgUrl
                  ? i18n.global.t('common.signed')
                  : i18n.global.t('common.signer')
              }}
            </UITag>
            <UITag
              class="text-[8px]"
              size="small"
              v-if="recipient?.role === RecipientRolesEnum.USER"
              :type="recipient.hasCompleted ? 'success' : 'error'"
            >
              {{
                recipient.hasCompleted
                  ? i18n.global.t('common.accepted')
                  : i18n.global.t('common.needToAccept')
              }}
            </UITag>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.avatar-name {
  color: var(--gray-700) !important;
  &:hover {
    color: var(--primary-500) !important;
  }
}
</style>
