import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(localizedFormat)

type DateInput = string | number | Date
export const formatDate = (date: DateInput, format = 'LLL') => {
  return dayjs(date).format(format)
}

export const isDateBefore = (date: DateInput) => {
  return dayjs().isBefore(date, 'day')
}

export const isValidDate = (date: DateInput) => {
  return dayjs(date).isValid()
}

export const convertToFormatForValidDate = (value: any) => {
  if (isValidDate(value)) return formatDate(value, 'LL')
  return value
}
