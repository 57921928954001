import { IElement, IPage } from '../types'

import { ELEMENTS_LOOKUP } from '../const'

export const floatingElementsSet = new Set([
  ELEMENTS_LOOKUP.INITIALS_FIELD,
  ELEMENTS_LOOKUP.SIGNATURE,
  ELEMENTS_LOOKUP.DATE_FIELD,
  ELEMENTS_LOOKUP.CHECKBOX,
  ELEMENTS_LOOKUP.TEXT_FIELD,
])
export const flatChildrens = (pages: IPage[]) => {
  return pages.reduce((acc, page) => {
    page.children?.forEach(element => {
      if (element.type === ELEMENTS_LOOKUP.ROW) {
        element.children?.forEach(column => {
          column.children?.forEach(child => {
            acc.push(child)
            if (child?.children && child?.children?.length > 0) {
              child.children.forEach(grandChild => {
                acc.push(grandChild)
              })
            }
          })
        })
      } else {
        acc.push(element)
        if (element?.children && element?.children?.length > 0) {
          element.children.forEach(child => {
            acc.push(child)
          })
        }
      }
    })
    return acc
  }, [] as IElement[])
}
