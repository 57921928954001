import {
  CurrencyConfigs,
  DynamicEditableField,
  DynamicEditableFieldProps,
  getCurrencyUtil,
} from '@gohighlevel/ghl-proposals-common'
import { DefineComponent, h } from 'vue'

import { UITextSmMedium } from '@gohighlevel/ghl-ui'
import { useI18n } from 'vue-i18n'
import ShowEditTableRow from './ShowEditTableRow.vue'

export const getProductListColumns = (
  currencyCode: string,
  elementVersion = 1,
  onUpdate: <T>(key: keyof T, value: any, row?: any) => void
) => {
  const { t } = useI18n()

  if (elementVersion < 3) {
    return [
      {
        title: t('common.item'),
        key: 'item',
        width: 200,
        align: 'left',
        columnClass: 'px-0' + (elementVersion > 1 ? '' : ' pl-4'),
        renderCell(row: any) {
          return h(
            UITextSmMedium,
            {
              class: 'text-gray-900 text-left',
            },
            {
              default: () => row.name,
            }
          )
        },
      },
      {
        title: t('common.price'),
        key: 'priceText',
        width: 150,
        align: 'left',
        renderCell(row: any) {
          return h(
            UITextSmMedium,
            {
              class: 'text-gray-900 text-left',
            },
            {
              default: () => row.priceText,
            }
          )
        },
      },
      {
        title: t('common.quantity'),
        key: 'qty',
        align: 'left',
        editable: true,
        columnClass: 'px-0',
        renderCell(row: any) {
          return h(ShowEditTableRow as unknown as DefineComponent, {
            isNumber: true,
            value: row.qty,
            min: row.minQty || 0,
            max: row.maxQty || Infinity,
            editable: row.qtyEditable,
            name: 'qty',
            onUpdateValue(value: any) {
              onUpdate('qty', value, row)
            },
          })
        },
      },
      {
        title: t('common.subtotal'),
        key: 'subtotal',
        align: 'right',
        renderCell(row: any) {
          const { getCurrencySymbol, formatCurrency } = getCurrencyUtil(
            currencyCode,
            CurrencyConfigs
          )
          return h(
            UITextSmMedium,
            {
              class: 'text-gray-900 text-right',
            },
            {
              default: () => formatCurrency(row.subtotal, getCurrencySymbol()),
            }
          )
        },
      },
    ]
  }
  return [
    {
      title: t('common.item'),
      key: 'item',
      width: 250,
      align: 'left',
      columnClass: 'px-0' + (elementVersion > 1 ? '' : ' pl-4'),
      renderCell(row: any) {
        return h('div', {}, [
          h(
            DynamicEditableField as DefineComponent<DynamicEditableFieldProps>,
            {
              value: row.name,
              name: 'name',
              editable: false,
              onUpdateValue() {
                return
              },
              id: row.key,
            }
          ),
          h(
            DynamicEditableField as DefineComponent<DynamicEditableFieldProps>,
            {
              id: row.key,
              richText: row.description,
              name: 'description',
              editable: false,
              isRichText: true,
              rowHovered: false,
              onUpdateValue() {
                return
              },
            },
            []
          ),
        ])
      },
    },
    {
      title: t('common.price'),
      key: 'priceText',
      width: 100,
      align: 'left',
      columnClass: 'pt-6',
      renderCell(row: any) {
        return h(
          UITextSmMedium,
          {
            class: 'text-gray-900 text-left',
          },
          {
            default: () => row.priceText,
          }
        )
      },
    },
    {
      title: t('common.quantity'),
      key: 'qty',
      align: 'left',
      editable: true,
      columnClass: 'px-0',
      renderCell(row: any) {
        return h(
          DynamicEditableField as DefineComponent<DynamicEditableFieldProps>,
          {
            id: row.key,
            isNumber: true,
            value: row.qty,
            min: row.minQty || 0,
            max: row.maxQty || Infinity,
            editable: row.qtyEditable,
            name: 'qty',
            onUpdateValue(value: any) {
              onUpdate('qty', value, row)
            },
          }
        )
      },
    },
    {
      title: t('common.subtotal'),
      key: 'subtotal',
      align: 'right',
      columnClass: 'pt-6',
      renderCell(row: any) {
        const { getCurrencySymbol, formatCurrency } = getCurrencyUtil(
          currencyCode,
          CurrencyConfigs
        )
        return h(
          UITextSmMedium,
          {
            class: 'text-gray-900 text-right',
          },
          {
            default: () => formatCurrency(row.subtotal, getCurrencySymbol()),
          }
        )
      },
    },
  ]
}
