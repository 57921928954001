import axios, { AxiosRequestConfig } from 'axios'

import { config } from '../config'

declare global {
  interface Window {
    getToken: any
  }
}

const getHeader = () => {
  return {
    channel: 'APP',
    source: 'WEB_USER',
    version: '2021-07-28',
  }
}

export const requests = (host?: string) => {
  const baseUrl = host || config.LOCATION_SERVICE_URL

  const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: getHeader(),
  })

  axiosInstance.interceptors.request.use(
    async (requestConfig: AxiosRequestConfig) => {
      try {
        requestConfig.headers['token-id'] = await window.getToken()
      } catch (e) {
        console.error(e)
      }
      return requestConfig
    }
  )

  return axiosInstance
}
