import {
  ELEMENTS_LOOKUP,
  GROUP_LOOKUP,
  GroupFields,
  GroupFieldsOptions,
  IElement,
  SignatureElementOptions,
  TextFieldElementOptions,
} from '@gohighlevel/ghl-proposals-common'
import { computed, onMounted, ref } from 'vue'

import { useDocumentStore } from '@/store/document'
import { cloneDeep } from 'lodash'

const elementsRef = ref<Record<string, HTMLElement>>({})
const currentActiveIdx = ref<number>(-1)
// const prevActiveElement = ref<IElement | null>(null)
const activeElement = ref<
  | IElement<SignatureElementOptions | TextFieldElementOptions>
  | GroupFields<GroupFieldsOptions>
>()
const pageHeights = ref<number[]>([])
const initialUnsignedLength = ref(0)
const isAllFilled = ref(false)
const sortedElements = ref<IElement[]>([])
/**
 * Custom composable hook for managing fillable fields.
 * This hook provides functions to navigate through fillable fields, set active fields, and clear active fields.
 * It also provides information about the active element, page heights, and whether all fields are filled.
 */
// const omitElements = ref<string[]>([])
export const useFillableFields = () => {
  /**
   * Get the document store instance.
   */
  const store = useDocumentStore()
  /**
   * Get the computed value of unfilled elements.
   */

  const unFilledElements = computed(() => {
    const pageMap = store.unFilledElements.elementsPageMap
    return {
      elements: store.unFilledElements.elements.filter(
        ({ id }) => !store.visitedOptionalElements?.includes(id)
      ),
      elementsPageMap: pageMap,
    }
  })
  // const unsignedElemIds = computed(() =>
  //   sortedElements.value.map(({ id }) => id)
  // )

  // const unsignedSignatureElementIds = computed(() =>
  //   unsignedElemIds.value.filter(
  //     id => !store.visitedOptionalElements?.includes(id)
  //   )
  // )
  const isGroupField = (elm: IElement) => elm.type === GROUP_LOOKUP.GROUP_FIELDS
  const sortUnsignedElements = () => {
    sortedElements.value = cloneDeep(unFilledElements.value.elements)
      .sort((a, b) => {
        const bound1 =
          elementsRef.value[
            isGroupField(a) ? a.children[0]?.elementId : a.id
          ]?.getBoundingClientRect()
        const bound2 =
          elementsRef.value[
            isGroupField(b) ? b.children[0]?.elementId : b.id
          ]?.getBoundingClientRect()
        return bound1?.top - bound2?.top
      })
      .filter(({ id }) => !store.visitedOptionalElements?.includes(id))
  }
  /**
   * Set the initial unsigned length value.
   */
  initialUnsignedLength.value = unFilledElements.value.elements.length

  /**
   * Perform actions after the component is mounted.
   */
  onMounted(() => {
    if (store.isEditable && store.hasPrevRecipientsCompleted) {
      /**
       * Delayed execution group should be available in the dom.
       */
      setTimeout(() => {
        unFilledElements.value.elements.forEach(elm => {
          if (isGroupField(elm)) {
            elementsRef.value[elm.children[0]?.elementId] =
              document.getElementById(elm.children[0]?.elementId) as HTMLElement
          }
          elementsRef.value[elm.id] = document.getElementById(
            elm.id
          ) as HTMLElement
        })
        sortUnsignedElements()

        if (store.isEditing && currentActiveIdx.value === -1) {
          currentActiveIdx.value = 0
          activeElement.value = sortedElements.value[currentActiveIdx.value]
          elementsRef.value[activeElement.value?.id]?.classList?.add('point-el')
          setTimeout(() => scrollInToView(activeElement.value?.id), 40)
          next()
        }
      }, 50)

      /**
       * Delayed execution to calculate page heights.
       */
      setTimeout(() => {
        const pages = Array.from(
          document.getElementsByClassName('elements_page')
        )
        pages.forEach(page => {
          pageHeights.value?.push(page.getBoundingClientRect().height)
        })
      }, 1200)
    }
  })

  /**
   * Get the window height.
   */
  const windowHeight = window.innerHeight

  /**
   * Get the element reference by id.
   * If the element reference is not found, create a new one.
   * @param id - The id of the element.
   * @returns The element reference.
   */
  const getElementRef = (id: string) => {
    if (elementsRef.value[id]) {
      return elementsRef.value[id]
    }
    const el = document.getElementById(id) as HTMLElement
    elementsRef.value[id] = el
    return elementsRef.value[id]
  }

  /**
   * Clear all active elements.
   */
  const clearAllActive = () => {
    Object.values(elementsRef.value).forEach(elem => {
      elem?.classList?.remove('point-el')
    })
  }

  /**
   * Scroll to the specified element.
   * @param id - The id of the element to scroll to.
   */
  const scrollInToView = (id: string) => {
    if (!id) return
    const target = getElementRef(id)
    const container = document.getElementById(
      'document-pages-container'
    ) as HTMLElement
    const containerRect = container?.getBoundingClientRect() as DOMRect
    const targetRect = target?.getBoundingClientRect() as DOMRect
    const relativeBottom =
      targetRect?.bottom -
      containerRect?.bottom +
      container?.scrollTop +
      windowHeight / 4

    const relativeLeft =
      targetRect?.left - containerRect?.left + container?.scrollLeft

    clearAllActive()
    target?.classList?.add('point-el')
    container?.scrollTo({
      top: relativeBottom,
      left: relativeLeft,
      behavior: 'smooth',
    })
  }

  /**
   * Set the active field by element id.
   * @param elementId - The id of the element to set as active.
   */
  const setActiveField = (elementId: string) => {
    const idx = sortedElements.value.findIndex(({ id }) => id === elementId)
    if (idx >= 0) {
      elementsRef?.value[elementId]?.classList?.add('point-el')
      currentActiveIdx.value = idx
      const id = sortedElements.value[currentActiveIdx.value].id
      setTimeout(() => scrollInToView(id), 40)
      activeElement.value = sortedElements.value[currentActiveIdx.value]
      currentActiveIdx.value = currentActiveIdx.value + 1
    } else {
      getElementRef(elementId)
      elementsRef.value[elementId].classList.add('point-el')
      currentActiveIdx.value = 0
      activeElement.value = store.assignedElementsForRecipient.find(element => {
        return element.id === elementId
      })
      setTimeout(() => scrollInToView(elementId), 40)
    }
  }

  const next = () => {
    const unsigned = sortedElements.value
    const unsignedCount = unsigned.length

    if (unsignedCount > 0) {
      if (unsignedCount <= currentActiveIdx.value) {
        currentActiveIdx.value = 0
      }

      if (unsignedCount > currentActiveIdx.value) {
        const id = unsigned[currentActiveIdx.value].id
        activeElement.value = sortedElements.value[currentActiveIdx.value]
        currentActiveIdx.value = currentActiveIdx.value + 1
        if (activeElement.value?.type === ELEMENTS_LOOKUP.PRODUCT_LIST) {
          store.setVisitedOptionalElement(activeElement.value.id)
        }
        if (activeElement.value?.type === ELEMENTS_LOOKUP.CHECKBOX) {
          if (
            activeElement.value?.component?.options?.preChecked &&
            activeElement.value?.component?.options?.text != 'false'
          ) {
            store.setVisitedOptionalElement(activeElement.value.id)
          }
        }
        if (currentActiveIdx.value === unsignedCount) {
          currentActiveIdx.value = 0
          sortUnsignedElements()
        }
        setTimeout(() => scrollInToView(id), 40)
      }
    } else {
      isAllFilled.value = true
    }
  }

  /**
   * Return the functions and reactive values of the composable hook.
   */
  return {
    next,
    clearAllActive,
    activeElement,
    pageHeights,
    isAllFilled,
    setActiveField,
    getElementRef,
    elementsRef,
    sortedElements,
    currentActiveIdx,
  }
}
