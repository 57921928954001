import uniqBy from 'lodash-es/uniqBy'
import { DropdownTreeOption } from './types'

/**
 * Path needs to hierarchial/nested for this to work.
 * Eg.,  Vehicles/Four-Wheeler/Car ==> will give the CAR related data
 *
 * Its logically defined to traverse tree under single branch and
 * always branches are nested and no two branches share children.
 */
export const getObjectByPath = ({
  path,
  options,
  pathSeparator = '/',
}: {
  options: DropdownTreeOption[]
  path: string
  pathSeparator?: string
}): DropdownTreeOption[] | null => {
  // Split the string by the separator
  const paths = path.split(pathSeparator).filter(Boolean)

  // Get the WHOLE tree
  let currentOptions = options

  for (const path of paths) {
    const matchingOption = currentOptions.find(option => option.value === path)

    // No matchings found on the current tree
    if (!matchingOption) {
      return null
    }

    // if the value is matched with the current tree
    currentOptions = matchingOption.children || []
  }

  //  Ensure unique collection is sent
  return uniqBy(currentOptions, 'value')
}

export const showDebugging = (eventName: string, output: any) => {
  console.info(eventName, output)
}

/**
 * Iterate through the array
 * If the option has children => the iterate
 * else add to flattened.
 *
 * Hence this ignores the Groups/parent elements from the search
 */
export const getFlattenedOptions = (nodes: DropdownTreeOption[]) => {
  const flattened = []

  const flatten = (node: DropdownTreeOption, path = '/') => {
    const { label, value, children, ...rest } = node
    const flattenedNode = { label, value, _pathFromRoot: path, ...rest }
    if (children) {
      flattened.push({ ...flattenedNode, type: 'parent' })
      children.forEach(child => flatten(child, `${path}${label}/`))
    } else {
      flattened.push(flattenedNode)
    }
  }

  nodes.forEach(node => flatten(node))

  //  Ensure unique collection is sent
  return uniqBy(flattened, 'value')
}
