import type { CustomDocumentFields } from '../types'

export const ELEMENTS_LOOKUP = {
  PAGE: 'Page',
  TEXT: 'Text',
  IMAGE: 'Image',
  VIDEO: 'Video',
  TABLE: 'Table',
  PRODUCT_LIST: 'ProductList',
  PAGE_BREAK: 'PageBreak',
  SIGNATURE: 'Signature',
  PAYMENT_DETAILS: 'PaymentDetails',
  TEXT_FIELD: 'TextField',
  DATE_FIELD: 'DateField',
  INITIALS_FIELD: 'InitialsField',
  CHECKBOX: 'Checkbox',
  ROW: 'Row',
  COLUMN: 'Column',
}
export type ElementTypes =
  (typeof ELEMENTS_LOOKUP)[keyof typeof ELEMENTS_LOOKUP]

export const ELEMENTS_TAG = {
  [ELEMENTS_LOOKUP.PAGE]: 'c-page',
  [ELEMENTS_LOOKUP.IMAGE]: 'c-image',
  [ELEMENTS_LOOKUP.TEXT]: 'c-text',
  [ELEMENTS_LOOKUP.VIDEO]: 'c-video',
  [ELEMENTS_LOOKUP.TABLE]: 'c-table',
  [ELEMENTS_LOOKUP.PRODUCT_LIST]: 'c-product-list',
  [ELEMENTS_LOOKUP.PAGE_BREAK]: 'c-page-break',
  [ELEMENTS_LOOKUP.SIGNATURE]: 'c-signature',
  [ELEMENTS_LOOKUP.PAYMENT_DETAILS]: 'c-payment-details',
}

export const ELEMENTS_NAME = {
  [ELEMENTS_LOOKUP.PAGE]: 'Page',
  [ELEMENTS_LOOKUP.TEXT]: 'Text Element',
  [ELEMENTS_LOOKUP.IMAGE]: 'Image Element',
  [ELEMENTS_LOOKUP.VIDEO]: 'Video Element',
  [ELEMENTS_LOOKUP.TABLE]: 'Table Element',
  [ELEMENTS_LOOKUP.PRODUCT_LIST]: 'Product List Element',
  [ELEMENTS_LOOKUP.PAGE_BREAK]: 'Page Break Element',
  [ELEMENTS_LOOKUP.SIGNATURE]: 'Signature Element',
  [ELEMENTS_LOOKUP.PAYMENT_DETAILS]: 'Payment Details Element',
  [ELEMENTS_LOOKUP.TEXT_FIELD]: 'Text Field Element',
  [ELEMENTS_LOOKUP.DATE_FIELD]: 'Date Field Element',
  [ELEMENTS_LOOKUP.INITIALS_FIELD]: 'Initials Field Element',
  [ELEMENTS_LOOKUP.CHECKBOX]: 'Checkbox Field Element',
  [ELEMENTS_LOOKUP.ROW]: 'Row Element',
  [ELEMENTS_LOOKUP.COLUMN]: 'Column Element',
}

export const ELEMENTS_META = {
  SRC: 'src',
  ALT_TEXT: 'altText',
  TEXT: 'text',
  HREF: 'href',
  HTML: 'html',
  REQUIRED: 'required',
  FIELD_ID: 'fieldId',
  RECIPIENT: 'recipient',
  LINE_ITEMS: 'lineItems',
  DISCOUNT_VALUE: 'discountValue',
  DISCOUNT_TYPE: 'discountType',
  PAYMENT_SCHEDULES: 'paymentSchedules',
  PAYMENT_STATUS: 'paymentStatus',
  CURRENCY_CODE: 'currencyCode',
  TIMESTAMP: 'timestamp',
  ENTITY_NAME: 'entityName',
  PLACEHOLDER: 'placeholder',
  AVAILABLE_DATES: 'availableDates',
  DATE_FORMAT: 'dateFormat',
  PRE_CHECKED: 'preChecked',
  IS_CONDITIONAL_LOGIC: 'isConditionalLogic',
  SHOW_NAME: 'showName',
  PAGE_DIMENSIONS: 'pageDimensions',
}

export const ELEMENTS_STYLES = {
  TOP: 'top',
  LEFT: 'left',
  ALIGN: 'align',
  BACKGROUND_COLOR: 'backgroundColor',
  HEIGHT: 'height',
  WIDTH: 'width',
  PADDING_TOP: 'paddingTop',
  PADDING_BOTTOM: 'paddingBottom',
  PADDING_LEFT: 'paddingLeft',
  PADDING_RIGHT: 'paddingRight',
  MARGIN_TOP: 'marginTop',
  MARGIN_BOTTOM: 'marginBottom',
  MARGIN_LEFT: 'marginLeft',
  MARGIN_RIGHT: 'marginRight',
  IMAGE_EFFECT: 'imageEffect',
  BACKGROUND_SIZE: 'backgroundSize',
  BACKGROUND_POSITION: 'backgroundPosition',
  BACKGROUND_REPEAT: 'backgroundRepeat',
  BACKGROUND_OPACITY: 'opacity',
}

export const APP_STORE_META: Record<string, keyof CustomDocumentFields> = {
  RECIPIENTS: 'recipients',
}
