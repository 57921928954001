<script setup lang="ts">
import { computed, ref } from 'vue'
import { useDocumentStore } from '../../store/document'
import { onMounted } from 'vue'
import {
  GroupFields,
  GroupFieldsOptions,
  getSignatureElementColorStyles,
  getRules,
  getRequiredValidation,
} from '@gohighlevel/ghl-proposals-common'
import { useGroups, useTypography, useFillableFields } from '@/composable'

const store = useDocumentStore()
const groups = computed(() => store.groups)
const groupInfo = ref()
const containerInfo = ref()
const { activeGroup } = useGroups()
const { activeElement } = useFillableFields()
const recipients = computed(() => store.recipients)
const { toCapitalize } = useTypography()

const getContainerInfo = () => {
  const container = document.querySelector('#page-renderer')
  const containerRect = container?.getBoundingClientRect()
  if (containerRect) {
    return containerRect
  }
}

const getRecipientInfo = (group: GroupFields<GroupFieldsOptions>) => {
  const recipientId = group?.component?.options?.recipient
  const entityName = group?.component?.options?.entityName
  if (recipientId && entityName) {
    const recipient = recipients.value.find(
      r => r.id === recipientId && r.entityName === entityName
    )
    return recipient
  }
  return null
}

const getRecipientColor = (group: GroupFields<GroupFieldsOptions>) => {
  const recipient = getRecipientInfo(group)
  if (recipient) {
    const color = getSignatureElementColorStyles(recipient)
    return `color: ${color.placeholderColor}`
  }
}

const getGroupInfo = () => {
  return groups.value.flatMap(g => {
    const groupElements = g.children.map(c => {
      const selector = '.groupField[data-element-id="' + c.elementId + '"]'
      return document.querySelector(selector)
    })
    if (groupElements && groupElements.length > 1) {
      const boundingBoxRect = getBoundingBoxRect(groupElements)
      return { group: g, groupElements, boundingBoxRect }
    }
  })
}

const getRecipientName = (group: GroupFields<GroupFieldsOptions>) => {
  const recipient = getRecipientInfo(group)
  if (recipient) {
    const { firstName, lastName } = recipient
    return toCapitalize(firstName ? `${firstName} ${lastName || ''}` : '')
  }
  return ''
}

const getBoundingBoxRect = (groupElements: Element[]) => {
  if (groupElements) {
    let minX = Infinity,
      minY = Infinity,
      maxX = -Infinity,
      maxY = -Infinity
    groupElements.forEach(el => {
      if (el) {
        const rect = el.getBoundingClientRect()
        minX = Math.min(minX, rect.left)
        minY = Math.min(minY, rect.top)
        maxX = Math.max(maxX, rect.right)
        maxY = Math.max(maxY, rect.bottom)
      }
    })
    return { left: minX, top: minY, right: maxX, bottom: maxY }
  }
  return null
}

onMounted(() => {
  // wait for the page load
  setTimeout(() => {
    containerInfo.value = getContainerInfo()
    groupInfo.value = getGroupInfo()
  }, 1200)
})
</script>

<template>
  <div v-for="g in groupInfo" :key="g.group.id">
    <div
      class="group-field-box"
      :id="g.group.id"
      :key="g.group.id"
      :data-group-id="g.group.id"
      :style="{
        transition: 'opacity 0.5s ease, transform 0.5s ease',
        display:
          (activeGroup && activeGroup?.id === g.group.id) ||
          (activeElement && activeElement.id === g.group.id)
            ? 'flex'
            : 'none',
        left: `${g.boundingBoxRect.left - containerInfo.left}px`,
        top: `${g.boundingBoxRect.top - containerInfo.top}px`,
        width: `${g.boundingBoxRect.right - g.boundingBoxRect.left}px`,
        height: `${g.boundingBoxRect.bottom - g.boundingBoxRect.top}px`,
        border:
          activeElement && activeElement?.id === g.group.id
            ? `0px`
            : `1px dotted #787878`,
      }"
    >
      <div
        v-if="getRules(g.group)"
        :style="{
          display: 'flex',
          zIndex: 101,
          position: 'absolute',
          willChange: 'position',
          right: '0',
          top: '-10px',
          transition: 'opacity 0.15s ease 0s',
          'box-shadow':
            'rgba(47, 47, 47, 0.04) 0px 0px 2px 0px, rgba(47, 47, 47, 0.12) 0px 2px 8px 0px',
          'pointer-events': 'none',
          'white-space': 'nowrap',
        }"
        class="bg-white -mt-5 animate-slideDown"
      >
        <div class="flex justify-between rounded-sm">
          <div
            class="font-semibold px-2 py-1 text-xs hl-text-sm-regular"
            :style="getRecipientColor(g.group)"
          >
            {{ getRules(g.group) }}
          </div>

          <span
            v-if="getRequiredValidation(g.group)"
            :style="{
              display: 'flex',
              zIndex: 101,
              position: 'absolute',
              willChange: 'position',
              right: '-2px',
              top: '-20px',
            }"
            class="option--required"
          >
            *
          </span>
        </div>
      </div>

      <div
        v-if="getRecipientName(g.group)"
        :style="{
          display: 'flex',
          zIndex: 101,
          position: 'absolute',
          willChange: 'position',
          left: '0',
          bottom: '-10px',
          transition: 'opacity 0.15s ease 0s',
          'box-shadow':
            'rgba(47, 47, 47, 0.04) 0px 0px 2px 0px, rgba(47, 47, 47, 0.12) 0px 2px 8px 0px',
          'pointer-events': 'none',
          'white-space': 'nowrap',
        }"
        class="bg-white -mb-5 animate-slideDown"
      >
        <div class="flex justify-between rounded-sm">
          <div
            class="capitalize font-semibold px-2 py-1 text-xs hl-text-sm-regular"
            :style="getRecipientColor(g.group)"
          >
            {{ getRecipientName(g.group) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.group-field-box {
  position: absolute;
  pointer-events: none;
  &.point-el {
    &::after {
      z-index: 0 !important;
    }
  }
}
</style>
