import libphonenumber from 'google-libphonenumber'

export const phoneNumberUtil = libphonenumber.PhoneNumberUtil.getInstance()

export const formatPhoneNumber = (
  countryCode: string,
  phoneNumber: string,
  format: libphonenumber.PhoneNumberFormat = libphonenumber.PhoneNumberFormat
    .INTERNATIONAL
) => {
  return phoneNumberUtil.format(
    phoneNumberUtil.parse(phoneNumber, countryCode),
    format
  )
}
