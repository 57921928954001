import { useDocumentStore } from '@/store/document'
import {
  GroupFields,
  GroupFieldsOptions,
  isElementPartOfAnyGroup,
} from '@gohighlevel/ghl-proposals-common'
import { ref } from 'vue'

const activeGroup = ref()

export const useGroups = () => {
  const store = useDocumentStore()

  const isElementPartOfGroup = (elementId: string) => {
    const groups = store?.groups
    const group = isElementPartOfAnyGroup(groups, elementId)
    return group
  }

  const calculateGroupPosition = (group: GroupFields<GroupFieldsOptions>) => {
    const pageRenderer = document.querySelector('#page-renderer')
    const pageRendererRect = pageRenderer?.getBoundingClientRect()
    const selector = '.group-field-box[data-group-id="' + group.id + '"]'
    const groupElement = document.querySelector(selector)
    const groupRect = groupElement?.getBoundingClientRect()
    if (groupRect && pageRendererRect) {
      const top = groupRect.top - pageRendererRect?.top
      const left = groupRect.left - pageRendererRect?.left
      return { top, left }
    }

    return {}
  }

  return {
    activeGroup,
    isElementPartOfGroup,
    calculateGroupPosition,
  }
}
