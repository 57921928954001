<script setup lang="ts">
import { UITooltip } from '@gohighlevel/ghl-ui'
import { Copy01Icon, Trash02Icon } from '@gohighlevel/ghl-icons/24/outline'

const emits = defineEmits(['on-clone', 'on-delete'])

const onClone = (event: MouseEvent) => {
  event.stopPropagation()
  emits('on-clone', event)
}

const onDelete = (event: MouseEvent) => {
  event.stopPropagation()
  emits('on-delete', event)
}
</script>

<template>
  <div
    class="custom-popover-options animate-slideUp flex justify-between bg-gray-charcoal border border-gray-700 shadow-float p-[4px] rounded-sm"
  >
    <UITooltip>
      <template #trigger>
        <div
          class="hover:bg-gray-600 w-full cursor-pointer flex"
          @click="onClone"
        >
          <Copy01Icon class="text-white p-1 text-sm h-4 w-4" />
        </div>
      </template>
      {{ $t('common.copy') }}
    </UITooltip>
    <UITooltip>
      <template #trigger>
        <div
          class="hover:bg-gray-600 w-full cursor-pointer flex"
          @click="onDelete"
        >
          <Trash02Icon class="text-white p-1 text-sm h-4 w-4" />
        </div>
      </template>
      {{ $t('common.delete') }}
    </UITooltip>
    <slot name="extra"></slot>
  </div>
</template>
