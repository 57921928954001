<script setup lang="ts">
import TopTitleBar from '@/components/header/TopTitleBar.vue'
import ViewerLayout from '@/components/layouts/ViewerLayout.vue'
import PageLoop from '@/components/page/PageLoop.vue'
import { FillDocumentActionBar } from '@/components/common'
import BaseDocument from './BaseDocument.vue'
import GroupsIndicator from '@/components/common/GroupsIndicator.vue'
import { useDocumentStore } from '@/store/document'
import { useMobileView } from '@/composable'

const store = useDocumentStore()
const { isMobile } = useMobileView()
</script>

<template>
  <BaseDocument v-slot="props">
    <ViewerLayout v-if="!props.pageLoading">
      <TopTitleBar :isPreview="false" />
      <FillDocumentActionBar />
      <div
        id="document-pages-container"
        :class="{
          '[@media(max-width:520px)]:mt-[4em]':
            isMobile && store.isEditing && store.hasPrevRecipientsCompleted,
          '[@media(max-width:520px)]:mt-[0em]':
            isMobile && store.isEditing && !store.hasPrevRecipientsCompleted,
          'mt-[0em]': isMobile && !store.isEditing,
        }"
        class="h-screen clearfix pt-1 overflow-auto mt-[6em]"
      >
        <div
          style="transition: 0.3s ease scroll"
          id="page-renderer"
          class="flex w-screen justify-center page:!justify-start relative clearfix h-full mt-4"
        >
          <PageLoop :isPreview="false" />
          <GroupsIndicator />
        </div>
      </div>
    </ViewerLayout>
  </BaseDocument>
</template>

<style scoped lang="scss">
@screen page {
  .m-h {
    height: calc(100% - 72px);
  }
}

.clearfix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}
</style>
