interface CounterProps {
  startCount: number
  timeGapInMs: number
  onEnd?: (count: number) => void
  onStart?: () => void
  onTick?: (count: number) => void
}
export const counter = ({
  startCount,
  timeGapInMs,
  onEnd,
  onStart,
  onTick,
}: CounterProps) => {
  let timeout: any
  let count = startCount

  const startCounter = () => {
    if (count === startCount) onStart && onStart()
    clearTimeout(timeout)
    if (count <= 0) onEnd && onEnd(count)
    timeout = setTimeout(() => {
      onTick && onTick(count)
      count = count - 1
      startCounter()
    }, timeGapInMs)
  }
  startCounter()
  return timeout
}
