<script setup lang="ts">
import { UITooltip, UIDivider } from '@gohighlevel/ghl-ui'
import {
  Copy01Icon,
  Trash02Icon,
  LayersThree02Icon,
  LayerSingleIcon,
} from '@gohighlevel/ghl-icons/24/outline'

const emits = defineEmits([
  'on-group-clone',
  'on-group-delete',
  'on-group-elements',
  'on-ungroup-elements',
])

const props = defineProps({
  groupExist: {
    type: Boolean,
    required: true,
  },
})

const onClone = (event: MouseEvent) => {
  event.stopPropagation()
  emits('on-group-clone', event)
}

const onDelete = (event: MouseEvent) => {
  event.stopPropagation()
  emits('on-group-delete', event)
}

const onGroup = (event: MouseEvent) => {
  event.stopPropagation()
  emits('on-group-elements', event)
}

const onUnGroup = (event: MouseEvent) => {
  event.stopPropagation()
  emits('on-ungroup-elements', event)
}
</script>

<template>
  <div
    class="group-tooltip animate-slideUp flex justify-between bg-gray-charcoal border border-gray-700 shadow-float p-[4px] rounded-sm"
  >
    <UITooltip v-if="props.groupExist">
      <template #trigger>
        <div
          class="hover:bg-gray-600 w-full cursor-pointer flex"
          @click="onUnGroup"
        >
          <LayerSingleIcon class="text-white p-1 text-sm h-4 w-4" />
        </div>
      </template>
      Ungroup
    </UITooltip>

    <UITooltip v-if="!props.groupExist">
      <template #trigger>
        <div
          class="hover:bg-gray-600 w-full cursor-pointer flex"
          @click="onGroup"
        >
          <LayersThree02Icon class="text-white p-1 text-sm h-4 w-4" />
        </div>
      </template>
      Group
    </UITooltip>
    <UIDivider :titlePlacement="'center'" :dashed="false" :vertical="true" />
    <UITooltip>
      <template #trigger>
        <div
          class="hover:bg-gray-600 w-full cursor-pointer flex"
          @click="onClone"
        >
          <Copy01Icon class="text-white p-1 text-sm h-4 w-4" />
        </div>
      </template>
      {{ $t('common.copy') }}
    </UITooltip>
    <UITooltip>
      <template #trigger>
        <div
          class="hover:bg-gray-600 w-full cursor-pointer flex"
          @click="onDelete"
        >
          <Trash02Icon class="text-white p-1 text-sm h-4 w-4" />
        </div>
      </template>
      {{ $t('common.delete') }}
    </UITooltip>
  </div>
</template>
