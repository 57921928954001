import { config } from '../config'
import { requests } from '../utils'

export const CustomFieldsForLinkageService = {
  getCustomFieldsForLinkage: (params: { locationId: string }) => {
    return requests(config.PROPOSAL_SERVICE_URL).get(
      `document/custom-fields/${params.locationId}`,
      {
        headers: {
          'location-id': params.locationId,
          'internal-channel': `proposals`,
        },
      }
    )
  },
}
