import { computed, inject, ref, watch } from 'vue'
import { RouteLocationNormalizedLoaded, useRoute } from 'vue-router'
import {
  getAllCustomFieldLocation,
  getAllCustomValLocation,
  removeOpportunityCustomFields,
} from '../utils'

import { TextInputIcon } from '@gohighlevel/ghl-icons/24/outline'
import { renderIcon } from '@gohighlevel/ghl-ui'
import { cloneDeep } from 'lodash'
import { useI18n } from 'vue-i18n'
import { CustomValuesService } from '../services'

const loading = ref(false)
const locationStaticCustomValues = ref([])
const locationCustomValues = ref([])
const locationCustomFields = ref([])
const staticCustomValues: any = ref([])
const staticCustomValuesWithoutOpportunity: any = ref([])

export const useCustomValues = (menuId = 'customValues' as string) => {
  const { t } = useI18n()
  let route = useRoute()
  if (!route) {
    route = inject<RouteLocationNormalizedLoaded>(
      'route'
    ) as RouteLocationNormalizedLoaded
  }
  const getCustomValuesAndFieldsForLocation = async () => {
    try {
      loading.value = true
      const { data } = await CustomValuesService.getCustomValueData({
        locationId: (route.params.locationId ||
          route.params.location_id) as string,
      })

      locationStaticCustomValues.value = data['static'] || []
      locationCustomValues.value = data['custom-values'] || []
      locationCustomFields.value = data['custom-fields'] || []
      setCustomValues()
    } catch (error) {
      console.error(error)
    } finally {
      loading.value = false
    }
  }

  const customMenuOptions = computed(() => [
    {
      menuId,
      menuTitle: t('common.customValues'),
      icon: renderIcon(TextInputIcon),
      renderIcon: true,
      renderTitle: false,
      items: staticCustomValues.value,
    },
  ])

  const customMenuOptionsWithoutOpp = computed(() => [
    {
      menuId,
      menuTitle: t('common.customValues'),
      icon: renderIcon(TextInputIcon),
      renderIcon: true,
      renderTitle: false,
      items: staticCustomValuesWithoutOpportunity.value,
    },
  ])

  const setCustomValues = () => {
    let customValueOptions: any = []
    let customFieldOptions: any = []
    let customFieldOptionsWithoutOpp: any = []

    if (locationCustomValues.value && locationCustomValues.value.length > 0) {
      customValueOptions = getAllCustomValLocation(locationCustomValues.value)
    }

    if (locationCustomFields.value && locationCustomFields.value.length > 0) {
      customFieldOptions = getAllCustomFieldLocation(locationCustomFields.value)

      const cfOptionsCopy = cloneDeep(customFieldOptions)
      customFieldOptionsWithoutOpp =
        removeOpportunityCustomFields(cfOptionsCopy)
    }

    staticCustomValues.value = [
      ...getStaticCustomValueOptions(customFieldOptions),
      ...customValueOptions,
    ]

    staticCustomValuesWithoutOpportunity.value = [
      ...getStaticCustomValueOptions(customFieldOptionsWithoutOpp),
      ...customValueOptions,
    ]
  }

  watch(
    () => [
      locationStaticCustomValues.value,
      locationCustomValues.value,
      locationCustomFields.value,
    ],
    () => {
      setCustomValues()
    }
  )

  const getStaticCustomValueOptions = (options: any = []) => {
    let staticCustomValueOptions: any = []
    if (
      locationStaticCustomValues.value &&
      locationStaticCustomValues.value.length > 0
    ) {
      staticCustomValueOptions = cloneDeep(locationStaticCustomValues.value)
    }
    const contactElemPos = staticCustomValueOptions?.findIndex(
      (obj: any) => obj.value === '__contact__'
    )
    if (contactElemPos >= 0) {
      staticCustomValueOptions[contactElemPos].children = [
        ...staticCustomValueOptions[contactElemPos].children,
        options,
      ]
    }

    return staticCustomValueOptions
  }

  return {
    loading,
    customMenuOptions,
    customMenuOptionsWithoutOpp,
    staticCustomValuesWithoutOpportunity,
    staticCustomValues,
    getCustomValuesAndFieldsForLocation,
    locationStaticCustomValues,
    locationCustomValues,
    locationCustomFields,
  }
}
