<script setup lang="ts">
import { ref } from 'vue'

defineProps({
  direction: {
    type: String as () => 'top' | 'bottom' | 'left' | 'right',
    default: 'top',
  },
  enable: {
    type: Boolean,
    default: true,
  },
})
const show = ref(false)

const onMouseEnter = () => {
  show.value = true
}
const onMouseLeave = () => {
  show.value = false
}
</script>

<template>
  <div
    @mouseover="onMouseEnter"
    @mouseleave="onMouseLeave"
    @click="onMouseLeave"
    style="height: inherit; width: inherit"
    class="relative"
  >
    <slot name="trigger"></slot>
    <div
      style="position: absolute; will-change: position"
      :style="{
        position: 'absolute',
        willChange: 'position',
        left: '2px',
        bottom: '-8px',
        transform: 'translateX(0%) translateY(100%)',
        transition: 'opacity 0.15s ease 0s',
        boxShadow:
          'rgba(47, 47, 47, 0.04) 0px 0px 2px 0px, rgba(47, 47, 47, 0.12) 0px 2px 8px 0px',
        opacity: show && enable ? 1 : 0,
        pointerEvents: show && enable ? 'all' : 'none',
        whiteSpace: 'nowrap',
      }"
      class="bg-white actions absolute -mt-8 flex"
    >
      <div
        :class="{
          'animate-slideUp': direction === 'top',
          'animate-slideDown': direction === 'bottom',
        }"
        class="flex justify-between rounded-sm"
      >
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss"></style>
