<script setup lang="ts">
import { AddCustomFieldModal } from '../modals/AddCustomFieldModal'
import { UITooltip } from '@gohighlevel/ghl-ui'
import { PropType, ref, computed } from 'vue'
import { Link01Icon } from '@gohighlevel/ghl-icons/24/outline'
import { CustomVariableEvents, ELEMENTS_LOOKUP } from '../../const'
import { useCustomFieldsForLinkage } from '../../composition'
import { ICustomFieldsLinkageForm, IElement } from '../../types'
import { debounce } from 'lodash'

const showLinkFieldModal = ref(false)
const { textCustomFields, dateCustomFields } = useCustomFieldsForLinkage()
const textCustomFieldOptions = computed(() => textCustomFields.value)
const dateCustomFieldsOptions = computed(() => dateCustomFields.value)
const emits = defineEmits([CustomVariableEvents.CUSTOM_VARIABLE_ADDED])

const props = defineProps({
  element: Object as PropType<IElement>,
  popoverId: { type: String, default: '' },
  trigger: {
    type: String as PropType<'hover' | 'click'>,
    default: 'click',
  },
  label: {
    type: String,
    default: 'Link a field',
  },
  availableDocumentVariables: {
    type: Array,
    default: () => [],
  },
  contactCustomValueMapping: {
    type: Object,
    default: () => ({}),
  },
})

const fieldOptions = computed(() => {
  if (props.element?.type === ELEMENTS_LOOKUP.TEXT_FIELD) {
    return textCustomFieldOptions.value
  } else if (props.element?.type === ELEMENTS_LOOKUP.DATE_FIELD) {
    return dateCustomFieldsOptions.value
  }
  return []
})

const openLinkFieldModal = (e: MouseEvent) => {
  e.stopImmediatePropagation()
  showLinkFieldModal.value = true
}

const toggleModal = () => {
  showLinkFieldModal.value = !showLinkFieldModal.value
}

const customVariableAdded = (formValue: ICustomFieldsLinkageForm) => {
  emits(CustomVariableEvents.CUSTOM_VARIABLE_ADDED, formValue)
  debounce(() => (showLinkFieldModal.value = false), 400)()
}
</script>

<template>
  <AddCustomFieldModal
    :show="showLinkFieldModal"
    @on-cancel="toggleModal"
    :availableDocumentVariables="availableDocumentVariables"
    @on-success="customVariableAdded"
    :menuOptions="fieldOptions"
    :element="element"
    :contactCustomValueMapping="contactCustomValueMapping"
  />
  <div class="hover:bg-gray-600 w-full cursor-pointer flex">
    <UITooltip placement="right">
      <template #trigger>
        <Link01Icon
          @click="openLinkFieldModal"
          class="text-white p-1 text-sm h-4 w-4"
        />
      </template>
      {{ label }}
    </UITooltip>
  </div>
</template>
