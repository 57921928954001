import { IRRule, ITax } from '../../types'

import { BusinessProductOption } from './product.schema'

export interface CProductListProps {
  productList: BusinessProductOption[]
  show: boolean
  loading: boolean
}

export interface CommonModalProps {
  show: boolean
  disabled?: boolean
  loading?: boolean
}

export type CommonModalPropsWithElementId = {
  elementId: string
} & CommonModalProps

export enum PriceTypeEnum {
  ONETIME = 'one-time',
  RECURRING = 'recurring',
}
export interface LineItem {
  name: string
  currency: string
  qty: number
  price: number
  subtotal: number
  productId: string
  priceId: string
  key: string
  priceText: string
  priceDescription?: string
  recurringPayment?: boolean
  rrule: IRRule
  taxes: ITax[]
  selected?: boolean
  optional?: boolean
  qtyEditable?: boolean
  minQty?: number
  maxQty?: number
  description?: string
  isSetupFeeItem?: string
  priceType?: PriceTypeEnum | string
  taxInclusive?: boolean
}

export enum ProductLineEvents {
  CLOSE_DISCOUNT_MODAL = 'close-discount-modal',
  OPEN_DISCOUNT_MODAL = 'open-discount-modal',
  CLOSE_ADD_ITEM_MODAL = 'close-add-item-modal',
  OPEN_ADD_ITEM_MODAL = 'open-add-item-modal',
  CLOSE_PAYMENT_STATUS_MODAL = 'close-payment-status-modal',
  OPEN_PAYMENT_STATUS_MODAL = 'open-payment-status-modal',
  ADD_LINE_ITEM_TO_LIST = 'add-line-item-to-list',
  CLOSE_ADD_PRODUCT_MODAL = 'close-add-product-modal',
  OPEN_ADD_PRODUCT_MODAL = 'open-add-product-modal',
  ADD_NEW_PRODUCT_TO_PRODUCTS_LIST = 'addNewProductToProductsList',
}

export enum ModalEvents {
  SHOW_MODAL = 'show-modal',
  CLOSE_MODAL = 'close-modal',
  ON_SUCCESS = 'on-success',
  ON_CANCEL = 'on-cancel',
}
