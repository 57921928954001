import { createI18n } from 'vue-i18n'
import da from './lang/da.json'
import de from './lang/de.json'
import enUS from './lang/en-US.json'
import es from './lang/es.json'
import fi from './lang/fi.json'
import frCA from './lang/fr-CA.json'
import frFR from './lang/fr-FR.json'
import it from './lang/it.json'
import nl from './lang/nl.json'
import no from './lang/no.json'
import ptBR from './lang/pt-BR.json'
import ptPT from './lang/pt-PT.json'
import sv from './lang/sv.json'

const i18n = createI18n({
  locale: localStorage.getItem('locales') || 'en-US',
  globalInjection: true,
  legacy: false,
  runtimeOnly: true,
  messages: {
    'en-US': enUS,
    es,
    de,
    'fr-CA': frCA,
    'fr-FR': frFR,
    'pt-BR': ptBR,
    'pt-PT': ptPT,
    it,
    nl,
    sv,
    da,
    fi,
    no,
  },
})

export default i18n
