import { VNode } from 'vue'

interface UniqueIdentifier {
  id?: string
}
export enum DynamicTableEvents {
  ON_ROW_HOVER = 'on-row-hover',
  ON_ROW_DELETE = 'on-row-delete',
  ON_ROW_SELECT = 'on-row-select',
  ON_SELECTION_CHANGE = 'on-selection-change',
}

export interface DynamicTableCellConfig<T extends UniqueIdentifier = any> {
  title: string
  width?: number
  key?: string
  renderCell?: (
    props: T,
    options: { index: number; rowActive: boolean }
  ) => VNode
  renderHead?: () => VNode
  align?: 'left' | 'right' | 'center'
  headerClass?: string
  columnClass?: string
  nameEditMap?: Record<string, boolean>
}

export interface DynamicTableProps<T extends UniqueIdentifier = any> {
  backgroundColor?: string
  data: T[]
  config: DynamicTableCellConfig<T>[]
  onOnRowHover?: (row: T) => void
  onOnRowDelete?: (row: T) => void
  onOnRowSelect?: (row: T) => void
  allowRowDelete?: boolean
  checkIfRowIsSelected?: (row: T) => boolean
  selectable?: boolean
  withCheckBox?: boolean
  uniqueKey?: string
  activeRow?: any
}
