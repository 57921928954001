<script setup lang="ts">
import postscribe from 'postscribe'
import { PropType, computed, onMounted } from 'vue'
import { IElement } from '@gohighlevel/ghl-proposals-common'
import CWrapper from './CWrapper.vue'
const props = defineProps({
  element: {
    type: Object as PropType<IElement>,
    required: true,
  },
})

const videoElementDimensions = computed(() => {
  const styles = props.element?.responsiveStyles.large
  return {
    height: styles?.height ? styles?.height + 'px' : '',
    width: styles?.width ? styles?.width + 'px' : '',
  }
})

const customStyleForImageParent = computed(() => {
  const styles = props.element?.responsiveStyles.large
  return {
    backgroundColor: styles?.backgroundColor,
    marginTop: styles?.marginTop,
    marginBottom: styles?.marginBottom,
    marginLeft: styles?.marginLeft,
    marginRight: styles?.marginRight,
    paddingTop: styles?.paddingTop,
    paddingBottom: styles?.paddingBottom,
    paddingLeft: styles?.paddingLeft,
    paddingRight: styles?.paddingRight,
    height: videoElementDimensions.value.height,
    maxWidth: videoElementDimensions.value.width,
    overflow: 'auto',
  }
})
const videoElementId = computed(() => {
  return props.element.id + '-hl-custom-code'
})

const loadVidyardVideo = function () {
  if (
    props.element?.component?.options?.html.includes('vidyard.com') &&
    window?.vidyardEmbed
  ) {
    window?.vidyardEmbed?.api.renderDOMPlayers()
  }
}

onMounted(() => {
  postscribe(
    `#${props.element.id + '-hl-custom-code'}`,
    props.element?.component?.options?.html
  )
  setTimeout(() => {
    loadVidyardVideo()
  }, 500)
})
</script>

<template>
  <CWrapper class="video-el print:break-inside-avoid break-inside-avoid">
    <div :style="customStyleForImageParent">
      <div class="iframe-container">
        <div
          class="c-video-iframe"
          :id="videoElementId"
          :style="videoElementDimensions"
        ></div>
      </div>
    </div>
  </CWrapper>
</template>

<style lang="scss">
.video-el {
  .c-video-iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    iframe {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .iframe-container {
    position: relative;
    z-index: 0;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    overflow: hidden;
  }
}
</style>
