export const useTypography = () => {
  const toCapitalize = (str: string) => {
    return str
      .split(' ')
      .map(subStr => {
        const firstChar = subStr.charAt(0)
        return firstChar.toUpperCase() + subStr.slice(1)
      })
      .join(' ')
  }
  return {
    toCapitalize,
  }
}
