import { inject, ref, Ref } from 'vue'
import { RouteLocationNormalizedLoaded, useRoute } from 'vue-router'
import { CustomFieldsForLinkageService } from '../services/CustomFieldsLinkageService'

export interface ICustomFieldsItem {
  id: string
  name: string
  model: string
  fieldKey: string
  placeholder: string
  dataType: string
  position: number
  documentType: string
  parentId: string
  locationId: string
  dateAdded: string
  standard: boolean
}

const customFields = ref([])
const loading = ref(false)
const dateCustomFields: any = ref([])
const textCustomFields: any = ref([])
const allCustomFieldsKeys: Ref<string[]> = ref([])

export const useCustomFieldsForLinkage = (
  routeData?: RouteLocationNormalizedLoaded
) => {
  const getCustomFieldsForLinkage = async () => {
    try {
      loading.value = true
      let route = useRoute()
      if (!route) {
        route = inject<RouteLocationNormalizedLoaded>(
          'route'
        ) as RouteLocationNormalizedLoaded
      }
      const { data } =
        await CustomFieldsForLinkageService.getCustomFieldsForLinkage({
          locationId: (routeData?.params?.locationId ||
            routeData?.params?.location_id ||
            route?.params?.locationId ||
            route?.params?.location_id) as string,
        })
      customFields.value = data['customFields'] || []
      setCustomFieldsForLinkage()
    } catch (error) {
      console.error(error)
    } finally {
      loading.value = false
    }
  }

  const setCustomFieldsForLinkage = () => {
    const allText = customFields.value.filter(
      (field: ICustomFieldsItem) =>
        field.dataType === 'TEXT' ||
        (field.dataType === 'LARGE_TEXT' && field.standard === false)
    )

    const allDate = customFields.value.filter(
      (field: ICustomFieldsItem) =>
        field.dataType === 'DATE' && field.standard === false
    )

    const textFields =
      allText && allText.length > 0
        ? [
            {
              label: 'Custom Fields',
              value: 'Custom Fields',
              children: allText.map((field: ICustomFieldsItem) => ({
                label: field.name,
                value: field.fieldKey,
                id: field.id,
                type: field.dataType,
                category: 'FIELD',
              })),
            },
          ]
        : []

    textCustomFields.value = [
      {
        label: 'Full Name',
        value: 'contact.name',
        id: 'name',
        type: 'TEXT',
        category: 'STANDARD',
      },
      {
        label: 'First Name',
        value: 'contact.first_name',
        id: 'firstName',
        type: 'TEXT',
        category: 'STANDARD',
      },
      {
        label: 'Last Name',
        value: 'contact.last_name',
        id: 'lastName',
        type: 'TEXT',
        category: 'STANDARD',
      },
      {
        label: 'Full Address',
        value: 'contact.full_address',
        id: 'fullAddress',
        type: 'TEXT',
        category: 'STANDARD',
      },
      {
        label: 'Address 1',
        value: 'contact.address1',
        id: 'address1',
        type: 'TEXT',
        category: 'STANDARD',
      },
      {
        label: 'City',
        value: 'contact.city',
        id: 'city',
        type: 'TEXT',
        category: 'STANDARD',
      },
      {
        label: 'State',
        value: 'contact.state',
        id: 'state',
        type: 'TEXT',
        category: 'STANDARD',
      },
      {
        label: 'Postal Code',
        value: 'contact.postal_code',
        id: 'postalCode',
        type: 'TEXT',
        category: 'STANDARD',
      },
      {
        label: 'Source',
        value: 'contact.source',
        id: 'source',
        type: 'TEXT',
        category: 'STANDARD',
      },
      {
        label: 'Website',
        value: 'contact.website',
        id: 'website',
        type: 'TEXT',
        category: 'STANDARD',
      },
      ...textFields,
    ]

    const dateFields =
      allDate && allDate.length > 0
        ? [
            {
              label: 'Custom Fields',
              value: 'Custom Fields',
              children: allDate.map((field: ICustomFieldsItem) => ({
                label: field.name,
                value: field.fieldKey,
                id: field.id,
                type: field.dataType,
                category: 'FIELD',
              })),
            },
          ]
        : []

    dateCustomFields.value = [
      {
        label: 'Date Of Birth',
        value: 'contact.date_of_birth',
        id: 'dateOfBirth',
        type: 'DATE',
        category: 'STANDARD',
      },
      ...dateFields,
    ]

    const cfFields = customFields.value.map((item: any) => item.fieldKey)
    const cfStandardsFields = textCustomFields.value.map((item: any) => {
      if (item && item?.id) {
        return item.id
      }
    })

    allCustomFieldsKeys.value = [...cfFields, ...cfStandardsFields]
  }

  return {
    loading,
    customFields,
    getCustomFieldsForLinkage,
    dateCustomFields,
    textCustomFields,
    allCustomFieldsKeys,
  }
}
