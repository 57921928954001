<script setup lang="ts">
import { UITextSmMedium, UITextSmRegular } from '@gohighlevel/ghl-ui'
import { useDocumentStore } from '../../store/document'
import DocumentStateActions from './DocumentStateActions.vue'

const store = useDocumentStore()
</script>

<template>
  <div
    class="bg-gray-900 h-16 w-full fixedElement flex flex-col justify-center"
  >
    <div class="px-3 py-2 flex justify-between items-center gap-4 page:gap-0">
      <div class="w-4/12 flex flex-col items-start page:w-8/12">
        <div class="w-full" :title="store?.document.name">
          <UITextSmMedium class="text-white truncate">
            {{ store?.document.name }}
          </UITextSmMedium>
        </div>
        <div>
          <UITextSmRegular class="text-white capitalize">
            {{ $t('documentViewer.by') }}
            {{ store.document.updatedBy?.name }}</UITextSmRegular
          >
        </div>
      </div>
      <div class="w-4/12 flex justify-center page:hidden">
        <div class="flex items-center space-x-2 text-white"></div>
      </div>
      <div class="w-4/12 flex justify-end page:w-4/12">
        <DocumentStateActions />
      </div>
    </div>
  </div>
</template>

<style>
.fixedElement {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}
</style>
