<script setup lang="ts">
import { UIDropdownTree } from '../TreeDropdown'
import { PropType } from 'vue'

import { DropdownTreeOption } from '@gohighlevel/ghl-ui'

type CustomMenu = {
  menuId: string
  menuTitle: string
  icon: () => void
  renderIcon: boolean
  renderTitle: boolean
  items: DropdownTreeOption[]
}

defineEmits(['on-select', 'onClickOutSide'])
defineProps({
  popoverId: { type: String, default: '' },
  customMenus: {
    type: Array as PropType<CustomMenu[]>,
    default: () => [],
  },
  trigger: {
    type: String as PropType<'hover' | 'click'>,
    default: 'click',
  },
  to: {
    type: String || Boolean,
    default: 'body',
  },
  show: {
    type: Boolean,
    default: false,
  },
})

const emptyOptions: DropdownTreeOption[] = [
  {
    label: 'No options available',
    value: '',
    disabled: true,
  },
]
</script>

<template>
  <UIDropdownTree
    v-for="customMenu in customMenus"
    v-bind:key="customMenu.menuId"
    :id="customMenu.menuId"
    :options="customMenu.items.length ? customMenu.items : emptyOptions"
    @on-select="val => $emit('on-select', val)"
    :to="to"
    size="small"
    :popoverId="popoverId"
    @onClickOutSide="(...args) => $emit('onClickOutSide', ...args)"
    :show="show"
    ><template #trigger>
      <slot name="trigger"></slot>
    </template>
  </UIDropdownTree>
</template>

<style scoped>
.text-blocks {
  width: 120px;
}
</style>
