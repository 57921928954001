/**
 * Checks if the current browser is Safari.
 * @returns {boolean} Returns true if the browser is Safari, false otherwise.
 */
export const isSafari = () => {
  return (
    !navigator.userAgent.includes('Chrome') &&
    navigator.userAgent.includes('Safari')
  )
}
