import { SnapDirections } from 'vue3-moveable'

export enum MovableComponentEvents {
  ON_DRAG = 'on-drag',
  ON_RESIZE = 'on-resize',
  ON_SCALE = 'on-scale',
  ON_COPY = 'on-copy',
  ON_DELETE = 'on-delete',
  ON_ACTIVE = 'on-active',
  ON_MOUSE_ENTER = 'on-mouse-enter',
  ON_MOUSE_LEAVE = 'on-mouse-leave',
  ON_FOCUS = 'on-focus',
  ON_DRAG_UPDATE = 'on-drag-update',
  ON_DRAG_END = 'on-drag-end',
  ON_CLONE_AND_ADD_TO_GROUP = 'on-clone-add-to-group',
  ON_CLICK_GROUP = 'on-click-group',
}

export type MoveableComponentDirections =
  | 'n'
  | 'nw'
  | 'ne'
  | 's'
  | 'se'
  | 'sw'
  | 'e'
  | 'w'
export interface MoveableComponentWrapperProps {
  snapDirections?: SnapDirections
  elementSnapDirections?: SnapDirections
  isActiveElement: boolean
  keepRatio?: boolean
  snapContainer?: string
  edgeDraggable?: boolean
  snappable?: boolean
  snapThreshold?: number
  position: { top: number; left: number }
  scale: { scaleX: number; scaleY: number }
  dimensions?: { height: number | string; width: number | string }
  id: string
  isGhost: boolean
  isEditable: boolean
  isDragElement: boolean
  align?: 'left' | 'right' | 'center'
  resizable?: boolean
  renderDirections?: MoveableComponentDirections[]
  minHeight?: number
  minWidth?: number
}
