<script setup lang="ts">
import { PropType, computed, ref } from 'vue'
import { IElement } from '@gohighlevel/ghl-proposals-common'
import CWrapper from './CWrapper.vue'
import {
  Color,
  LineHeight,
  Link,
  RequiredExtensions,
  TextAlign,
  TextBlocks,
  TextSize,
  UIRichTextEditor,
} from '@gohighlevel/ghl-text-editor'
import { Editor } from '@tiptap/vue-3'
import FontFamily from '@tiptap/extension-font-family'
import TextStyle from '@tiptap/extension-text-style'
import Underline from '@tiptap/extension-underline'
import BulletList from '@tiptap/extension-bullet-list'
import StarterKit from '@tiptap/starter-kit'
import ListItem from '@tiptap/extension-list-item'
import HardBreak from '@tiptap/extension-hard-break'

const props = defineProps({
  element: {
    type: Object as PropType<IElement>,
    required: true,
  },
})
const editor = ref()
const CustomBulletList = BulletList.extend({
  addCommands() {
    return {
      ...this.parent?.(),
      toggleCustomBulletList: () => editor => {
        return editor.commands.toggleBulletList()
      },
    }
  },
})

const HardBreakExtension = HardBreak.extend({
  addKeyboardShortcuts() {
    return {
      Enter: () => {
        if (
          this.editor.isActive('orderedList') ||
          this.editor.isActive('bulletList')
        ) {
          return this.editor.chain().createParagraphNear().run()
        }
        return this.editor.commands.setHardBreak()
      },
    }
  },
})

const customStyleForTextParent = computed(() => {
  const styles = props.element?.responsiveStyles.large
  return {
    backgroundColor: styles?.backgroundColor,
    marginTop: styles?.marginTop,
    marginBottom: styles?.marginBottom,
    marginLeft: styles?.marginLeft,
    marginRight: styles?.marginRight,
    paddingTop: styles?.paddingTop,
    paddingBottom: styles?.paddingBottom,
    paddingLeft: styles?.paddingLeft,
    paddingRight: styles?.paddingRight,
  }
})
editor.value = new Editor({
  content: props.element.component.options.text,
  editable: false,
  parseOptions: {
    preserveWhitespace: 'full',
  },
  extensions: [
    StarterKit.configure({
      paragraph: {
        HTMLAttributes: {
          class: 'break-inside-auto', // Your custom class name
        },
      },
    }),
    TextSize,
    RequiredExtensions,
    TextStyle,
    FontFamily,
    TextBlocks,
    Color,
    TextAlign.configure({
      types: ['heading', 'paragraph'],
    }),
    Underline,
    Link.configure({
      openOnClick: false,
      protocols: ['ftp', 'mailto', '#popup', 'sms', 'tel', '{{', '/'],
      autolink: false,
      linkOnPaste: false,
    }),
    LineHeight,
    ListItem,
    CustomBulletList,
    HardBreakExtension.configure({
      HTMLAttributes: {
        class: 'break-after-auto page-break',
      },
    }),
  ],
  editorProps: {
    attributes: state => {
      return {
        ...state.doc.attrs,
        style: 'font-family: sans-serif', // Default font-family
      }
    },
  },
})
</script>

<template>
  <CWrapper class="flex flex-col w-full">
    <div :style="customStyleForTextParent">
      <UIRichTextEditor :editor="editor" />
    </div>
  </CWrapper>
</template>

<style lang="scss" scoped>
.editor-container {
  position: unset;
  border-radius: 0;
  border: 0px solid #d0d5dd;
  padding: 0px;
  height: 100%;
  min-width: unset;
  width: 100%;
}
.editor-wrapper {
  .tiptap.ProseMirror[contenteditable='false'] {
    white-space: break-spaces !important;
  }
  p {
    break-inside: avoid-column !important;
    font-size: 16px;
    font-family: sans-serif;
  }
}
@media print {
  .editor-wrapper {
    break-inside: avoid;
    p {
      font-size: 16px;
      font-family: sans-serif;
    }
  }
}
</style>
