<script setup lang="ts">
import { useDocumentStore } from '@/store/document'
import { StyleValue, computed, inject, ref } from 'vue'
import { UISpace } from '@gohighlevel/ghl-ui'
import FillableFieldIndicator from '../common/FillableFieldIndicator.vue'
import BlocksRenderer from '../common/BlocksRenderer.vue'
import FloatingElementRenderer from '../common/FloatingElementRenderer.vue'

import {
  IElement,
  Rotation,
  PageElementOptions,
  OLD_PAGE_HEIGHT,
  DEFAULT_PAGE_DIMENSION,
  OLD_PAGE_WIDTH,
} from '@gohighlevel/ghl-proposals-common'
import { useMobileView } from '@/composable'

const store = useDocumentStore()
const isPDFView = inject<boolean>('isPDFView')
const pageData = computed(() => store.document.pages)
const pageParentRef = ref<HTMLElement>()
const isPDFPage = (page: IElement<PageElementOptions>) =>
  page?.component.options.fromPDF

const getPageDimensions = (page: IElement<PageElementOptions>) => {
  /**
   * Page dimensions
   * @default properties from the page component
   */
  return (
    page?.component?.options?.pageDimensions || {
      dimensions: {
        width: 816,
        height: 1056,
      },
      margins: {
        top: 48,
        right: 48,
        bottom: 48,
        left: 48,
      },
      rotation: Rotation.Portrait,
    }
  )
}

const getPageViewMinHeight = (page: IElement<PageElementOptions>) => {
  if (page.version > 1) {
    const { height } = getPageDimensions(page).dimensions
    return height + 'px'
  }
  return isPDFPage(page) || page?.component.options.src
    ? `${OLD_PAGE_HEIGHT}px`
    : `${DEFAULT_PAGE_DIMENSION.dimensions.height}px`
}

const getBackgroundSize = (page: IElement<PageElementOptions>) => {
  if (isPDFPage(page)) {
    if (page.version > 1) {
      const { height, width } = getPageDimensions(page).dimensions
      return `${width}px ${height}px`
    } else {
      return `${OLD_PAGE_WIDTH}px ${OLD_PAGE_HEIGHT}px`
    }
  } else {
    return page?.responsiveStyles?.large?.backgroundSize
  }
}

const { scaleFactor } = useMobileView()

const getPageStyles = (page: IElement<PageElementOptions>): StyleValue => {
  return {
    backgroundColor: page?.responsiveStyles?.large?.backgroundColor || null,
    backgroundImage: `url('${page?.component?.options?.src}')`,
    backgroundPosition: page?.responsiveStyles?.large?.backgroundPosition,
    backgroundRepeat: page?.responsiveStyles?.large?.backgroundRepeat,
    backgroundSize: getBackgroundSize(page),
    opacity: page?.responsiveStyles?.large?.opacity / 100 || 1,
    zIndex: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    willChange: 'transform',
    transform: 'translateZ(0px)',
  }
}

const getDraggableElement = (children: IElement[]) => {
  if (!children) return []
  return children.filter(element => element && element?.component?.isDraggable)
}

const getBlockElements = (children: IElement[]) => {
  if (!children) return []
  return children.filter(element => element && !element?.component?.isDraggable)
}
</script>

<template>
  <UISpace
    justify="center"
    :align="`center`"
    :vertical="true"
    :class="isPDFView ? '!gap-0' : '!gap-8'"
    class="relative pb-[20rem]"
    :wrap="false"
    :wrap-item="false"
    :style="{
      transform: `translate3d(0px, 0px, 0px) scale3d(${scaleFactor}, ${scaleFactor}, 1)`,
      transformOrigin: 'left top',
      position: 'absolute',
    }"
  >
    <FillableFieldIndicator
      v-if="store.isEditing && store.hasPrevRecipientsCompleted"
    />
    <div
      ref="pageParentRef"
      v-for="(page, index) in pageData"
      :key="index"
      :class="`elements_page shadow-page relative ${
        isPDFView ? '!shadow-none !m-0' : ''
      }`"
    >
      <div :style="getPageStyles(page as IElement<PageElementOptions>)"></div>
      <div
        class="page-view"
        :style="{ minHeight: getPageViewMinHeight(page as IElement<PageElementOptions>), width: getPageDimensions(page as IElement<PageElementOptions>).dimensions.width + 'px'}"
      >
        <div class="page-header header-footer" v-if="!isPDFView">
          <div
            class="page-header-content"
            :style="{
              marginTop: getPageDimensions(page as IElement<PageElementOptions>).margins.top + 'px',
            }"
          ></div>
        </div>
        <div
          class="page-content"
          :style="{
            marginLeft: getPageDimensions(page as IElement<PageElementOptions>).margins.left + 'px',
            marginRight: getPageDimensions(page as IElement<PageElementOptions>).margins.right + 'px',
          }"
        >
          <div
            class="document-block"
            style="
              flex: 1 1 0%;
              width: 100%;
              display: flex;
              flex-direction: column;
            "
          >
            <FloatingElementRenderer
              :elements="getDraggableElement(page.children)"
              :pageId="page.id"
              :page="page"
              :pageRef="pageParentRef"
              :parentRef="pageParentRef"
            />
            <template
              v-for="(element, index) in getBlockElements(page.children)"
              :key="index"
            >
              <BlocksRenderer
                :page="page"
                :pageRef="pageParentRef"
                :parentRef="pageParentRef"
                :element="(element as IElement<any>)"
                :pageId="(page.id as string)"
                v-if="!element.component.isDraggable"
              />
            </template>
          </div>
        </div>
        <div class="page-footer header-footer" v-if="!isPDFView">
          <div
            class="page-footer-content"
            :style="{
              marginBottom: getPageDimensions(page as IElement<PageElementOptions>).margins.bottom + 'px',
          }"
          ></div>
        </div>
      </div>

      <div
        v-if="pageData.length != index"
        style="page-break-before: always"
      ></div>
    </div>
  </UISpace>
</template>

<style lang="scss" scoped>
.page-view {
  position: relative;
  // min-height: 960px;
  // width: 816px;
  display: flex;
  flex-direction: column;
  .pg-break {
    position: absolute;
    width: 60px;
    left: -64px;
    border-top: 2px dotted gray;
    color: rgb(141, 141, 141);
    font-size: 10px;
    padding: 2px;
    user-select: none;
    top: calc(960px + 96px);
  }
}

.page-header {
  .page-header-content {
    //   min-height: 48px;
    box-sizing: border-box;
  }
}

.header-footer {
  position: relative;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
}

.page-footer {
  .page-footer-content {
    //  min-height: 48px;
    box-sizing: border-box;
  }
}

.elements_page {
  background: #ffffff;
  &:last-child {
    margin-bottom: 2em;
  }
}

.page-content {
  // margin-left: 48px;
  // margin-right: 48px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.draggable-container {
  flex: 1 0 auto;
}
</style>
