export * from './common-properties-options'
export * from './currency'
export * from './custom-variable'
export * from './elements'
export * from './groups'
export * from './productLineItems'
export * from './rrule'
export * from './schedule-options'
export * from './styles'

export const DEFAULT_PAGE_DIMENSION = {
  dimensions: { width: 816, height: 1056 },
  margins: { top: 48, right: 48, bottom: 48, left: 48 },
}

export const OLD_PAGE_HEIGHT = 1152
export const OLD_PAGE_WIDTH = 816
export const OLD_PAGE_MARGIN = 48

export const TEMPLATE_LIBRARY_INTERNAL_AGENCY_ID = [
  'Xc3YnOTZmyvB7D1YWo4T', // staging
  'pfpm9iFi4k5yvjnstXWU', // production
]

export const INTERNAL_AGENCY_IDS = [
  '5DP4iH6HLkQsiKESj6rh',
  'YuTUZlUtrwBtvmgByZDW',
  'ktapXmiRvMM4pJHyN3b3',
] // 5DP4iH6HLkQsiKESj6rh (staging) & YuTUZlUtrwBtvmgByZDW (production) & ktapXmiRvMM4pJHyN3b3(production)
