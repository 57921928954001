import { setupSentry } from '@gohighlevel/ghl-proposals-common'
import i18n from '@gohighlevel/ghl-proposals-common/src/locales'
import '@gohighlevel/ghl-ui/dist/style.css'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import App from './App.vue'
import './assets/elements.scss'
import { router } from './router'
import './style.css'
const pinia = createPinia()
const app = createApp(App)
app.use(pinia)
app.use(router)
app.use(i18n)
app.mount('#app')
setupSentry(app, router, process.env.NODE_ENV)
