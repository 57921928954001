<script lang="ts" setup>
import { SearchMdIcon } from '@gohighlevel/ghl-icons/24/outline'
import debounce from 'lodash-es/debounce'
import { NInput, NPopover } from 'naive-ui'
import { PropType, computed, ref, useSlots, watch } from 'vue'
import { FollowerPlacement } from 'vueuc'

import BreadCrumb from './components/BreadCrumb.vue'
import DropdownItem from './components/DropdownItem.vue'
import { DropdownTreeOption } from './types'
import { getFlattenedOptions, getObjectByPath, showDebugging } from './utils'

const props = defineProps({
  popoverId: { type: String },
  options: {
    type: Array as PropType<DropdownTreeOption[]>,
    default: () => [],
  },
  pathSeparator: {
    type: String,
    default: '/',
  },
  disabled: Boolean,
  triggerLabel: String,
  debug: Boolean,
  placement: String as PropType<FollowerPlacement>,
  loading: Boolean,
  remote: Boolean,
  searchPlaceholder: {
    type: String,
    required: false,
    default: 'Search',
  },
  trigger: {
    type: String as PropType<'hover' | 'click' | 'focus' | 'manual'>,
    default: 'click',
  },
  show: {
    type: Boolean,
    default: undefined,
  },
  to: {
    type: String || Boolean,
    default: 'body',
  },
})

/**
 * flattenedOptions is needed when SEARCHING. this will flat all the nested elements and constructs the paths from root
 * 🚨 this wont consider parent element; elements which has children.length > 0
 */
const flattenedOptions = computed(() => {
  const options = getFlattenedOptions(props.options).filter(
    option => option.type !== 'parent'
  )

  if (props.debug) {
    showDebugging(`flattenedOptions`, options)
  }
  return options
})

/**
 * flattenedParents is needed for the Breadcrumb and path-construction.
 * path-construction helps us to traverse forward and backward in the tree.
 * Like for an example options data
 * [{
 * value: fruits,
 * children: [
 *  {
 *    value: 'apples',
 *    children:[
 *              {
 *                value: 'green apple'
 *              }]
 *    }]
 * }]
 *
 * path-construction would help construct a path like fruits/apples/green apple/... when you traverse the tree.
 * base value for the path-construction is option.value
 * 🚨 this considers ONLY parent element; elements which has children.length > 0
 */
const flattenedParents = computed(() => {
  const options = getFlattenedOptions(props.options).filter(
    option => option.type === 'parent'
  )

  if (props.debug) {
    showDebugging(`flattenedParents`, options)
  }
  return options
})

/**
 * Current options that are being rendered
 * - these are updated with search or
 * - when you traverse the parent/children
 */
const currentTree = ref(props.options)
watch(
  () => props.options,
  updateOptions => {
    currentTree.value = updateOptions
  }
)

/**
 * A string to indicate the current path in the tree
 * Empty string is the root
 */
const currentPath = ref('')

/**
 * pathSeparator helps define multiple paths.
 * default is '/', but it is allowed to be overwritten in case of a conflict
 */
const pathSeparator = ref(props.pathSeparator)

const searchString = ref(null)
const slots = useSlots()
const emits = defineEmits([
  'onSelect',
  'onSearch',
  'onSelectWithKeyValue',
  'onClickOutSide',
])
const isSearching = ref(false) // this is added for dropdownItem. Where i can more control compared to searchString while showing breadcrumb paths
/**
 * @param value
 * @returns null
 *
 * This helps traverse forward/downward the tree.
 * As in go to its children, their children and so-on, till there are no children left
 * This updates `currentPath` and `currentTree`
 */
const handleForwardTraversal = (value: string) => {
  if (!currentPath.value?.includes(value)) {
    if (currentPath.value) {
      currentPath.value += `${value}${pathSeparator.value}`
    } else {
      currentPath.value += `${value}${pathSeparator.value}`
    }

    currentTree.value =
      getObjectByPath({ options: props.options, path: currentPath.value }) ?? []

    if (props.debug) {
      showDebugging(
        `handleForwardTraversal(${value})`,
        `${JSON.stringify(currentTree.value)}   ${currentPath.value}`
      )
    }
  }
}

/**
 * @param value
 * @returns null
 *
 * This helps traverse reverse/upward the tree.
 * As in go to its parent and their parents and so-on
 * This updates `currentPath` and `currentTree`
 */
const handleBackwardTraversal = (path: string) => {
  const paths = path.split(pathSeparator.value).filter(Boolean)
  const updatedPath = paths.slice(0, paths.length - 1).join(pathSeparator.value)

  // Since we depend on the pathSeparator, add only if the updatedPath is not falsy
  currentPath.value = updatedPath ? `${updatedPath}${pathSeparator.value}` : ''
  currentTree.value =
    getObjectByPath({ options: props.options, path: updatedPath }) ?? []

  if (props.debug) {
    showDebugging(`handleBackwardTraversal(${path})`, currentTree.value)
  }
}

/**
 * This is just used to render the parent (breadcrumb) title
 * With the icons.
 */
const currentViewTitle = computed(() => {
  const path = currentPath.value
    .split(pathSeparator.value)
    .filter(Boolean)
    .at(-1)

  const currentOption = flattenedParents.value.find(
    option => option.value === path
  )

  if (props.debug) {
    showDebugging(`[Computed] currentViewTitle for ${path} is`, currentOption)
  }

  return currentOption
})

/**
 *
 * @param value
 * @returns null
 *
 * Handles the Search of the `watched` value called `searchString`
 * This only searches the options which don't have the children.
 *
 * You can write your own search, but in that case you need to set `remote=true`
 * and use `onSearch` emit
 */
const handleSearch = (value: string) => {
  currentPath.value = ''
  isSearching.value = true

  if (props.remote) {
    emits('onSearch', value)
  } else if (value) {
    const filteredValues = flattenedOptions.value.filter(option =>
      option.label.toLowerCase().includes(value.toLowerCase())
    )

    currentTree.value = filteredValues
  } else {
    currentTree.value = props.options
    isSearching.value = false
  }

  if (props.debug) {
    showDebugging(
      `handleSearch with remote ${props.remote} and search string is ${value}`,
      currentTree.value
    )
  }
}

// I am sure you'd get it 😏
watch(
  () => searchString,
  debounce(searchString => {
    handleSearch(searchString.value)
  }, 300),
  { deep: true }
)

/**
 * This is an event handle of on:close on popper.js
 * I am using this to reset all the values and trees
 * setTimeout is a facility to do them under the hood
 * so that users dont see while unmounting
 */
const handlePopperClose = () => {
  emits('onClickOutSide', true)
  setTimeout(() => {
    currentTree.value = props.options
    searchString.value = null
    currentPath.value = ''
  }, 300)
}

const popoverRef = ref()
const selectedValue = (value: string | number | boolean, options: any) => {
  emits('onSelect', value, options)
  // closePopup()
}
// const closePopup = () => {
//   popoverRef?.value?.handleClick()
// }
</script>

<template>
  <NPopover
    raw
    class="dropdown-tree"
    :disabled="disabled"
    :show-arrow="false"
    :trigger="trigger"
    @clickoutside="handlePopperClose"
    :placement="placement"
    ref="popoverRef"
    :show="show"
    :id="popoverId"
    :to="to"
  >
    <!--  Trigger Button -->
    <template #trigger>
      <slot name="trigger" v-if="slots.trigger" :disabled="disabled"> </slot>
    </template>

    <!--  Dropdown container -->
    <div class="dropdown-tree__container" @click="ev => ev.stopPropagation()">
      <!-- Search -->
      <div class="dropdown-tree__search">
        <NInput
          id="dropdown-tree-search"
          :placeholder="searchPlaceholder"
          v-model:value="searchString"
          :loading="loading"
          size="small"
        >
          <template #prefix>
            <slot name="searchIcon" v-if="slots.searchIcon"></slot>
            <SearchMdIcon class="icon" v-else />
          </template>
        </NInput>
      </div>

      <!-- TOP Bread crumb -->
      <BreadCrumb
        :show="Boolean(currentPath.length)"
        :title="currentViewTitle?.label"
        :disabled="!currentPath"
        @onClick="handleBackwardTraversal(currentPath)"
        :icon="currentViewTitle?.icon"
        :icon-class="currentViewTitle?.iconClass"
      />

      <!-- Dropdown items -->
      <DropdownItem
        :showPath="isSearching"
        :options="currentTree"
        @onForwardTraverse="option => handleForwardTraversal(option.value)"
        @onSelect="(value, option) => selectedValue(value, option)"
      />

      <!-- Empty state -->
      <div v-if="!isSearching && !options.length">
        <slot name="empty" v-if="slots.empty"></slot>
        <p v-else class="no-data">No data</p>
      </div>

      <!-- Empty Search -->
      <div v-if="isSearching && !currentTree.length">
        <slot name="emptySearch" v-if="slots.emptySearch"></slot>
        <p v-else class="empty-search">No matching results found</p>
      </div>
    </div>
  </NPopover>
</template>

<style scoped lang="scss">
@mixin align-center {
  display: flex;
  align-items: center;
}

@mixin icon-size {
  width: 20px;
  height: 20px;
}

@mixin text-gray {
  color: #475667;
}

.dropdown-tree {
  &__trigger {
    span {
      @include align-center;
    }

    .chevron {
      @include icon-size();
    }
  }

  &__container {
    box-sizing: border-box;
    margin-top: 0.5rem;
    border: 1px solid var(--gray-200);
    border-radius: 0.5rem;
    background-color: #fff;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
      0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    padding: 0;
    width: 19rem;
    max-height: 32rem;
    overflow-y: auto;
  }

  &__search {
    padding: 0.75rem 0.5rem;
    user-select: none;
    position: sticky;
    top: 0;
    z-index: 10;
    background: var(--base-white);

    .icon {
      @include icon-size();
      @include text-gray();
    }
  }

  .empty-search,
  .no-data {
    @include text-gray();
    padding: 4px;
    text-align: center;
  }
}

// Override for styles as we are using n-popover
.n-popover.n-popover-shared.n-popover--raw.dropdown-tree {
  box-shadow: none;
}
</style>
