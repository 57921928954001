/**
 * version >=4: Rounds of value to 2 decimal places
 * version <4: Truncates value to 2 decimal places
 * @param value
 * @param version
 * @returns
 */
export const withTwoDecimal = (value: number, version: number): number => {
  if (version && version >= 4) {
    const epsilon = 0.00001
    return Number((Math.round((value + epsilon) * 100) / 100).toFixed(2))
  }

  return Math.trunc(value * 100) / 100
}

export const calculator = (amount = 0, version: number) => {
  const percentageOf = (percentageValue = 1): number => {
    const result = (amount / 100) * percentageValue
    return withTwoDecimal(result, version)
  }

  const getPercentage = (value: number, version: number): number => {
    if (amount === 0) {
      console.error('Total cannot be zero.')
      return 0
    }
    const percent = (value / amount) * 100
    return withTwoDecimal(percent, version)
  }

  return {
    percentageOf,
    getPercentage,
  }
}
