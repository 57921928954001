import { getColorFromString } from '@gohighlevel/ghl-ui'
import { useTypography } from '../composition'
import { IRecipient } from '../types'

export const changeLightness = (
  hslString: string,
  saturation = 0,
  lightness = 0
) => {
  const regex = /hsl\((\d+),\s*(\d+)%,\s*(\d+)%\)/
  const match = hslString.match(regex)

  if (!match) {
    console.error('Invalid HSL string format')
    return hslString
  }

  const currentHue = parseInt(match[1])

  return `hsl(${currentHue},${saturation}%,${lightness}%)`
}

export const getSignatureElementColorStyles = (
  recipient: IRecipient,
  addLightness = true
) => {
  const { toCapitalize } = useTypography()
  let colorStr = 'hsl(140, 80%, 90%)'
  if (Object.keys(recipient).length > 0) {
    colorStr = getColorFromString(
      toCapitalize(
        `${recipient?.contactName}` ||
          `${recipient.firstName} ${recipient.lastName}`
      ).trim()
    )
  }
  if (addLightness) {
    return {
      borderColor: changeLightness(colorStr, 66, 52),
      backgroundColor: changeLightness(colorStr, 80, 90),
      color: changeLightness(colorStr, 97, 24),
      placeholderColor: changeLightness(colorStr, 80, 40),
    }
  }
  return {
    borderColor: colorStr,
    backgroundColor: colorStr,
    color: colorStr,
  }
}

export const parseTransformValues = (transformString: string) => {
  const translateRegex = /translate\(([^,]+),\s*([^)]+)\)/
  const scaleRegex = /scale\(([^,]+),\s*([^)]+)\)/

  const extractValues = (match: string[]) => {
    return [parseFloat(match[1]), parseFloat(match[2])]
  }

  const translateMatch = transformString.match(translateRegex)
  const scaleMatch = transformString.match(scaleRegex)

  const translateValues = translateMatch
    ? extractValues(translateMatch)
    : [0, 0]
  const scaleValues = scaleMatch
    ? extractValues(scaleMatch)
    : { scaleX: 1, scaleY: 1 }

  return {
    translate: translateValues,
    scale: { scaleX: scaleValues[0], scaleY: scaleValues[1] },
  }
}
