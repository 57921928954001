import { GroupFields, useMovable } from '..'
import { GroupFieldsOptions, ISelectedGroupElement } from '../types/elements'
import { computed, ref } from 'vue'
import {
  createGroup,
  fetchExistingGroup,
  getGroupInfoWithSelectedElements,
  getOneRecipientBasedOnAllSelectedElements,
  getPageAndElementMap,
  getPageIdForElement,
  getPageIndex,
  getRecipientInfo,
  getTypeAndChildDataForGroup,
  isAnyElementPartOfAnyGroup,
  isElementPartOfAnyGroup,
  mergeByElementId,
} from '../utils/group'

import { ELEMENTS_META } from '../const/elements'
import { fetchPrimaryRecipient } from '../utils/helpers'

const selectoGroupTargets = ref<
  {
    pageId: string
    pageIndex?: string
    elementId: string
  }[]
>([])
export const useGroupsFactory = (builderStore: any, appStore: any) => {
  const setGroupTargetsOnSelection = (
    groupTargets: {
      pageId: string
      pageIndex?: string
      elementId: string
    }[]
  ) => {
    const { selectMovableRef } = useMovable()
    selectoGroupTargets.value = groupTargets
    if (groupExist.value) {
      builderStore.setActiveGroup(groupExist)
    } else {
      builderStore.setActiveGroup(null)
    }
    if (selectoGroupTargets.value && selectoGroupTargets.value.length > 1) {
      builderStore.updateActiveElement(null)
    }
    if (
      selectoGroupTargets.value &&
      selectoGroupTargets.value.length === 0 &&
      selectMovableRef?.value
    ) {
      selectMovableRef.value.updateRect()
    }
  }

  const selectedElements = computed(() => {
    return selectoGroupTargets.value
  })

  const groupExist = computed(() => {
    const currentGroups = builderStore.groups
    const groupExist = fetchExistingGroup({
      currentGroups,
      group: selectedElements.value,
    })
    return groupExist
  })

  const groupTargets = () => {
    let recipient
    const recipients = appStore.document?.recipients
    const primaryRecipient = fetchPrimaryRecipient(recipients)
    const pages = builderStore.pages

    const currentGroups = builderStore.groups
    if (!groupExist.value) {
      const group = isAnyElementPartOfAnyGroup(
        currentGroups,
        selectedElements.value
      )

      if (group) {
        const isRecipient =
          group?.component?.options?.recipient &&
          group?.component?.options?.entityName

        const { groupType, children } = getTypeAndChildDataForGroup(
          selectedElements.value,
          pages
        )
        const childrens = mergeByElementId(children, group.children)
        builderStore.updateOneGroup(group?.id, childrens, groupType)
        builderStore.setActiveGroup(group.id)
        // update the individual elements also with the recipient details.
        if (isRecipient) {
          updateGroupElementsRecipientInfo(group)
        }
      } else {
        const { groupType, children } = getTypeAndChildDataForGroup(
          selectedElements.value,
          pages
        )
        // check all the elements has same recipient or not.
        // if its same then pass that. otherwise use the primary recipient.
        const recipientInfo = getOneRecipientBasedOnAllSelectedElements(
          children,
          pages,
          recipients
        )
        recipient = recipientInfo ? recipientInfo : primaryRecipient
        const group = createGroup({
          groupType,
          children,
          recipient,
          currentGroups,
        })
        builderStore.updateGroup(group)
        builderStore.setActiveGroup(group.id)
      }
      builderStore.autoAssignIfRecipientIdIsNotPresentInRecipientList()
    }
    builderStore.syncGroup()
    builderStore.syncGroupRecipient()
  }

  const unGroupTargets = () => {
    if (groupExist.value && groupExist.value.id) {
      builderStore.deleteGroup(groupExist.value.id)
    }
    builderStore.setActiveGroup(null)
    setGroupTargetsOnSelection([])
    builderStore.syncGroup()
  }

  const deleteGroupElements = e => {
    if (e && e.selectedElements) {
      e.selectedElements.forEach((element: ISelectedGroupElement) => {
        builderStore.deleteElement(element?.elementId as string)
        builderStore.syncRecipient()
      })
    }
    if (e && e.groupData && e.groupData?.id) {
      builderStore.deleteGroupAndGroupElements(e.groupData.id)
      builderStore.syncRecipient()
    }
    builderStore.setActiveGroup(null)
    setGroupTargetsOnSelection([])
    builderStore.syncGroup()
  }

  const cloneGroupElements = e => {
    if (e && e.selectedElements) {
      const selectedElementsMap = e.selectedElements.map(
        ({ elementId }) => elementId
      )
      const groupInfo = getGroupInfoWithSelectedElements(
        builderStore.groups,
        selectedElementsMap
      )
      const { closestGroup, group, isConflict, isPartialGroup } = groupInfo

      if (!group && !isPartialGroup && !isConflict && !closestGroup) {
        // there is no actual group available with selected elements
        // there is no partial group also available for selected elements.
        // but there is no conflicts with other groups.
        // in this case, the elements is not part of any group at all. so just clone the elements.
        selectedElementsMap.forEach(elementId => {
          builderStore.cloneElement(elementId as string)
        })
      }

      if (isPartialGroup && closestGroup && !isConflict && !group) {
        // if there is no conflicts groups and there is one closest group available
        // but there is no actual group present.
        cloneGroupWithElements(closestGroup, selectedElementsMap)
      }

      if (group && isPartialGroup && !isConflict) {
        // there is an actual group present with selected elements
        // there is no conflicts with any other groups
        cloneGroupWithElements(group, selectedElementsMap)
      }
    }
    builderStore.syncGroup()
  }

  const onCloneAndAddToGroupByGroupId = () => {
    const selectedElementsMap = selectoGroupTargets.value.map(
      ({ elementId }) => elementId
    )
    const groupInfo = getGroupInfoWithSelectedElements(
      builderStore.groups,
      selectedElementsMap
    )
    const { closestGroup, group, isConflict, isPartialGroup } = groupInfo

    if (isConflict) return

    if (group && isPartialGroup && !isConflict) {
      // there is an actual group present with selected elements
      // there is no conflicts with any other groups
      const elementInfo =
        group && group?.children && group?.children.length > 0
          ? group?.children[group?.children.length - 1]
          : null
      const elementId = elementInfo?.elementId
      const newElement = builderStore.cloneElement(elementId as string)
      const newElementId = newElement?.id || ''
      if (!newElementId) return
      const pageId = getPageIdForElement(builderStore.pages, newElementId)
      if (pageId && newElementId) {
        builderStore.addElementToExistingGroup(
          { pageId, elementId: newElementId },
          group?.id
        )
        const child = {
          pageId,
          pageIndex: getPageIndex(builderStore.pages, pageId),
          elementId: newElementId,
        }
        setGroupTargetsOnSelection([...selectoGroupTargets.value, child])
      }
      updateGroupElementsRecipientInfo(group)
      builderStore.setActiveGroup(group.id)
      builderStore.updateActiveElement(null)
    }

    if (!group && !isPartialGroup && !isConflict) {
      // there is no actual group available with selected elements
      // there is no partial group also available for selected elements.
      // but there is no conflicts with other groups.
      // in this case, the elements is not part of any group at all.
      let recipient
      const recipients = appStore.document?.recipients
      const primaryRecipient = fetchPrimaryRecipient(recipients)
      const elementId =
        selectedElementsMap && selectedElementsMap.length > 0
          ? selectedElementsMap[selectedElementsMap.length - 1]
          : null
      const newElement = builderStore.cloneElement(elementId as string)
      const newElementId = newElement?.id || ''
      const pageId = getPageIdForElement(builderStore.pages, newElementId)
      if (pageId && newElementId) {
        const child = {
          pageId,
          pageIndex: getPageIndex(builderStore.pages, pageId),
          elementId: newElementId,
        }
        const pageElementMap = [...selectoGroupTargets.value, child]
        const { groupType, children } = getTypeAndChildDataForGroup(
          pageElementMap,
          builderStore.pages
        )
        const recipientInfo = getOneRecipientBasedOnAllSelectedElements(
          children,
          builderStore.pages,
          recipients
        )

        recipient = recipientInfo ? recipientInfo : primaryRecipient
        const currentGroups = builderStore.groups
        const group = createGroup({
          groupType,
          children,
          recipient,
          currentGroups,
        })
        setGroupTargetsOnSelection([...selectoGroupTargets.value, child])
        builderStore.updateGroup(group)
        builderStore.syncGroupRecipient()
        builderStore.setActiveGroup(group.id)
      }
    }

    if (isPartialGroup && closestGroup && !isConflict && !group) {
      // if there is no conflicts groups and there is one closest group available
      // but there is no actual group present.
      const elementId =
        selectedElementsMap && selectedElementsMap.length > 0
          ? selectedElementsMap[selectedElementsMap.length - 1]
          : null
      const newElement = builderStore.cloneElement(elementId as string)
      const newElementId = newElement?.id || ''
      const pageId = getPageIdForElement(builderStore.pages, newElementId)
      if (pageId && newElementId) {
        // logic has to write here.
        const groupElementIds = closestGroup.children?.map(
          ({ elementId }) => elementId
        )
        const elementsExceptGroupElements = selectoGroupTargets.value.filter(
          ({ elementId }) => {
            return !groupElementIds.includes(elementId)
          }
        )
        const child = {
          pageId,
          pageIndex: getPageIndex(builderStore.pages, pageId),
          elementId: newElementId,
        }
        const newElements = [...elementsExceptGroupElements, child]
        builderStore.addElementsToExistingGroup(newElements, closestGroup?.id)
        setGroupTargetsOnSelection([...selectoGroupTargets.value, child])
        updateGroupElementsRecipientInfo(closestGroup)
        builderStore.setActiveGroup(closestGroup.id)
        builderStore.updateActiveElement(null)
        builderStore.syncGroupRecipient()
      }
    }

    builderStore.syncGroup()
  }

  const onCloneAndAddToGroup = (elementId: string) => {
    const recipients = appStore.document?.recipients
    const element = builderStore.getElementById(elementId as string)?.element
    const newElement = builderStore.cloneElement(elementId as string)
    const newElementId = newElement?.id || ''
    if (!newElementId) return

    let group = isElementPartOfAnyGroup(builderStore.groups, elementId)
    if (group) {
      const pageId = getPageIdForElement(builderStore.pages, newElementId)
      if (pageId && newElementId) {
        builderStore.addElementToExistingGroup(
          { pageId, elementId: newElementId },
          group?.id
        )
      }
      builderStore.setActiveGroup(group.id)
    }

    if (!group && newElement && element) {
      const currentGroups = builderStore.groups
      const pageElementMap: ISelectedGroupElement[] = getPageAndElementMap(
        [element, newElement],
        builderStore.pages
      )
      const { groupType, children } = getTypeAndChildDataForGroup(
        pageElementMap,
        builderStore.pages
      )
      const recipient = getRecipientInfo(element, recipients)
      group = createGroup({ groupType, children, recipient, currentGroups })
      builderStore.updateGroup(group)
      updateGroupElementsRecipientInfo(group)
      builderStore.setActiveGroup(group.id)
      setGroupTargetsOnSelection(pageElementMap)
      builderStore.updateActiveElement(null)
    }
    builderStore.syncGroup()
  }

  const updateGroupOptions = (
    key: string,
    value: string | number,
    id: string
  ) => {
    if (id)
      builderStore.updateGroupOption({
        id: id,
        key,
        value,
      })
  }

  const updateGroupElementsRecipientInfo = (
    group: GroupFields<GroupFieldsOptions>
  ) => {
    if (group) {
      const isRecipient =
        group?.component?.options?.recipient &&
        group?.component?.options?.entityName

      if (isRecipient) {
        group?.children.forEach(({ elementId }) => {
          const element = builderStore.getElementById(elementId)?.element
          if (element) {
            builderStore.updateElementOption(
              ELEMENTS_META.RECIPIENT,
              group?.component?.options?.recipient,
              element.id,
              element.type
            )
            builderStore.updateElementOption(
              ELEMENTS_META.ENTITY_NAME,
              group?.component?.options?.entityName,
              element.id,
              element.type
            )
          }
        })
      }
    }
  }

  const cloneGroupWithElements = (
    group: GroupFields<GroupFieldsOptions>,
    selectedElementsMap: string[]
  ) => {
    const recipients = appStore.document?.recipients
    const currentGroups = builderStore.groups
    const cgChild = group.children?.map(({ elementId }) => elementId)
    const newGroupChildren: { pageId: string; elementId: string }[] = []
    selectedElementsMap.forEach(elementId => {
      const newElement = builderStore.cloneElement(elementId as string)
      const newElementId = newElement?.id || ''
      if (newElementId) {
        if (cgChild.includes(elementId)) {
          const pageId = getPageIdForElement(builderStore.pages, newElementId)
          newGroupChildren.push({ pageId, elementId: newElementId })
        }
      }
    })

    const { groupType, children } = getTypeAndChildDataForGroup(
      newGroupChildren,
      builderStore.pages
    )
    const rule = group?.component?.options?.rule || ''
    const ruleValue = group?.component?.options?.ruleValue || ''
    const recipient = getRecipientInfo(group, recipients)
    const newGroup = createGroup({
      groupType,
      children,
      recipient,
      currentGroups,
      rule,
      ruleValue,
    })
    builderStore.updateGroup(newGroup)
    builderStore.syncGroupRecipient()
    builderStore.updateActiveElement(null)
    const allChildren = newGroup?.children?.flatMap(child => {
      return {
        ...child,
        pageIndex: getPageIndex(builderStore.pages, child.pageId),
      }
    })
    if (allChildren && allChildren.length > 1)
      setGroupTargetsOnSelection(allChildren)
    builderStore.setActiveGroup(newGroup.id)
  }

  return {
    selectoGroupTargets,
    groupExist,
    selectedElements,
    groupTargets,
    unGroupTargets,
    deleteGroupElements,
    cloneGroupElements,
    onCloneAndAddToGroup,
    setGroupTargetsOnSelection,
    onCloneAndAddToGroupByGroupId,
    updateGroupOptions,
  }
}
