import {
  BuilderComponentOptionsTypes,
  FillableField,
  IElement,
  IPage,
  IPricingTable,
  LineItem,
} from '../types'

import { ELEMENTS_LOOKUP } from '..'

const checkIfShowNamePropertyExists = (
  element: IElement<BuilderComponentOptionsTypes>
) => {
  return (
    element.type === ELEMENTS_LOOKUP.SIGNATURE &&
    element.version > 1 &&
    !Object.hasOwnProperty.call(element.component.options, 'showName')
  )
}
export const setFillableFieldsDataIntoDocument = (
  pages = [] as IPage[],
  fields = [] as FillableField[],
  pricingTables = [] as IPricingTable[]
) => {
  const fieldsMap = fields.reduce((acc, textField) => {
    acc[textField.id] = textField
    return acc
  }, {} as Record<string, FillableField>)
  const pricingTablesMap = pricingTables?.reduce((acc, table) => {
    acc[table.id] = table.lineItems
    return acc
  }, {} as Record<string, LineItem[]>)

  const updateChild = (child: IElement<BuilderComponentOptionsTypes>) => {
    return {
      ...child,
      children: child?.children?.map(updateChild) || [],
      component: {
        ...child.component,
        options: {
          ...child.component?.options,
          ...(checkIfShowNamePropertyExists(child) ? { showName: true } : {}), // Add showName property to signature elements that don't have it (version > 1
          ...(child.type === ELEMENTS_LOOKUP.SIGNATURE
            ? {
                src: fieldsMap[child.id]?.value,
              }
            : {
                text: fieldsMap[child.id]
                  ? fieldsMap[child.id]?.value
                  : child.component?.options?.text,
              }),
          ...(pricingTablesMap[child.id]
            ? { lineItems: pricingTablesMap[child.id] }
            : {}),
        },
      },
    }
  }

  return pages.map(page => {
    return {
      ...page,
      children: page.children.map(child => {
        if (child.type === ELEMENTS_LOOKUP.ROW) {
          return {
            ...child,
            children: child.children.map(column => {
              return {
                ...column,
                children: column.children.map(el => {
                  return {
                    ...el,
                    children: el?.children?.map(updateChild) || [],
                    component: {
                      ...el.component,
                      options: {
                        ...el.component.options,
                        ...(pricingTablesMap[el.id]
                          ? { lineItems: pricingTablesMap[el.id] }
                          : {}),
                      },
                    },
                  }
                }),
              }
            }),
          }
        } else {
          return updateChild(child)
        }
      }),
    }
  })
}
