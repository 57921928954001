<script lang="ts" setup>
/**
 *
 * @component DynamicEditableField
 * @description A dynamic editable field component that allows users to edit different types of data such as text, numbers, dates, and rich text.
 *
 * @prop {string} align - The alignment of the field content. Default is 'left'.
 * @prop {string | number} value - The initial value of the field.
 * @prop {boolean} isCurrency - Indicates whether the value is a currency. Default is false.
 * @prop {boolean} editable - Indicates whether the field is editable. Default is false.
 * @prop {boolean} isRichText - Indicates whether the field supports rich text editing. Default is false.
 * @prop {boolean} rowHovered - Indicates whether the row containing the field is being hovered. Default is false.
 * @prop {boolean} rowSelected - Indicates whether the row containing the field is selected. Default is false.
 *
 * @event onUpdateValue - Emitted when the value of the field is updated.
 * @event onInputFocus - Emitted when the field gains or loses focus.
 *
 * @example
 * <DynamicEditableField
 *   align="left"
 *   value="Hello World"
 *   isCurrency="true"
 *   editable="true"
 *   isRichText="false"
 *   rowHovered="false"
 *   rowSelected="false"
 *   @onUpdateValue="handleUpdateValue"
 *   @onInputFocus="handleInputFocus"
 * />
 */

import { Ref, ref } from 'vue'
import {
  UIDatepicker,
  UIFormItem,
  UIInput,
  UIInputNumber,
  UIModal,
  UIModalContent,
  UIModalFooter,
  UIModalHeader,
  UITextXsRegular,
} from '@gohighlevel/ghl-ui'
import { toRef } from 'vue'
import { formatDate } from '../../utils'
import { DynamicEditableFieldProps } from './props'
import { RichTextInputField } from '../RichTextInputField'
import { computed } from 'vue'

const props = withDefaults(defineProps<DynamicEditableFieldProps>(), {
  align: 'left',
  value: '',
  isCurrency: false,
  editable: false,
  isRichText: false,
  rowActive: false,
  min: 0,
  max: 999999999,
})
const showRichTextModal = ref(false)
const isEdit = ref(false)
const inputValue: Ref<string | number> = ref(props.value)
const richTextInputValue = toRef(props.richText || '')
const richTextValue = ref('')
const inputRef = ref<HTMLInputElement>({} as HTMLInputElement)
const hasError = ref(false)

const isRowActive = computed(() => props.rowActive)

const handleOnClick = (e: MouseEvent) => {
  e?.stopImmediatePropagation()
  if (props.editable) {
    props?.onInputFocus && props?.onInputFocus(true)
    isEdit.value = true
    if (props.isRichText) {
      showRichTextModal.value = true
    }
  }
}

const handleChange = (value: string) => {
  inputValue.value = value
  props?.onUpdateValue(inputValue.value)
  props?.onInputFocus && props?.onInputFocus(true)
}
const handleNumberChange = (value: number) => {
  inputValue.value = value
  props?.onUpdateValue(inputValue.value)
}

const onBlur = () => {
  props?.onUpdateValue(inputValue.value)
  props?.onInputFocus && props?.onInputFocus(false)
  isEdit.value = false
}

const validator = {
  trigger: props.validateOn,
  validator() {
    const error = props?.validator ? props?.validator(inputValue.value) : null
    hasError.value = error instanceof Error
    return error
  },
}

const dateValidators = {
  disablePreviousDate: props?.dateProps?.disablePreviousDate,
}
const getValue = () => {
  if (props.isDate) {
    return formatDate(props.value, 'LL')
  }
  if (props.isCurrency) {
    return props?.formatCurrency
      ? props.formatCurrency(props.value as number)
      : props.value
  }
  return props.value
}

const onModalCancel = () => {
  showRichTextModal.value = false
  isEdit.value = false
}
const onSuccess = () => {
  richTextInputValue.value = richTextValue.value
  showRichTextModal.value = false
  isEdit.value = false
  props?.onUpdateValue(richTextInputValue.value)
}
const handleRichTextChange = (value: string) => {
  richTextValue.value = value
}
const randomKey = computed(() => Math.random().toString(36).substring(7))
</script>

<template>
  <UIModal
    :show="showRichTextModal"
    display-directive="if"
    :width="920"
    :key="`modal-key-${randomKey}`"
    :id="`modal-id-${randomKey}`"
    :trap-focus="false"
    :auto-focus="false"
    className="rich-text-dynamic-field-editor-modal"
  >
    <template #header>
      <UIModalHeader
        id="rich-text-dynamic-field-editor-modal-header"
        class="border-b"
        title="Edit Description"
        @close="onModalCancel"
      />
    </template>
    <UIModalContent>
      <RichTextInputField
        :editable="props.editable"
        :value="richTextInputValue as string"
        @onChange="handleRichTextChange"
        to=".rich-text-dynamic-field-editor-modal"
      />
    </UIModalContent>
    <template #footer>
      <UIModalFooter
        id="ui-modal-footer"
        :negativeText="$t('common.cancel')"
        :positiveText="$t('common.save')"
        @negativeClick="onModalCancel"
        @positiveClick="onSuccess"
      />
    </template>
  </UIModal>
  <div
    class="editable-table-input"
    @click="handleOnClick"
    :style="{
      maxWidth: '90%',
      textAlign: props.align,
    }"
  >
    <UIFormItem
      v-if="isEdit && props.editable"
      :rule="validator"
      :showLabel="false"
      :showFeedback="false"
      :class="{
        'editable-table-input-form-item': true,
      }"
    >
      <UIDatepicker
        v-if="props.isDate && isEdit"
        :id="props.id"
        ref="inputRef"
        :value="inputValue as number"
        :is-date-disabled="dateValidators.disablePreviousDate"
        @blur="onBlur"
        @update:value="handleChange"
        :clearable="false"
        size="medium"
      />
      <UIInputNumber
        v-else-if="props.isNumber && isEdit"
        :id="props.id"
        ref="inputRef"
        :modelValue="inputValue as number"
        @update:modelValue="handleNumberChange"
        @blur="onBlur"
        class="rounded-md focused"
        size="medium"
        :show-button="false"
        :step="1"
        :min="props.min"
        :max="props.max"
      />
      <template v-else-if="props.isRichText && isEdit">
        <div
          v-html="richTextInputValue"
          class="richText-el"
          :class="{ 'not-readonly': editable }"
          :style="{
            marginTop: '10px',
            minHeight: '30px',
          }"
        ></div>
      </template>

      <UIInput
        v-else
        :id="props.id"
        ref="inputRef"
        type="textarea"
        :modelValue="inputValue as string"
        @update:modelValue="handleChange"
        @blur="onBlur"
        class="rounded-md"
        size="medium"
        :clearable="false"
        :autosize="true"
      />
    </UIFormItem>
    <template v-else>
      <template v-if="props.isRichText">
        <div
          v-if="richTextInputValue"
          v-html="richTextInputValue"
          class="richText-el"
          :style="{
            marginTop: '10px',
            minHeight: '30px',
          }"
          :class="{ 'not-readonly': editable }"
        ></div>
        <div
          v-else-if="!richTextInputValue && isRowActive && props.editable"
          class="text-value richText-el with-outline items-center flex"
          :style="{ minHeight: '30px', marginTop: '10px' }"
        >
          <UITextXsRegular :style="{ color: 'var(--gray-400)' }"
            >Add Description</UITextXsRegular
          >
        </div>
      </template>
      <UIFormItem
        v-else
        :showLabel="false"
        :showFeedback="false"
        class="w-full block hover:outline-1 hover-outline-primary-600 editable-table-input-form-item"
        :class="{
          'editable-table-input-form-item--readOnly': editable,
        }"
      >
        <UIInput
          ref="inputRef"
          :id="`disabled-${props.id}`"
          :value="getValue()"
          type="textarea"
          class="font-medium rounded-md"
          :class="{ 'to-text': !props.editable }"
          size="medium"
          :clearable="false"
          :autosize="true"
          :readonly="true"
        />
      </UIFormItem>
    </template>
  </div>
</template>

<style lang="scss">
.richText-el {
  padding: 0px 8px;
  p {
    margin: 0;
  }
  &.with-outline {
    outline: 1px solid var(--primary-600);
    border-radius: 4px;
  }
  &.not-readonly {
    &:hover,
    &:focus {
      outline: 1px solid var(--primary-600);
      border-radius: 4px;
    }
  }
}
.editable-table-input-form-item {
  .to-text {
    box-shadow: none !important;
    border: 1px solid transparent !important;
    --n-border: 1px solid transparent !important;
    --n-border-disabled: 1px solid transparent !important;
    --n-border-hover: 1px solid transparent !important;
    --n-border-focus: 1px solid transparent !important;
    --n-border-warning: 1px solid transparent !important;
    --n-border-focus-warning: 1px solid transparent !important;
    --n-border-hover-warning: 1px solid transparent !important;
    --n-border-error: 1px solid transparent !important;
    --n-border-focus-error: 1px solid transparent !important;
    --n-border-hover-error: 1px solid transparent !important;
    background-color: transparent;
  }
  .n-form-item-blank {
    .hl-input-text {
      .n-input-wrapper {
        .n-input__suffix {
          display: none !important;
        }
      }
    }
  }
  &--readOnly {
    .n-form-item-blank {
      .hl-input-text {
        box-shadow: none !important;
        color: #333 !important;
        .n-input__border {
          border: 1px solid transparent !important;
        }
      }
    }
  }
}
</style>
