<script setup lang="ts">
import { useRoute } from 'vue-router'
import BaseDocument from './BaseDocument.vue'
import { computed } from 'vue'
import PDFPageLoop from '@/components/page/PDFPageLoop.vue'
import CertificatePageLoop from '@/components/page/CertificatePageLoop.vue'

const route = useRoute()
const isOnlyCertificate = computed(
  () => route?.query?.onlyCertificate == 'true'
)

const pagination = computed(() => {
  if (route?.query?.from && route?.query?.to) {
    return { from: route?.query?.from, to: route?.query?.to }
  }
  return {}
})
</script>

<template>
  <BaseDocument v-slot="props">
    <template v-if="!props.loading">
      <div id="page-renderer" v-if="!isOnlyCertificate">
        <PDFPageLoop :isPreview="false" :pagination="pagination" />
      </div>
      <div
        id="certificate-renderer"
        v-if="isOnlyCertificate && props.hasCertificate"
      >
        <CertificatePageLoop />
      </div>
    </template>
  </BaseDocument>
</template>
