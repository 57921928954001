import i18n from '../locales'

export const backgroundPositionOptions = [
  {
    value: 'center center',
    label: i18n.global.t('proposals.center'),
  },
  {
    value: 'top center',
    label: i18n.global.t('proposals.topCenter'),
  },
  {
    value: 'top left',
    label: i18n.global.t('proposals.topLeft'),
  },
  {
    value: 'top right',
    label: i18n.global.t('proposals.topRight'),
  },
  {
    value: 'bottom right',
    label: i18n.global.t('proposals.bottomRight'),
  },
  {
    value: 'bottom left',
    label: i18n.global.t('proposals.bottomLeft'),
  },
  {
    value: 'bottom center',
    label: i18n.global.t('proposals.bottomCenter'),
  },
]

export const backgroundSizeOptions = [
  {
    value: 'auto',
    label: i18n.global.t('proposals.auto'),
  },
  {
    value: 'contain',
    label: i18n.global.t('proposals.fitToSize'),
  },
  {
    value: 'cover',
    label: i18n.global.t('proposals.fillPage'),
  },
]

export const backgroundRepeatOptions = [
  {
    value: 'no-repeat',
    label: i18n.global.t('proposals.noRepeat'),
  },
  {
    value: 'repeat',
    label: i18n.global.t('proposals.repeat'),
  },
  {
    value: 'repeat-x',
    label: i18n.global.t('proposals.repeatX'),
  },
  {
    value: 'repeat-y',
    label: i18n.global.t('proposals.repeatY'),
  },
]
