<script setup lang="ts">
/**
 * DynamicTable component displays a table with dynamic data and provides various functionalities like row selection, row deletion, and checkbox selection.
 *
 * @component DynamicTable
 *
 * @props {boolean} allowRowDelete - Determines whether row deletion is allowed. Default value is `false`.
 * @props {boolean} withCheckBox - Determines whether checkbox selection is enabled. Default value is `false`.
 * @props {string} uniqueKey - The unique key used to identify each row in the table. Default value is `'id'`.
 * @props {Array} config - An array of objects representing the table columns. Each object should have the following properties:
 *   - `title` (string): The title of the column.
 *   - `width` (string): The width of the column.
 *   - `align` (string): The alignment of the column content. Default value is `'left'`.
 *   - `headerClass` (string): Additional CSS classes to be applied to the column header.
 *   - `columnClass` (string): Additional CSS classes to be applied to the column cells.
 *   - `renderHead` (function): A function that returns a custom rendered content for the column header.
 *   - `renderCell` (function): A function that returns a custom rendered content for the column cells.
 * @props {Array} data - An array of objects representing the table rows.
 *
 * @emits {Function} onRowHover - Emitted when a row is hovered. The callback function receives the hovered row as a parameter.
 * @emits {Function} onRowDelete - Emitted when a row is deleted. The callback function receives the deleted row as a parameter.
 * @emits {Function} onRowSelect - Emitted when a row is selected. The callback function receives the selected row as a parameter.
 * @emits {Function} onSelectionChange - Emitted when the checkbox selection changes. The callback function receives the changed row and the new checkbox value as parameters.
 *
 * @example
 * <DynamicTable
 *   :allowRowDelete="true"
 *   :withCheckBox="true"
 *   :uniqueKey="'id'"
 *   :config="[
 *     { title: 'Name', width: '200px' },
 *     { title: 'Age', width: '100px' },
 *     { title: 'Email', width: '300px' }
 *   ]"
 *   :data="[
 *     { id: 1, name: 'John Doe', age: 30, email: 'john.doe@example.com' },
 *     { id: 2, name: 'Jane Smith', age: 25, email: 'jane.smith@example.com' },
 *     { id: 3, name: 'Bob Johnson', age: 40, email: 'bob.johnson@example.com' }
 *   ]"
 *   @onRowHover="handleRowHover"
 *   @onRowDelete="handleRowDelete"
 *   @onRowSelect="handleRowSelect"
 *   @onSelectionChange="handleSelectionChange"
 * />
 */
import { computed, ref } from 'vue'
import { DynamicTableEvents, DynamicTableProps } from './DynamicTable.props'
import { UIButton, UICheckbox } from '@gohighlevel/ghl-ui'
import { XCloseIcon } from '@gohighlevel/ghl-icons/24/outline'

const props = withDefaults(defineProps<DynamicTableProps>(), {
  allowRowDelete: false,
  withCheckBox: false,
  uniqueKey: 'id',
})
const emit = defineEmits([
  DynamicTableEvents.ON_ROW_HOVER,
  DynamicTableEvents.ON_ROW_DELETE,
  DynamicTableEvents.ON_ROW_SELECT,
  DynamicTableEvents.ON_SELECTION_CHANGE,
])
const data = computed(() => props.data)
const activeRow = computed(() => props.activeRow!)
const isSelected = ref(activeRow.value ? true : false)
const hoveredRow = ref(activeRow.value || null)

const handleRowClick = (e: MouseEvent, row: any) => {
  e.stopImmediatePropagation()
  if (props.selectable) {
    const item = isRowSelected(row) ? null : row
    isSelected.value = item ? true : false
    emit(DynamicTableEvents.ON_ROW_SELECT, item)
  }
}
const onHover = (_e: MouseEvent, row: any) => {
  if (props.selectable) {
    hoveredRow.value = row
    emit(DynamicTableEvents.ON_ROW_HOVER, hoveredRow.value)
  }
}

const isRowHovered = (row: any) => {
  return (
    hoveredRow.value &&
    row[props.uniqueKey] === hoveredRow.value[props.uniqueKey]
  )
}

const isRowSelected = (row: any) => {
  return (
    activeRow.value && row[props.uniqueKey] === activeRow.value[props.uniqueKey]
  )
}
const isRowActive = (row: any) => {
  return isRowHovered(row) || isRowSelected(row)
}

const onDelete = (row: any) => {
  emit(DynamicTableEvents.ON_ROW_DELETE, row)
  emit(DynamicTableEvents.ON_ROW_SELECT, null)
}
const onCheckBoxChange = (row: any, value: boolean) => {
  emit(DynamicTableEvents.ON_SELECTION_CHANGE, row, value)
}
</script>

<template>
  <div class="rounded-t-xl overflow-auto w-full">
    <table
      class="dynamic-table table-auto border-collapse w-full rounded-table-rows"
    >
      <thead>
        <tr>
          <th
            v-if="props.withCheckBox"
            class="text-center border-0 border-b-2"
            style="width: 36px; padding: 0 4px"
          ></th>
          <th
            :width="config.width"
            :style="`text-align: ${config.align || 'left'}; ${
              config.width ? `width:${config.width};` : ''
            }`"
            :class="`p-4 ${
              config.headerClass || ''
            } border-b-2 border-primary-600`"
            v-for="(config, index) in props.config"
            v-bind:key="index"
          >
            {{ config.renderHead ? config.renderHead() : config.title }}
          </th>
          <th
            v-if="props.allowRowDelete"
            class="text-right border-solid border-b-2 border-primary-600"
            style="width: 36px; padding: 0 4px"
          ></th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="hover:bg-primary-200 rounded-md"
          :class="{
            'cursor-pointer': props.selectable,
            'bg-primary-50': isRowActive(row),
            'row-selected': isRowSelected(row),
          }"
          v-for="(row, index) in data"
          v-bind:key="row.key"
          @click="e => handleRowClick(e, row)"
          @mouseover="e => onHover(e, row)"
          @mouseleave="e => onHover(e, null)"
        >
          <template v-if="props.withCheckBox">
            <td
              style="text-align: center; padding-top: 24px"
              @click="e => e.stopImmediatePropagation()"
            >
              <UICheckbox
                @update:checked="val => onCheckBoxChange(row, val)"
                :id="`product-line-item-checkbox-${index}`"
                :checked="row.selected"
                :disabled="row.disabled || !props.selectable"
              ></UICheckbox>
            </td>
          </template>
          <td
            v-for="(config, idx) in props.config"
            v-bind:key="`${row.key}-${config.key}`"
            class="p-4 font-medium"
            :class="{
              [config.columnClass as string]: true,
            }"
          >
            <template v-if="config.renderCell">
              <component
                :is="
                  config.renderCell &&
                  config.renderCell(row, {
                    index: idx,
                    rowActive: isRowActive(row),
                  })
                "
              ></component>
            </template>
            <template v-if="!config.renderCell">{{
              row[config.key as keyof DynamicTableProps]
            }}</template>
          </td>
          <template v-if="props.allowRowDelete">
            <td class="text-right delete-action" style="padding-top: 16px">
              <UIButton
                v-if="isRowActive(row)"
                @click="() => onDelete(row)"
                :id="`delete-btn-${index}`"
                :circle="true"
                :quaternary="true"
                ><XCloseIcon
                  class="w-5 h-5 text-error-500"
                  style="color: var(--error-500)"
              /></UIButton>
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style lang="scss">
.dynamic-table {
  tbody {
    tr {
      &:first-child {
        &.row-selected {
          box-shadow: inset 0px 0.5px 0.5px 2px var(--primary-400);
        }
      }
    }
    td {
      vertical-align: top;
    }
  }
}
.row-selected {
  box-shadow: inset 0 0 0px 1px var(--primary-400);
}
.rounded-table-rows {
  th,
  td {
    &:first-child {
      border-radius: 6px 0 0 6px;
    }
    &:last-child {
      border-radius: 0 6px 6px 0;
    }
  }
}
</style>
