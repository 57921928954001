<script setup lang="ts">
/**
 * @file RichTextInputField.vue
 * @description This file contains the definition of the RichTextInputField component.
 */
import {
  RTEControlBold,
  RTEControlColorPicker,
  RTEControlEmbedLink,
  RTEControlFontFamily,
  RTEControlFontSize,
  RTEControlItalic,
  RTEControlLineHeight,
  RTEControlList,
  RTEControlResetStyles,
  RTEControlStrike,
  RTEControlTextAlignment,
  RTEControlTextBlocks,
  RTEControlUnderline,
  RTEControlSuperscript,
  RTEControlSubscript,
  AllExtensions,
  UIRichTextEditor,
  Link,
} from '@gohighlevel/ghl-text-editor'

import Collaboration from '@tiptap/extension-collaboration'
import * as Y from 'yjs'
import { Editor } from '@tiptap/vue-3'

import { EditorState } from '@tiptap/pm/state'
import { onMounted } from 'vue'
import { CommonFieldEmits } from '../../enums'
import { ExtendedFontFamily } from '../../extensions'

const props = defineProps({
  to: {
    type: String,
    default: 'body',
  },
  editable: {
    required: true,
    type: Boolean,
    default: true,
  },
  value: {
    required: true,
    type: String,
    default: '',
  },
  minHeight: {
    type: String,
    default: '400px',
  },
  maxHeight: {
    type: String,
    default: '60vh',
  },
})
const emits = defineEmits([CommonFieldEmits.ON_CHANGE])

const editor = new Editor({
  extensions: [
    Collaboration.configure({
      document: new Y.Doc(),
    }),
    AllExtensions.configure({
      image: {
        inline: true,
        allowBase64: true,
      },
      fontSize: {
        HTMLAttributes: {
          class: 'bolder',
        },
      },
      textAlignment: {
        types: ['heading', 'paragraph'],
      },
      divTag: {
        inline: false,
        preserveWhitespace: false,
      },
    }),
    Link.configure({
      openOnClick: false,
      protocols: ['ftp', 'mailto', '#popup', 'sms', 'tel', '{{', '/'],
      autolink: true,
      linkOnPaste: false,
    }),
  ],
  autofocus: false,
  editable: props.editable,
  onUpdate({ editor }) {
    let html = editor.getHTML()
    if (html === '<p></p>') html = ''
    emits(CommonFieldEmits.ON_CHANGE, html)
  },
  enableInputRules: [ExtendedFontFamily],
  parseOptions: {
    preserveWhitespace: true,
  },
  editorProps: {
    attributes: (state: EditorState) => {
      return {
        ...state.doc.attrs,
        style: 'font-family: sans-serif', // Default font-family
      }
    },
  },
})
onMounted(() => {
  editor.commands.setContent(props.value)
  emits(CommonFieldEmits.ON_CHANGE, props.value)
})
</script>

<template>
  <div v-if="editor" class="flex">
    <UIRichTextEditor
      :editor="editor"
      container-class="rich_text-editor--container"
    >
      <template #toolbar>
        <div
          class="all-formats__toolbar rich_text-editor--toolbar flex gap-2 flex-wrap z-[999999]"
        >
          <RTEControlBold :editor="editor" />
          <RTEControlUnderline :editor="editor" />
          <RTEControlItalic :editor="editor" />
          <RTEControlStrike :editor="editor" />
          <RTEControlResetStyles :editor="editor" />
          <RTEControlBlockQuote :editor="editor" />
          <RTEControlResetStyles :editor="editor" reset="document" />
          <RTEControlTextAlignment :editor="editor" align="left" />
          <RTEControlTextAlignment :editor="editor" align="center" />
          <RTEControlTextAlignment :editor="editor" align="right" />
          <RTEControlTextAlignment :editor="editor" align="justify" />
          <RTEControlColorPicker :editor="editor" v-bind="{}" />
          <RTEControlList :editor="editor" listType="bulletList" />
          <RTEControlList :editor="editor" listType="circleList" />
          <RTEControlList :editor="editor" listType="squareList" />
          <RTEControlList :editor="editor" listType="orderedList" />
          <RTEControlList :editor="editor" listType="lowerAlphaList" />
          <RTEControlList :editor="editor" listType="lowerGreekList" />
          <RTEControlList :editor="editor" listType="lowerRomanList" />
          <RTEControlList :editor="editor" listType="upperAlphaList" />
          <RTEControlList :editor="editor" listType="upperRomanList" />
          <RTEControlSubscript :editor="editor" />
          <RTEControlSuperscript :editor="editor" />
          <!-- <RTEControlEmbedImage
            :editor="editor"
            :enableFloatingMenu="true"
            :enableExternalEmbed="true"
            :modal-container="to"
          /> -->
          <RTEControlEmbedLink :editor="editor" :modal-container="to" />
          <RTEControlFontSize :editor="editor" />
          <RTEControlTextBlocks
            :editor="editor"
            :options="[
              'Heading1',
              'Heading2',
              'Heading3',
              'Heading4',
              'Heading5',
              'Paragraph',
            ]"
          />
          <RTEControlFontFamily
            :editor="editor"
            :fallback-font="'sans-serif'"
          />
          <RTEControlLineHeight :editor="editor" />
        </div>
      </template>
    </UIRichTextEditor>
  </div>
</template>

<style lang="scss">
.v-binder-follower-container {
  z-index: 999999 !important;
}
.rich_text-editor--container {
  padding: 0px !important;
  min-height: v-bind('props.minHeight') !important;
  .toolbar_container {
    padding: 8px !important;
  }
  .editor-wrapper {
    max-height: v-bind('props.maxHeight') !important;
    overflow: auto;
    padding: 0 8px !important;
  }
}
.rich_text-editor--toolbar {
  .n-select {
    width: auto;
    display: inline-block;
  }
}
</style>
