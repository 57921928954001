<script setup lang="ts">
import { UIContentWrap } from '@gohighlevel/ghl-ui'
import { onMounted, provide } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
const i18n = useI18n()

const route = useRoute()
provide('route', route)

// Function to set locales
const setLocales = (lang: string) => {
  let locale = lang
  locale = locale.replace('_', '-')
  const localLang = i18n.availableLocales.includes(locale) ? locale : 'en-US'
  localStorage.setItem('locales', localLang)
  i18n.locale.value = localLang
}

const getUrlParameter = (name: string): string | null => {
  if (typeof window === 'undefined' || !window.location.search) {
    return null
  }
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(name)
}

// Get the locale from the URL parameters
onMounted(() => {
  const locale = getUrlParameter('locale') || 'en-US' // Default to 'en-US' if not provided
  setLocales(locale)
})
</script>

<template>
  <UIContentWrap full-screen>
    <router-view />
  </UIContentWrap>
</template>

<style lang="scss">
@keyframes point {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-10px);
  }
  60% {
    transform: translateX(-5px);
  }
}

.point-el {
  //  border-width: 1px !important;
  //  box-sizing: content-box !important;
  //  border-radius: 0.125em;
  //  transition: all ease;
  //  border-style: solid !important;
  //  border-color: red !important;
  // box-shadow: 1px 1px 0px 2px red;
  &.filled {
    // box-shadow: 0px 0px 0px 2px red !important;
  }
  &:after {
    position: absolute;
    width: 100%;
    content: '';
    top: 0px;
    left: 0px;
    height: 100%;
    outline: 2px solid #fec84b;
    border-radius: 1px;
    outline-offset: 2px;
    z-index: -1;
  }
  //   &.signature-el,
  //   &.date-field-el {
  //     &::after {
  //       top: 1px;
  //       left: 1px;
  //     }
  //  }
}
</style>
