import {
  DATE_FIELD_DATE_VALIDATION,
  IPaymentStatus,
  LineItem,
  PaymentSchedule,
  ProductsDiscountProps,
} from '../components'

import { IContact } from '../service.schema'
import { ICurrency } from '../rrule'
import { PageConfig } from '../common'

export interface ICreateElement {
  type: string
}

export enum RecipientEntityTypeEnum {
  CONTACTS = 'contacts',
  USERS = 'users',
}

export enum RecipientRolesEnum {
  USER = 'user',
  SIGNER = 'signer',
}

export interface FillableFieldInfo {
  text: string
  fieldId: string
  filledDate: string
}
export type IRecipient = Pick<
  IContact,
  'firstName' | 'lastName' | 'email' | 'id'
> & {
  hasCompleted: boolean
  signingOrder: number
  shareLink: string
  contactName?: string
  phone?: string
  country: string
  imgUrl: string
  signedDate?: string
  isPrimary?: boolean
  entityName?: RecipientEntityTypeEnum
  role?: RecipientRolesEnum
  fillableFields?: FillableFieldInfo[]
  initialsImgUrl?: string
  lastViewedAt?: string
}

export interface IComponentOptions {
  placeholder?: string
  text?: string
  src?: string
  altText?: string
  href?: string
  required?: boolean
  fieldId?: string
  html?: string
  lineItems: LineItem[]
  paymentSchedules: PaymentSchedule[]
  paymentStatus: IPaymentStatus
  currencyCode: ICurrency['code']
  recipient: string
  signedDate?: Date | null | string | number
  isGhost?: boolean
  isFilled?: boolean
  filledDate?: Date | null | string | number
  entityName?: RecipientEntityTypeEnum
  preChecked?: boolean
}

export type ComponentOptionsTypes = IComponentOptions[keyof IComponentOptions]

export type GroupComponentOptionsTypes =
  GroupFieldsOptions[keyof GroupFieldsOptions]

export interface IResponsiveStylesOptions {
  [key: string]: any
  position?: {
    top: number
    left: number
    bottom: number
    preferBottom?: boolean
    right: number
    preferRight?: boolean
  }
  scale?: { scaleX: number; scaleY: number }
  dimensions?: { height: number; width: number }
}

export interface IDynamic {
  [key: string]: string
}

export interface IResponsiveStyles {
  large: IResponsiveStylesOptions
  medium?: IResponsiveStylesOptions
  small?: IResponsiveStylesOptions
}

export interface IComponent<T> {
  name: string
  options: T
  isDraggable?: boolean
}

export interface IElement<T = any> {
  type: string
  version: number
  id: string
  children: Array<IElement<T>>
  responsiveStyles: IResponsiveStyles
  component: IComponent<T>
  meta?: IDynamic
}

export type TextElementOptions = Pick<IComponentOptions, 'text'>

export type ImgElementOptions = Pick<
  IComponentOptions,
  'src' | 'altText' | 'href'
>

export type VideoElementOptions = Pick<IComponentOptions, 'src' | 'html'>

export type ProductListElementOptions = ProductsDiscountProps &
  Pick<
    IComponentOptions,
    'lineItems' | 'paymentStatus' | 'paymentSchedules' | 'currencyCode'
  >

export type SignatureElementOptions = Pick<
  IComponentOptions,
  | 'text'
  | 'required'
  | 'fieldId'
  | 'src'
  | 'recipient'
  | 'signedDate'
  | 'isGhost'
  | 'entityName'
  | 'altText'
> & {
  isSigned?: boolean
  showName?: boolean
}

export type InitialsElementOptions = Pick<
  IComponentOptions,
  | 'text'
  | 'required'
  | 'fieldId'
  | 'src'
  | 'recipient'
  | 'signedDate'
  | 'isGhost'
  | 'entityName'
  | 'altText'
> & {
  isSigned?: boolean
}

export type TextFieldElementOptions = Pick<
  IComponentOptions,
  | 'placeholder'
  | 'text'
  | 'required'
  | 'fieldId'
  | 'src'
  | 'recipient'
  | 'filledDate'
  | 'isGhost'
  | 'isFilled'
  | 'entityName'
> & {
  isFilled?: boolean
  // this is just for validation in document-viewer. Not recommended to use at other areas
  textFieldErrorMessage?: string
}

export type DateFieldElementOptions = Pick<
  IComponentOptions,
  | 'placeholder'
  | 'text'
  | 'required'
  | 'fieldId'
  | 'src'
  | 'recipient'
  | 'filledDate'
  | 'isGhost'
  | 'isFilled'
  | 'entityName'
> & {
  isFilled?: boolean
  // this is just for validation in document-viewer. Not recommended to use at other areas
  textFieldErrorMessage?: string
  availableDates: DATE_FIELD_DATE_VALIDATION
  dateFormat: string
}

export type CheckBoxElementOptions = Pick<
  IComponentOptions,
  | 'text'
  | 'required'
  | 'fieldId'
  | 'src'
  | 'recipient'
  | 'filledDate'
  | 'isGhost'
  | 'isFilled'
  | 'preChecked'
> & {
  isFilled?: boolean
  entityName?: RecipientEntityTypeEnum
  textFieldErrorMessage?: string
}

export type GroupFieldsOptions = Pick<
  IComponentOptions,
  | 'text'
  | 'required'
  | 'fieldId'
  | 'recipient'
  | 'filledDate'
  | 'isGhost'
  | 'isFilled'
  | 'entityName'
> & {
  groupType: string
  rule: string
  ruleValue: string
}

export interface ISelectedGroupElement {
  pageId: string
  pageIndex?: string
  elementId: string
}

export type TableElementOptions = Pick<IComponentOptions, 'text'>

export type EmptyElementOptions = Record<string, any>
export type PageBreakElementOptions = EmptyElementOptions
export type PaymentElementOptions = EmptyElementOptions
export type PageElementOptions = EmptyElementOptions &
  Pick<IComponentOptions, 'src'> & {
    fromPDF?: boolean
    pageDimensions: PageConfig
  }
export type BuilderComponentOptionsTypes =
  | TextElementOptions
  | ImgElementOptions
  | VideoElementOptions
  | ProductListElementOptions
  | SignatureElementOptions
  | InitialsElementOptions
  | TextFieldElementOptions
  | DateFieldElementOptions
  | TableElementOptions
  | EmptyElementOptions
  | PageBreakElementOptions

export interface IPage<T = BuilderComponentOptionsTypes> {
  type: string
  version: number
  id: string
  children: Array<IElement<T>>
  responsiveStyles: IResponsiveStyles
  component: IComponent<PageElementOptions>
  meta?: IDynamic
}

export type Direction = 'top' | 'bottom' | 'left' | 'right'
