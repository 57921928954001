<script setup lang="ts">
import { ChevronRightIcon } from '@gohighlevel/ghl-icons/24/outline'
import { NEllipsis } from 'naive-ui'
import { PropType } from 'vue'
import { UITooltip } from '@gohighlevel/ghl-ui'
import { DropdownTreeOption } from '../types'

defineProps({
  options: {
    type: Array as PropType<DropdownTreeOption[]>,
    default: () => [],
  },
  showPath: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['onForwardTraverse', 'onSelect'])

const handleClick = (option: DropdownTreeOption) => {
  if (!option.disabled && option.children?.length) {
    emit('onForwardTraverse', option)
  } else {
    emit('onSelect', option.value, option)
  }
}

// Current parent
const getCurrentPath = (path: string) => {
  if (!path) return null

  return path?.split('/').filter(Boolean).at(-1)
}
</script>

<template>
  <a
    v-for="option in options"
    v-bind:key="option.label.toString()"
    class="dropdown-tree__item"
    :class="[option?.className, option.disabled ? 'disabled' : '']"
    @click="() => handleClick(option)"
  >
    <span class="flex gap-1">
      <!-- Path -->
      <UITooltip
        :disabled="!showPath && option._pathFromRoot"
        v-if="showPath && option._pathFromRoot !== '/'"
      >
        <template #trigger>
          <span class="dropdown-tree__item--path">
            <NEllipsis class="dropdown-tree__item--path-label" :tooltip="false">
              {{ getCurrentPath(option._pathFromRoot) }}
            </NEllipsis>
            {{ '/' }}
          </span>
        </template>
        {{ option?._pathFromRoot }}
      </UITooltip>

      <!-- Label -->
      <span class="dropdown-tree__item--label">
        <component :is="option.icon" :class="option.iconClass" />
        <NEllipsis :class="showPath ? 'showPath' : ''" :tooltip="false">
          {{ option.label }}
        </NEllipsis>
      </span>
    </span>

    <!-- Arrow -->
    <span v-if="option.children?.length" class="dropdown-tree__item--icon">
      <component
        :is="option?.customArrow"
        :class="option?.arrowClass"
        v-if="option.customArrow"
      />
      <ChevronRightIcon class="chevron" v-else />
    </span>
  </a>
</template>

<style scoped lang="scss">
@mixin align-center {
  display: flex;
  align-items: center;
}
.dropdown-tree {
  &__item {
    @include align-center;
    user-select: none;
    justify-content: space-between;

    background-color: transparent;
    padding: 8px;
    text-decoration: inherit;
    color: var(--gray-700);
    font-weight: 400;
    max-width: 300px;
    overflow: hidden;
    margin: 1px auto;
    border-radius: 4px;

    &:hover {
      background-color: var(--gray-100);
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &--label {
      @include align-center;
      gap: 4px;
      cursor: pointer;
      max-width: 250px;

      .showPath {
        max-width: 180px;
      }
    }

    &--icon {
      line-height: 10px;
      cursor: pointer;

      .chevron {
        width: 20px;
        height: 20px;
      }
    }

    &--path {
      display: flex;
      color: var(--gray-500);

      &-label {
        max-width: 100px;
      }
    }

    span {
      color: var(--gray-700);
    }
  }
}
</style>
