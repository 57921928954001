import { ref } from 'vue'
const movableRefMap = ref<Record<string, any>>({})
const selectMovableRef = ref()
const tooltipRef = ref<Record<string, any>>({})

export const useMovable = () => {
  const setMovableRef = (id: string, ref: any) => {
    movableRefMap.value[id] = ref
  }

  const setSelectionMovableRef = (ref: any) => {
    selectMovableRef.value = ref
  }

  const setTooltipRef = (id: string, ref: any) => {
    tooltipRef.value[id] = ref
  }

  return {
    movableRefMap,
    setMovableRef,
    setSelectionMovableRef,
    selectMovableRef,
    setTooltipRef,
    tooltipRef,
  }
}
