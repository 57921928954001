<script setup lang="ts">
import { useDocument } from '@/composable/useDocument'
import { UISpin, UITextXsMedium } from '@gohighlevel/ghl-ui'
import { computed, onMounted, provide, ref } from 'vue'
import { useDocumentStore } from '@/store/document'
import { DocumentStatus } from '@gohighlevel/ghl-proposals-common'
import { router } from '@/router'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const route = useRoute()
const pageLoading = ref(false)
const store = useDocumentStore()
const hasCertificate = computed(
  () => !!store.certificate?._id && !store.certificate?.deleted
)
const props = defineProps({
  childLoading: { type: Boolean, default: false },
  isPreview: { type: Boolean },
  isPDFView: { type: Boolean },
  loaderText: {
    type: String,
  },
  skipDocumentFetch: { type: Boolean, default: false },
})
const {
  loading,
  getDocumentData,
  redirectingToInvoice,
  secondsTillRedirectToPayment,
} = useDocument()

provide('isPreview', props.isPreview)
provide('isPDFView', props.isPDFView)

onMounted(async () => {
  if (!props.skipDocumentFetch) {
    pageLoading.value = true
    await getDocumentData(props.isPreview, props.isPDFView)
    pageLoading.value = false
    if (
      store?.status === DocumentStatus.DRAFT &&
      !props.isPreview &&
      !props.isPDFView
    ) {
      router.push({
        name: 'document-error',
        params: { type: 'draft_state' },
        query: {
          documentId: route.params.documentId,
          recipientId: route.params.recipientId,
        },
      })
    }
  }
})
const isLoading = computed(
  () => pageLoading.value || redirectingToInvoice.value || props.childLoading
)
const loadingText = computed(() => {
  if (props.loaderText) {
    return props.loaderText
  }
  if (redirectingToInvoice.value) {
    return `${t('documentViewer.redirectingToPayment')} (${
      secondsTillRedirectToPayment.value
    })sec...`
  }
  return `${t('documentViewer.loadingDocument')}...`
})
</script>

<template>
  <div
    class="h-screen w-screen bg-white z-[99999] flex justify-items-center justify-center fixed flex-col"
    v-if="isLoading"
  >
    <UISpin />
    <UITextXsMedium
      class="inline-flex items-center justify-center mt-4 text-gray-500"
      >{{ loadingText }}</UITextXsMedium
    >
  </div>
  <slot
    :loading="loading"
    :pageLoading="isLoading"
    :hasCertificate="hasCertificate"
  ></slot>
</template>
