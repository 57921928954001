<script setup lang="ts">
import { UISpace } from '@gohighlevel/ghl-ui'
import { computed, inject } from 'vue'
import { CertificateGroupBody } from '@/components/common'
import { useDocumentStore } from '@/store/document'
const isPDFView = inject<boolean>('isPDFView')
const certificateBgUrl = new URL(`@/assets/cert_bg.png`, import.meta.url).href
const certificateBodyBgUrl = new URL(
  `@/assets/cert_body_bg.png`,
  import.meta.url
).href

const store = useDocumentStore()
const participantsGroup = computed(() => {
  const pageElemCount = 3
  const pages = []
  const participantHasSignature =
    store.certificate?.participants?.filter(
      participant => participant.signature && participant.signature.length > 0
    ) || []

  for (let i = 0; i < participantHasSignature.length; i += pageElemCount) {
    const elemGroup = participantHasSignature.slice(i, i + pageElemCount)
    pages.push(elemGroup)
  }
  return pages
})

const pageStyle = computed(
  () => `border-image: url('${certificateBgUrl.toString()}');`
)
const certBodyStyle = computed(
  () => `background-image: url('${certificateBodyBgUrl}')`
)
</script>

<template>
  <UISpace
    justify="center"
    :align="`center`"
    :vertical="true"
    :class="isPDFView ? '!gap-0' : '!gap-8'"
    :wrap="false"
  >
    <div
      v-for="(signGroup, index) in participantsGroup"
      v-bind:key="`participant-group-${index}`"
    >
      <div
        class="elements_page certificate-page"
        style="min-height: 1056px; width: 816px"
        :style="pageStyle"
      >
        <div class="certificate-page-view">
          <div :style="certBodyStyle" class="cert-body flex flex-1 flex-col">
            <div class="cert-page-content">
              <CertificateGroupBody :participants="signGroup" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </UISpace>
</template>

<style lang="scss">
.elements_page {
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  background: #ffffff;
}
.certificate-page {
  box-sizing: border-box;
  border-width: 20px;
  border-image-slice: 20 !important;
  display: flex;
  border-image-repeat: repeat !important;
  .certificate-page-view {
    display: flex;
    flex: 1;
    flex-direction: column;
    border: 5px solid #0f758c;
    .cert-body {
      border: 2px solid #2498b2;
      margin: 10px;
    }
  }
}
</style>
