const cache: { [key: string]: any } = {}

export const memoize = (fn: (...args: any[]) => any) => {
  return function (...args: any[]) {
    const key = JSON.stringify(args)
    if (cache[key]) {
      return cache[key]
    } else {
      const result = fn(...args)
      cache[key] = result
      return result
    }
  }
}

export function addPseudoElementStyle(
  selector: string,
  pseudoElement: 'before' | 'after',
  styles: string
) {
  const styleElement = document.createElement('style')
  styleElement.appendChild(document.createTextNode('')) // WebKit hack :(
  document.head.appendChild(styleElement)

  const stylesheet = styleElement.sheet
  if (stylesheet) {
    if (pseudoElement === 'before') {
      stylesheet.insertRule(`${selector}::before {${styles}}`, 0)
    }
    if (pseudoElement === 'after') {
      stylesheet.insertRule(`${selector}::after {${styles}}`, 0)
    }
  }
}

export function loadingAnimation() {
  const animationStyles = `
      @keyframes loading {
        0% {
            background-position: 1rem 0;
        }
        100% {
            background-position: 0 0;
        }
      }
    `

  const styleElement = document.createElement('style')
  styleElement.appendChild(document.createTextNode(animationStyles)) // WebKit hack :(
  document.head.appendChild(styleElement)
}
