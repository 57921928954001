<script setup lang="ts">
import {
  IElement,
  SignatureElementOptions,
  TextFieldElementOptions,
  DateFieldElementOptions,
  InitialsElementOptions,
  CheckBoxElementOptions,
  ELEMENTS_LOOKUP,
} from '@gohighlevel/ghl-proposals-common'

import CTextField from '../elements/CTextField.vue'
import CDateField from '../elements/CDateField.vue'
import CInitialsField from '../elements/CInitialsField.vue'
import CCheckboxField from '../elements/CCheckboxField.vue'
import CSignature from '../elements/CSignature.vue'
import { PropType, inject } from 'vue'

const props = defineProps({
  elements: {
    required: true,
    type: Array as () => IElement<
      | SignatureElementOptions
      | TextFieldElementOptions
      | DateFieldElementOptions
      | InitialsElementOptions
      | CheckBoxElementOptions
    >[],
  },
  pageId: {
    required: true,
    type: String,
  },
  page: {
    required: true,
    type: Object as () => IElement,
  },
  pageRef: {
    required: false,
    type: Object as () => HTMLElement,
  },
  parentRef: {
    required: false,
    type: Object as () => HTMLElement,
  },
  crossings: {
    required: true,
    type: Array as PropType<
      {
        boundary: number
        delta: number
        index: number
        crossingPosition: number
      }[]
    >,
  },
  type: {
    required: true,
    type: String,
  },
})
const isPDFView = inject('isPDFView') as boolean

const getShift = (el: IElement) => {
  if (!isPDFView) return 'translate(0px, 0px)'
  if (props.type === ELEMENTS_LOOKUP.IMAGE) {
    return 'translate(0px, 0px)'
  }
  let shift = 0
  const preferBottom = el.responsiveStyles.large.position?.preferBottom
  for (let i = 0; i < props?.crossings?.length; i++) {
    const crossing = props.crossings[i]
    if (
      el.responsiveStyles.large.position?.top &&
      el.responsiveStyles.large.position?.top > crossing.crossingPosition
    ) {
      if (preferBottom) {
        shift += 48 * 2 * (i + 1)
      } else {
        shift += 48 * 2 * (i + 1)
      }
    }
  }
  return !preferBottom
    ? `translate(0px, ${shift}px)`
    : `translateY(-${shift}px)`
}
</script>

<template>
  <template v-for="(element, index) in elements" v-bind:key="element.id">
    <CSignature
      v-if="element.type === ELEMENTS_LOOKUP.SIGNATURE"
      :pageRef="pageRef"
      :parentRef="parentRef"
      :page="page"
      :element="(element as unknown as IElement<SignatureElementOptions>)"
      :pageId="(pageId as string)"
      :shift="(getShift(element) as string)"
    />
    <CTextField
      :pageRef="pageRef"
      :parentRef="parentRef"
      :page="page"
      :tab-index="index"
      :element="(element as unknown as IElement<TextFieldElementOptions>)"
      :pageId="pageId"
      :shift="(getShift(element) as string)"
      v-if="element.type === ELEMENTS_LOOKUP.TEXT_FIELD"
    />
    <CDateField
      :pageRef="pageRef"
      :parentRef="parentRef"
      :page="page"
      :tab-index="index"
      :element="(element as unknown as IElement<DateFieldElementOptions>)"
      :pageId="pageId"
      v-if="element.type === ELEMENTS_LOOKUP.DATE_FIELD"
    />
    <CInitialsField
      :pageRef="pageRef"
      :parentRef="parentRef"
      :page="page"
      :tab-index="index"
      :element="(element as unknown as IElement<InitialsElementOptions>)"
      :pageId="pageId"
      v-if="element.type === ELEMENTS_LOOKUP.INITIALS_FIELD"
    />
    <CCheckboxField
      :pageRef="pageRef"
      :parentRef="parentRef"
      :page="page"
      :tab-index="index"
      :element="(element as unknown as IElement<CheckBoxElementOptions>)"
      :pageId="pageId"
      v-if="element.type === ELEMENTS_LOOKUP.CHECKBOX"
    />
  </template>
</template>
