import {
  Certificate,
  DocumentStatus,
  DownloadPdfParams,
  IDocument,
  LinkReference,
  UpdateSignatureParams,
} from '@gohighlevel/ghl-proposals-common'

import config from '@/config'
import { requests } from '.'

interface GetDocument {
  id?: string
  isPreview?: boolean
  recipientId?: string
  referenceId?: string
}
export type IPublicDocumentResponse = Pick<
  IDocument,
  | 'pages'
  | 'pricingTables'
  | 'fillableFields'
  | 'status'
  | 'timezone'
  | 'enableSigningOrder'
  | 'locationId'
  | 'name'
  | 'recipients'
  | 'sentBy'
  | 'type'
  | 'updatedBy'
> & {
  businessName: string
}
export const DocumentServices = {
  getDocument: (params: GetDocument) =>
    requests(config.PROPOSAL_SERVICE_URL).get<{
      document: IPublicDocumentResponse
      recipientLinkReference: LinkReference
    }>(`/document/public`, { params }),

  updateSignature: (params: UpdateSignatureParams) =>
    requests(config.PROPOSAL_SERVICE_URL).put('/document/sign', params),

  acceptDocument: (params: UpdateSignatureParams) =>
    requests(config.PROPOSAL_SERVICE_URL).post('/document/accept', params),

  downloadPdf: (params: DownloadPdfParams) =>
    requests(config.PROPOSAL_SERVICE_URL).get('/document/public/download', {
      params,
    }),
  getCertificateDetails: (params: Pick<DownloadPdfParams, 'documentId'>) =>
    requests(config.PROPOSAL_SERVICE_URL).get<
      Record<'certificate', Certificate>
    >('/certificate', {
      params,
    }),
  checkDocumentStatus: (params: { referenceId: string; invoiceId?: string }) =>
    requests(config.PROPOSAL_SERVICE_URL).get<{
      invoicePaymentStatus: string
      documentStatus: DocumentStatus
      invoiceId?: string
      documentId: string
      locationId: string
      invoiceName?: string
      invoiceUrl?: string
    }>('/document/public/validate-document', { params }),
  downloadInvoice: (params: {
    altId: string
    altType: string
    invoiceId: string
  }) => {
    return requests(config.INVOICE_SERVICE_URL).post<{ url: string }>(
      '/download',
      params
    )
  },
}
