import { DocumentServices } from '@/service/DocumentService'
import { useDocumentStore } from '@/store/document'
import { ref } from 'vue'
import { useRoute } from 'vue-router'

export const useCertificate = () => {
  const route = useRoute()
  const loading = ref(false)
  const store = useDocumentStore()

  const getCertificateDetails = async () => {
    loading.value = true
    const { data } = await DocumentServices.getCertificateDetails({
      documentId: route?.params?.documentId as string,
    })
    if (data && data.certificate && !data.certificate.deleted) {
      store.setCertificateDetails(data.certificate)
      window.document.title = `Document Ref: ${data.certificate.referenceNumber.toUpperCase()}`
    }
    loading.value = false
  }
  return {
    getCertificateDetails,
    loading,
  }
}
