import { DATE_FIELD_DATE_VALIDATION } from '../types'
import { ELEMENTS_LOOKUP } from './elements'

export const fillableFields = new Set([
  ELEMENTS_LOOKUP.TEXT_FIELD,
  ELEMENTS_LOOKUP.DATE_FIELD,
])

export const selectableFields = new Set([ELEMENTS_LOOKUP.CHECKBOX])

export const assignableFields = new Set([
  ELEMENTS_LOOKUP.TEXT_FIELD,
  ELEMENTS_LOOKUP.DATE_FIELD,
  ELEMENTS_LOOKUP.SIGNATURE,
  ELEMENTS_LOOKUP.INITIALS_FIELD,
  ELEMENTS_LOOKUP.CHECKBOX,
])

export const dateFieldDateValidationOptions = [
  {
    label: 'Any Date',
    value: DATE_FIELD_DATE_VALIDATION.ANY,
  },
  {
    label: 'Past Date',
    value: DATE_FIELD_DATE_VALIDATION.PAST_DATE,
  },
  {
    label: 'Future Date',
    value: DATE_FIELD_DATE_VALIDATION.FUTURE_DATE,
  },
  {
    label: "Today's Date",
    value: DATE_FIELD_DATE_VALIDATION.TODAYS_DATE,
  },
]
export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd'
export const dateFormatOptions = [
  { label: 'YYYY-MM-DD', value: 'yyyy-MM-dd' },
  { label: 'MM-DD-YYYY', value: 'MM-dd-yyyy' },
  { label: 'DD-MM-YYYY', value: 'dd-MM-yyyy' },
  { label: 'YYYY / MM / DD', value: 'yyyy / MM / dd' },
  { label: 'MM / DD / YYYY', value: 'MM / dd / yyyy' },
  { label: 'DD / MM / YYYY', value: 'dd / MM / yyyy' },
  { label: 'YYYY.MM.DD', value: 'yyyy.MM.dd' },
  { label: 'MM.DD.YYYY', value: 'MM.dd.yyyy' },
  { label: 'DD.MM.YYYY', value: 'dd.MM.yyyy' },
  { label: 'MMM DD, YYYY', value: 'MMM dd, yyyy' },
  { label: 'DD MMM YYYY', value: 'dd MMM yyyy' },
  { label: 'D MMMM YYYY', value: 'd MMMM yyyy' },
  { label: 'MMMM D, YYYY', value: 'MMMM d, yyyy' },
]
