<script setup lang="ts">
import { UIButton, UIDropdown, renderIcon } from '@gohighlevel/ghl-ui'
import {
  DotsVerticalIcon,
  FileDownload02Icon,
} from '@gohighlevel/ghl-icons/24/outline'
import { computed } from 'vue'
import { useDocument } from '@/composable'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { loading, downloadProposalPdf } = useDocument()
const optionsList = computed(() => {
  const options = [
    {
      label: t('documentViewer.downloadPdf'),
      key: 'download',
      icon: renderIcon(FileDownload02Icon),
    },
  ]
  return options
})

const onSelect = async (value: string) => {
  if (value === 'download') {
    await downloadProposalPdf()
  }
}
</script>

<template>
  <UIDropdown
    id="document-state-actions"
    :options="optionsList"
    @select="onSelect"
    class="pointer-events-none opacity-50"
  >
    <UIButton
      class="hover:text-primary-600 text-white"
      :loading="loading"
      id="document-state-actions-btn"
      :circle="true"
      :quaternary="true"
      ><DotsVerticalIcon class="w-5 h-5 text-white"
    /></UIButton>
  </UIDropdown>
</template>
