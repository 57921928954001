<script setup lang="ts">
import { useDocumentStore } from '@/store/document'
import { computed, inject } from 'vue'
import { UISpace } from '@gohighlevel/ghl-ui'
import PDFPage from '@/components/page/PDFPage.vue'
import {
  DEFAULT_PAGE_DIMENSION,
  IElement,
  OLD_PAGE_HEIGHT,
  OLD_PAGE_MARGIN,
  OLD_PAGE_WIDTH,
  PageElementOptions,
} from '@gohighlevel/ghl-proposals-common'
import { onMounted } from 'vue'
import { watch } from 'vue'

const props = defineProps({
  pagination: { type: Object },
  isPreview: { type: Boolean, default: false },
})

const store = useDocumentStore()
const isPDFView = inject<boolean>('isPDFView')
const pageData = computed(() => {
  if (props.pagination) {
    if (props.pagination?.from && props.pagination?.to) {
      const paginatedData = store?.document?.pages?.slice(
        Number(props.pagination?.from),
        Number(props.pagination?.to) + 1
      )
      return paginatedData
    }
  }
  return store.document.pages
})
const pageMargins = computed(() => {
  if (pageData.value && pageData.value.length > 0) {
    const allPagesVersionGreaterThanOne = pageData.value?.every(
      page => page.version > 1
    )
    if (allPagesVersionGreaterThanOne) {
      const { margins } =
        pageData?.value[0]?.component?.options?.pageDimensions ||
        DEFAULT_PAGE_DIMENSION

      return {
        top: margins.top + 'px',
        right: margins.right + 'px',
        bottom: margins.bottom + 'px',
        left: margins.left + 'px',
      }
    }
  }
  return {
    top: 48 + 'px',
    right: 48 + 'px',
    bottom: 48 + 'px',
    left: 48 + 'px',
  }
})

const printSize = computed(() => {
  if (pageData.value && pageData.value.length > 0) {
    const allPagesVersionGreaterThanOne = pageData.value?.every(
      page => page.version > 1
    )
    if (allPagesVersionGreaterThanOne) {
      const { dimensions } =
        pageData?.value[0]?.component?.options?.pageDimensions ||
        DEFAULT_PAGE_DIMENSION
      return `${dimensions.width}px ${dimensions.height}px`
    }
  }
  return `${OLD_PAGE_WIDTH}px ${OLD_PAGE_HEIGHT}px`
})

const bottomMarginPosition = computed(() => {
  if (pageData.value && pageData.value.length > 0) {
    const allPagesVersionGreaterThanOne = pageData.value?.every(
      page => page.version > 1
    )
    if (allPagesVersionGreaterThanOne) {
      const { margins, dimensions } =
        pageData?.value[0]?.component?.options?.pageDimensions ||
        DEFAULT_PAGE_DIMENSION
      return dimensions.height - margins.bottom + 'px'
    }
  }
  return `calc(${OLD_PAGE_HEIGHT}px - ${OLD_PAGE_MARGIN}px)`
})

const addPrintStyles = () => {
  const el = document.getElementById('dynamic-print-styles')
  if (el) {
    document.head.removeChild(el)
  }
  const style = document.createElement('style')
  style.setAttribute('id', 'dynamic-print-styles')
  style.innerHTML = `
        @page {
          size: ${printSize.value};
          margin-top: 0px;
          margin-bottom: 0px;
        }
      `
  document.head.appendChild(style)
}
onMounted(() => {
  addPrintStyles()
})

watch(printSize, () => {
  addPrintStyles()
})
</script>

<template>
  <UISpace
    justify="center"
    :align="`center`"
    :vertical="true"
    :class="isPDFView ? '!gap-0' : '!gap-8'"
    :wrap="false"
  >
    <div>
      <div class="page-header" style="text-align: center"></div>
      <div class="page-footer" style="text-align: center"></div>
      <div
        v-for="(page, index) in pageData"
        :key="index"
        :class="`elements_page ${isPDFView ? '!shadow-none !m-0' : ''}`"
      >
        <PDFPage :page="(page as IElement<PageElementOptions>)" />
        <div
          v-if="pageData.length - 1 != index"
          style="page-break-before: always"
        ></div>
      </div>
    </div>
  </UISpace>
</template>

<style lang="scss">
.elements_page {
  background: #ffffff;
  position: relative;
  transform: translateZ(0px);
  will-change: transform;
}

.page-content {
  margin-left: v-bind('pageMargins.left');
  margin-right: v-bind('pageMargins.right');
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.draggable-container {
  flex: 1 0 auto;
}

/**
 custom css
*/

.page-header,
.page-header-space {
  height: v-bind('pageMargins.top');
}

.page-footer,
.page-footer-space {
  height: v-bind('pageMargins.bottom');
}

.page-footer {
  position: fixed;
  bottom: 0;
  width: 816px;
  z-index: 1;
  top: v-bind('bottomMarginPosition');
}

.page-header {
  position: fixed;
  top: 0mm;
  width: 816px;
  z-index: 1;
}
</style>
