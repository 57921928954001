import { onMounted, ref } from 'vue'

const isMobile = ref(false)
const scaleFactor = ref(1)
export const useMobileView = () => {
  onMounted(() => {
    // Get the window dimensions
    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight

    if (windowWidth <= 816) {
      // Set the fixed width of the div
      const divWidth = 816
      isMobile.value = true
      // Calculate the scale value
      const scaleValue = Math.min(
        windowWidth / divWidth,
        windowHeight / divWidth
      )
      scaleFactor.value = scaleValue
    } else {
      isMobile.value = false
      scaleFactor.value = 1
    }
  })

  return {
    isMobile,
    scaleFactor,
  }
}
