<script setup lang="ts">
import { inject } from 'vue'

const isPDFView = inject<boolean>('isPDFView')
</script>

<template>
  <div
    id="layout-box"
    :class="`flex flex-col items-center h-screen overflow-auto ${
      isPDFView ? 'bg-white' : 'bg-gray-100'
    }`"
  >
    <slot></slot>
  </div>
</template>
