export const DOC_VAR_PREFIX = `document`

export enum ObjectForLinkedField {
  DOCUMENT_VARIABLE = 'documentVariable',
  CONTACT_CUSTOM_VALUES = 'contactCustomValue',
}

export const SPECIAL_CHAR_REGEX_DOC_VAR =
  /[ `!@#$%^&*()+\-=[\]{};':"\\|,.<>/?~]/

export enum CustomVariableEvents {
  CUSTOM_VARIABLE_ADDED = 'custom-variable-added',
}

export const DOCUMENT_LINKED_VARIABLE = 'linked'
