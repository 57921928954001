import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import weekday from 'dayjs/plugin/weekday'
import i18n from '../locales'
import { DATE_FIELD_DATE_VALIDATION } from '../types'
import { isSafari } from './isSafari'

dayjs.extend(localizedFormat)
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(weekday)
dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(relativeTime)

type DateInput = string | number | Date
export const formatDate = (date: DateInput, format = 'LLL') => {
  return dayjs(date).format(format)
}

export const isDateBefore = (date: DateInput) => {
  return dayjs().isBefore(date, 'day')
}

export const isValidDate = (date: DateInput) => {
  return dayjs(date).isValid()
}

export const convertToFormatForValidDate = (value: any) => {
  if (isValidDate(value)) return formatDate(value, 'LL')
  return value
}

export const validateDate = (
  availableDates = DATE_FIELD_DATE_VALIDATION.ANY as DATE_FIELD_DATE_VALIDATION,
  ts: number
) => {
  const now = dayjs()
  const date = dayjs(ts)
  if (availableDates === DATE_FIELD_DATE_VALIDATION.FUTURE_DATE) {
    return now.unix() > date.unix()
  }
  if (availableDates === DATE_FIELD_DATE_VALIDATION.PAST_DATE) {
    return now.unix() < date.unix()
  }
  if (availableDates === DATE_FIELD_DATE_VALIDATION.TODAYS_DATE) {
    return !now.isSame(date, 'day')
  }
  return false
}

/**
 *
 * @param date
 * @returns
 * FYI: https://medium.com/@papapapaya11/how-to-resolve-invalid-date-issue-in-chrome-or-safari-eebf7475262d#:~:text=During%20the%20implementation%20of%20a,Invalid%20date%20iso%208601%E2%80%9D%20specification.
 */
export const convertToUTC = (date: number | string) => {
  if (!date) {
    return null
  }
  const dateToSave = new Date(date)
  if (isSafari()) {
    return formatDate(dateToSave, 'DD-MMMM-YYYY')
  } else {
    return formatDate(dateToSave, 'DD/MMMM/YYYY')
  }
}

export const convertUtcToUNIX = (date: string) => {
  if (!date) return null
  if (isSafari() && date.includes('/')) {
    return new Date(date.replace(/\//g, '-'))
  }
  return new Date(date)
}

export const convertCustomValueToFormatForValidDate = (value: any) => {
  const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/
  if (value && regex.test(value)) return formatDate(value, 'LL')
  return value
}

export const dayjsTZ = (
  date?: Date | string | number,
  format?: string,
  timezone?: string
) => {
  if (timezone) {
    if (date && format) {
      return dayjs.tz(dayjs(date).format(format), timezone)
    }
    return dayjs.tz(date, timezone)
  }
  return dayjs(date, format)
}

export const formatDateWithTZ = (
  date: string,
  format = 'MM/DD/YYYY',
  timezone?: string
) => {
  if (!date) return ''
  if (timezone) {
    return dayjs.tz(date, timezone).format(format)
  }
  return dayjs(date).format(format)
}

export const getCurrentDate = (format = 'YYYY-MM-DD', timezone?: string) => {
  if (timezone) {
    return dayjs().tz(timezone).format(format)
  }
  return dayjs().format(format)
}

export const getFormatDate = (
  date: string,
  format = 'YYYY-MM-DD',
  timezone?: string
) => {
  if (timezone) {
    return dayjs(date).tz(timezone).format(format)
  }
  return dayjs(date).format(format)
}

export const parseDateTimeInUTC = (dt: any) => {
  const timezoneOffset = dayjs().utcOffset()
  return dayjs.utc(dt).subtract(timezoneOffset, 'minutes')
}

export const getYearMonthDate = (date: Date | string) => {
  const parsedDate = dayjs(date, 'YYYY-MM-DD')
  return [parsedDate.year(), parsedDate.month(), parsedDate.date()]
}

export const getHourMinSec = (time: Date | string) => {
  const parsedTime = dayjs(time, 'HH:mm:ss')
  return [parsedTime.hour(), parsedTime.minute(), parsedTime.second()]
}

export const formatDayAbbreviation = (dayOfWeek: number) => {
  const formattedDay = dayjs().weekday(dayOfWeek).format('dd').toUpperCase()
  return formattedDay
}

export const formatMonthNumber = (monthOfYear: number) => {
  const formattedMonth = dayjs().month(monthOfYear).format('M')
  return formattedMonth
}

export const formatDateSubtractDays = (
  firstOccurrence: Date | string,
  daysBefore: number,
  format = 'MMMM D, YYYY',
  timezone?: string
) => {
  if (timezone) {
    return dayjs(firstOccurrence)
      .subtract(Number(daysBefore), 'days')
      .tz(timezone)
      .format(format)
  }
  return dayjs(firstOccurrence)
    .utc(true)
    .subtract(Number(daysBefore), 'days')
    .format(format)
}

export const formatLastViewedTime = (date: string, timezoneString = 'UTC') => {
  const now = dayjs().tz(timezoneString)
  const viewedTime = dayjs(date).tz(timezoneString)
  const yearsDifference = now.diff(viewedTime, 'year')
  const monthsDifference = now.diff(viewedTime, 'month')
  const weeksDifference = now.diff(viewedTime, 'week')
  const daysDifference = now.diff(viewedTime, 'day')
  const hoursDifference = now.diff(viewedTime, 'hour')
  const minutesDifference = now.diff(viewedTime, 'minute')
  if (yearsDifference >= 1) {
    return `${yearsDifference} ${i18n.global.t(
      yearsDifference > 1
        ? 'schedulingOptions.timeAgo.years'
        : 'schedulingOptions.timeAgo.year'
    )}`
  } else if (monthsDifference >= 1) {
    return `${monthsDifference} ${i18n.global.t(
      monthsDifference > 1
        ? 'schedulingOptions.timeAgo.months'
        : 'schedulingOptions.timeAgo.month'
    )}`
  } else if (weeksDifference >= 1) {
    return `${weeksDifference} ${i18n.global.t(
      weeksDifference > 1
        ? 'schedulingOptions.timeAgo.weeks'
        : 'schedulingOptions.timeAgo.week'
    )}`
  } else if (daysDifference >= 1) {
    return `${daysDifference} ${i18n.global.t(
      daysDifference > 1
        ? 'schedulingOptions.timeAgo.days'
        : 'schedulingOptions.timeAgo.day'
    )}`
  } else if (hoursDifference >= 1) {
    return `${hoursDifference} ${i18n.global.t(
      hoursDifference > 1
        ? 'schedulingOptions.timeAgo.hours'
        : 'schedulingOptions.timeAgo.hour'
    )}`
  } else {
    return `${minutesDifference} ${i18n.global.t(
      minutesDifference > 1
        ? 'schedulingOptions.timeAgo.minutes'
        : 'schedulingOptions.timeAgo.minute'
    )}`
  }
}
