import axios from 'axios'
import config from '../config'

export const getHeader = () => {
  return {
    channel: 'APP',
    source: 'WEB_USER',
    version: '2021-07-28',
  }
}

export const requests = (host?: string) => {
  const baseUrl = host || config.restApiUrl
  const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: getHeader(),
  })

  return axiosInstance
}
