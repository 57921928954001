<script setup lang="ts">
import { UIButton } from '@gohighlevel/ghl-ui'
import {
  useFillableFields,
  useGroups,
  useMobileView,
  useSignature,
} from '@/composable'
import { useDocumentStore } from '@/store/document'
import {
  ELEMENTS_LOOKUP,
  getRules,
  GROUP_LOOKUP,
} from '@gohighlevel/ghl-proposals-common'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const { calculateGroupPosition } = useGroups()
const { t } = useI18n()
const { signedCount, signElementCount } = useSignature()
defineProps({
  isMobileView: {
    type: Boolean,
    default: false,
  },
})
const { activeElement, next, pageHeights, elementsRef } = useFillableFields()
const store = useDocumentStore()
const pageElementMap = store.unFilledElements.elementsPageMap
const { isMobile } = useMobileView()
const getHeightFromTop = (limit = 0) => {
  if (limit > 0) {
    let h = 0
    for (let i = 0; i < limit; i++) {
      h += pageHeights.value[i]
    }
    return h
  }
  return 0
}

const elementRef = computed(() => {
  if (
    elementsRef.value &&
    elementsRef.value[activeElement.value?.id as string]
  ) {
    return elementsRef.value[
      activeElement.value?.id as string
    ] as HTMLElement | null
  }
  return document.getElementById(
    activeElement.value?.id as string
  ) as HTMLElement | null
})

const calculateChildPosition = (
  selector: string
): { top: number; left: number } => {
  let parent = null
  const parentElements = document.querySelectorAll(`${selector}`)
  const childElement = elementRef.value
  parentElements.forEach(el => {
    if (el.contains(childElement)) {
      parent = el
    }
  })
  if (parent && childElement) {
    const parentRect = parent?.getBoundingClientRect()
    const childRect = childElement.getBoundingClientRect()

    const top = childRect.top - parentRect.top
    const left = childRect.left - parentRect.left

    return { top, left }
  }

  return { top: 0, left: 0 }
}
const isFilled = computed(
  () =>
    !store.validateElementUnFilled(
      store.getElementById(activeElement.value?.id as string)
    )
)

const text = computed(() => {
  if (isFilled.value && activeElement.value?.type !== GROUP_LOOKUP.GROUP_FIELDS)
    return t('documentViewer.next')
  if (activeElement.value?.type === ELEMENTS_LOOKUP.SIGNATURE)
    return t('documentViewer.sign')
  if (activeElement.value?.type === ELEMENTS_LOOKUP.CHECKBOX)
    return t('documentViewer.select')
  if (activeElement.value?.type === ELEMENTS_LOOKUP.DATE_FIELD)
    return t('documentViewer.select')
  if (activeElement.value?.type === GROUP_LOOKUP.GROUP_FIELDS) {
    const ruleText = getRules(activeElement.value)
    return ruleText || t('documentViewer.select')
  }

  return t('documentViewer.fillIn')
})

const styles = computed(() => {
  const css = { top: 0, right: '100%', whiteSpace: 'nowrap' }
  if (activeElement.value) {
    if (activeElement.value?.type === GROUP_LOOKUP.GROUP_FIELDS) {
      css.top = calculateGroupPosition(activeElement.value).top
    } else {
      css.top = calculateChildPosition('.elements_page').top
    }

    const delta =
      pageElementMap[activeElement.value?.id as string] * 32 +
      getHeightFromTop(pageElementMap[activeElement.value?.id as string])
    const style = {
      ...css,
      top: `${
        (css?.top as number) +
        (activeElement.value?.type === GROUP_LOOKUP.GROUP_FIELDS ? 0 : delta)
      }px`,
    }
    return style
  }
  return css
})
</script>

<template>
  <UIButton
    type="tertiary"
    v-if="isMobileView && store.unFilledElements.elements.length > 0"
    @click="next"
    class="!text-gray-600 font-medium !bg-warning-300 shadow-page cursor-pointer !hover:bg-warning-400 !active:bg-warning-400 !visited:bg-warning-400"
    id="floating-field-nxt-btn"
    >Next</UIButton
  >
  <span
    v-if="
      !isMobile &&
      store.unFilledElements.elements.length > 0 &&
      signedCount !== signElementCount
    "
    @click="next"
    class="test fillable-indicator absolute px-6 py-2 text-gray-600 font-medium bg-warning-300 shadow-page cursor-pointer hover:bg-warning-400 rounded-tl-md rounded-bl-md"
    :class="{ hidden: isMobileView }"
    :style="{ ...styles }"
    ><span>{{ text }}</span></span
  >
</template>

<style lang="scss">
.fillable-indicator {
  animation: point 3s infinite;
  z-index: 1;
  transition: 0.5s ease top;
  &:hover {
    &::before {
      border-left: 15px solid var(--warning-400);
    }
  }
  &::before {
    position: absolute;
    content: ' ';
    border-left: 15px solid var(--warning-300);
    border-top: 19px solid transparent;
    border-bottom: 19px solid transparent;
    right: -15px;
    z-index: 10;
    top: 0px;
  }
}
</style>
