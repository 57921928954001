import { config } from '../config'
import { requests } from '../utils'

export const CustomValuesService = {
  getCustomValueData: (params: { locationId: string }) => {
    return requests(config.CUSTOM_DATA_SERVICE_URL).get(
      `/custom-data/proposals`,
      {
        params: {
          locationId: params.locationId,
          types: 'custom-values,custom-fields',
        },
        headers: {
          'location-id': params.locationId,
          'internal-channel': `proposals`,
        },
      }
    )
  },
}
