export * from './timezone.enum'

export enum DocumentTypeEnum {
  PROPOSAL = 'proposal',
  ESTIMATE = 'estimate',
}

export enum DiscountType {
  PERCENTAGE = 'percentage',
  CUSTOM_AMOUNT = 'custom_amount',
}

export enum PaymentStatus {
  WAITING_FOR_PAYMENT = 'waiting_for_payment',
  PAID = 'paid',
  NO_PAYMENT = 'no_payment',
}

export enum PaymentType {
  CASH = 'cash',
  CHEQUE = 'cheque',
  OTHER = 'other',
}

export enum ProductType {
  DIGITAL = 'DIGITAL',
  PHYSICAL = 'PHYSICAL',
  SERVICE = 'SERVICE',
}

export enum DocumentStatus {
  SENT = 'sent',
  DRAFT = 'draft',
  COMPLETED = 'completed',
  ACCEPTED = 'accepted',
  VIEWED = 'viewed',
  WAITING_FOR_PAYMENT = 'waiting_for_payment',
}

export enum CustomNotificationTypes {
  CUSTOMER = 'customer',
  TEAM = 'team',
  INVOICE = 'invoice',
}

export enum SentMedium {
  LINK = 'link',
  EMAIL = 'email',
}

export enum RRuleFrequency {
  MINUTELY = 'MINUTELY',
  HOURLY = 'HOURLY',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export enum RRuleEndBy {
  NEVER = 'NEVER',
  BY = 'BY',
  AFTER = 'AFTER',
}
