export enum PageSize {
  A4 = 'A4',
  A5 = 'A5',
  A3 = 'A3',
  Letter = 'Letter',
  Legal = 'Legal',
  Tabloid = 'Tabloid',
}

export type Dimensions = {
  width: number
  height: number
}

export type PageSizesConfig = {
  [key in PageSize]: PageConfig
}

export type PageConfig = {
  dimensions: Dimensions
  margins: Margins
  rotation: Rotation
}

export type Margins = {
  top: number
  right: number
  bottom: number
  left: number
}

export enum Rotation {
  Portrait = 'portrait',
  Landscape = 'landscape',
}
