import { computed, inject } from 'vue'

import { useDocumentStore } from '@/store/document'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDocument } from './useDocument'

const fonts = [
  "'Aguafina Script'",
  "'Dancing Script'",
  "'Style Script'",
  "'Vujahday Script'",
]
export interface ISignatureProps {
  text?: string
  padding?: number
  fontFamily?: string
  color?: string
  width: number
  height: number
}

type TranslateFunction = (...args: any[]) => string

const useSignatureFactory = (t: TranslateFunction) => {
  const initialUnsignedLength = ref(0)
  const colors = ref(['#000000', '#155EEF', '#D92D20'])

  const tabs = ref(['Draw', 'Type'])
  const activeTab = ref('Type')

  const selectedFontFamily = ref(fonts[0])
  const selectedColor = ref(colors.value[0])
  const signaturePad = ref(null)
  const errorMessage = ref('')
  const drawedSignature = ref('')
  const signatureText = ref('')

  const isPreview = inject<boolean>('isPreview')

  return () => {
    const { acceptSignature } = useDocument()
    const store = useDocumentStore()
    initialUnsignedLength.value = store.unsignedSignatureElementIds.length

    const onFinishBtnClick = () => {
      acceptSignature()
    }

    const finishBtnText = computed(() => {
      if (store.isPaymentRequired && store.activeRecipient?.isPrimary) {
        return t('documentViewer.finishAndCompletePayment')
      } else {
        return t('documentViewer.finish')
      }
    })

    const getImgFromCanvas = ({
      text,
      fontFamily,
      color,
      width = 192,
      height = 70,
    }: ISignatureProps): Promise<string> => {
      const padding = (20 * height) / 100
      return new Promise((resolve, reject) => {
        try {
          const canvas = document.createElement('canvas') as HTMLCanvasElement
          const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
          const dpr = window.devicePixelRatio || 1
          canvas.width = width * dpr
          canvas.height = height * dpr
          ctx.scale(dpr, dpr)

          const maxWidth = canvas.width / dpr - 2 * padding
          const maxHeight = canvas.height / dpr - 2 * padding
          let fontSize = 100

          ctx.clearRect(0, 0, canvas.width, canvas.height)

          let textWidth
          let textHeight

          // Reduce font size until text fits within the canvas bounds
          do {
            ctx.font = `${fontSize}px ${fontFamily || selectedFontFamily.value}`
            textWidth = ctx.measureText(text || signatureText.value).width
            textHeight = fontSize
            fontSize -= 1
          } while (
            (textWidth > maxWidth || textHeight > maxHeight) &&
            fontSize > 0
          )

          // Set the x position for left alignment with padding
          const x = padding
          // Calculate y position for vertical centering with padding
          const y = padding + maxHeight / 2

          ctx.font = `${fontSize}px ${fontFamily || selectedFontFamily.value}`
          ctx.textAlign = 'left'
          ctx.textBaseline = 'middle'
          ctx.fillStyle = color || selectedColor.value

          ctx.fillText(text || signatureText.value, x, y)
          resolve(canvas.toDataURL())
        } catch (error) {
          reject(error)
        }
      })
    }

    const validateSignerName = (str?: string) => {
      return !(!str || (str && str?.trim().length < 2))
    }

    const resetError = () => {
      errorMessage.value = ''
    }

    const getImg = async (props: ISignatureProps): Promise<string | null> => {
      if (activeTab.value === 'Draw') {
        if (signaturePad.value) {
          // eslint-disable-next-line no-unsafe-optional-chaining
          const { isEmpty, data } = (signaturePad.value as any)?.saveSignature()
          if (isEmpty) {
            errorMessage.value = t('documentViewer.signatureValidationMesage')
          }
          drawedSignature.value = isEmpty ? null : data
          return isEmpty ? null : data
        }
        return drawedSignature.value
      } else {
        if (!validateSignerName(props.text || signatureText.value)) {
          errorMessage.value = t('documentViewer.signatureValidationMesage')
          return null
        }
        return getImgFromCanvas(props)
      }
    }

    const signedCount = computed(() => {
      if (isPreview === true) {
        return store.signedSignatureCount
      } else {
        if (store.activeRecipient?.hasCompleted === true) {
          return store.signatureElementCountForRecipient
        }
        return store.signedSignatureElementCountForRecipient
      }
    })

    const signElementCount = computed(() => {
      return isPreview === true
        ? store.signatureElementCount
        : store.signatureElementCountForRecipient
    })

    return {
      signatureText,
      getImg,
      tabs,
      selectedColor,
      selectedFontFamily,
      activeTab,
      fonts,
      colors,
      signaturePad,
      errorMessage,
      validateSignerName,
      drawedSignature,
      resetError,
      signedCount,
      signElementCount,
      finishBtnText,
      onFinishBtnClick,
    }
  }
}

export const useSignature = () => {
  const { t } = useI18n()
  return useSignatureFactory(t)()
}
export const useInitials = () => {
  const { t } = useI18n()
  return useSignatureFactory(t)()
}
