import { Fragment, Node, Slice } from '@tiptap/pm/model'

import { Plugin } from '@tiptap/pm/state'
import { EditorView } from '@tiptap/pm/view'
import { Extension } from '@tiptap/vue-3'

export interface NodeStyleProps {
  fontSize?: string
  fontFamily?: string
}

export interface NodePasteOptions {
  paragraph?: NodeStyleProps
  heading?: ({ level: 1 | 2 | 3 | 4 | 5 | 6 } & { options: NodeStyleProps })[]
}

export const NodePasteExtension = Extension.create<NodePasteOptions>({
  name: 'customNodePasteExtension',

  addProseMirrorPlugins() {
    const options = this.options

    const transformNodes = (view: EditorView, slice: Slice) => {
      const newNodes: Node[] = []
      slice.content.forEach(child => {
        const nodes: Node[] = []
        const nodeType = child.type.name as keyof NodePasteOptions

        if (nodeType === 'paragraph' || nodeType === 'heading') {
          let optionsConfig = {}
          if (nodeType === 'heading') {
            const headingLevel =
              options.heading?.find(
                ({ level }) => level === child.attrs.level
              ) || ({ options: {} } as { options: NodeStyleProps })
            optionsConfig = {
              ...optionsConfig,
              ...headingLevel.options,
            }
          } else {
            optionsConfig = {
              ...optionsConfig,
              ...options[nodeType],
            }
          }
          child.content.forEach(innerChild => {
            nodes.push(
              innerChild.mark(
                innerChild.marks.concat(
                  view.state.schema.marks.textStyle.create({
                    ...optionsConfig,
                  })
                )
              )
            )
          })
          const newNode = child.type.create(child.attrs, nodes, child.marks)
          newNodes.push(newNode)
        }
      })

      // Create a new fragment with modified nodes
      const newFragment = Fragment.fromArray(newNodes)

      // Create a new slice with the modified fragment
      return new Slice(newFragment, slice.openStart, slice.openEnd)
    }

    return [
      new Plugin({
        props: {
          handlePaste: (view, event, slice) => {
            const html = event?.clipboardData?.getData('text/html')

            if (html) {
              const newSlice = transformNodes(view, slice)

              // Update the editor with the new slice
              const tr = view.state.tr.replaceSelection(newSlice)
              view.dispatch(tr)
              view.focus()
            }
            return true
          },
          handleDOMEvents: {
            input: view => {
              const { state, dispatch } = view
              const { tr, selection } = state

              const slice = selection.content()
              const newSlice = transformNodes(view, slice)

              // Update the editor with the new slice
              tr.replaceSelection(newSlice)
              dispatch(tr)
              view.focus()

              return true
            },
          },
        },
      }),
    ]
  },
})

export const NodePasteExtensionWithConfig = NodePasteExtension.configure({
  paragraph: {
    fontSize: '16px',
    fontFamily: 'sans-serif',
  },
  heading: [
    {
      level: 1,
      options: {
        fontSize: '32px',
        fontFamily: 'sans-serif',
      },
    },
    {
      level: 2,
      options: {
        fontSize: '24px',
        fontFamily: 'sans-serif',
      },
    },
    {
      level: 3,
      options: {
        fontSize: '18.72px',
        fontFamily: 'sans-serif',
      },
    },
    {
      level: 4,
      options: {
        fontSize: '16px',
        fontFamily: 'sans-serif',
      },
    },
    {
      level: 5,
      options: {
        fontSize: '13.28px',
        fontFamily: 'sans-serif',
      },
    },
  ],
})
