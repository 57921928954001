<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import Moveable, {
  BoundType,
  MoveableRefTargetType,
  OnDrag,
  OnScale,
  OnScaleStart,
  OnResizeStart,
  OnDragEnd,
  SnapDirections,
  OnResizeEnd,
  OnDragStart,
  OnResize,
} from 'vue3-moveable'
import {
  MovableComponentEvents,
  MoveableComponentDirections,
} from '../../types'
import { calculatePercentage, parseTransformValues } from '../../utils'
import { MaybeElementRef, onClickOutside } from '@vueuse/core'
import { useMovable } from '../../composition/useMovable'
import { ELEMENTS_LOOKUP, OLD_PAGE_MARGIN } from '../..'

const emit = defineEmits<{
  (
    event: MovableComponentEvents.ON_DRAG,
    payload: {
      top: number
      left: number
      height: number
      width: number
      bottom: number
    },
    target: HTMLElement
  ): void
  (
    event: MovableComponentEvents.ON_RESIZE,
    payload: { width: number; height: number; transform: string },
    target: HTMLElement
  ): void
  (
    event: MovableComponentEvents.ON_SCALE,
    scale: { scaleX: number; scaleY: number },
    target: HTMLElement
  ): void
  (event: MovableComponentEvents.ON_COPY): void
  (event: MovableComponentEvents.ON_DELETE): void
  (
    event: MovableComponentEvents.ON_ACTIVE,
    e: MouseEvent | any,
    isActive: boolean
  ): void
  (event: MovableComponentEvents.ON_MOUSE_ENTER): void
  (event: MovableComponentEvents.ON_MOUSE_LEAVE): void
  (event: MovableComponentEvents.ON_FOCUS, isFocused: boolean): void
  (event: MovableComponentEvents.ON_DRAG_UPDATE, isDragging: boolean): void
  (
    event: MovableComponentEvents.ON_DRAG_END,
    parentInfo: { id?: string; type?: string; pageId?: string }
  ): void
}>()
const props = withDefaults(
  defineProps<{
    pageIndex?: string
    pageId?: string
    snapDirections?: SnapDirections
    elementSnapDirections?: SnapDirections
    isActiveElement: boolean
    keepRatio?: boolean
    snapContainer?: string
    edgeDraggable?: boolean
    snappable?: boolean
    snapThreshold?: number
    position: {
      top: number
      left: number
      bottom: number
      preferBottom?: boolean
      right: number
      preferRight?: boolean
    }
    scale: { scaleX: number; scaleY: number }
    dimensions?: { height: number | string; width: number | string }
    id: string
    isGhost: boolean
    isEditable: boolean
    isDragElement: boolean
    align?: 'left' | 'right' | 'center'
    resizable?: boolean
    renderDirections?: MoveableComponentDirections[]
    minHeight?: number
    minWidth?: number
    maxHeight?: number
    maxWidth?: number
    colorOverride?: string
    inactiveColorOverride?: string
    useMinHeight?: boolean
    ignoreOnClick?: (MaybeElementRef | string)[]
    withDoubleClick?: boolean
    isSelectoField?: boolean
    elementGroup?: any
    disableNestedMoveable?: boolean
    boundContainer: string
  }>(),
  {
    isDragElement: false,
    isEditable: false,
    isGhost: false,
    isBlockElement: true,
    keepRatio: true,
    snapContainer: '.snapContainer',
    boundContainer: '.snapContainer',
    edgeDraggable: false,
    snappable: true,
    snapThreshold: 5,
    resizable: false,
    colorOverride: '#4af',
    inactiveColorOverride: '#4af',
    renderDirections: () => {
      // return ['nw', 'ne', 'se', 'sw']
      return ['se']
    },
    ignoreOnClick: () => [],
    withDoubleClick: false,
    isSelectoField: false,
    disableNestedMoveable: false,
  }
)

const directions = computed(() => ({
  top: true,
  left: true,
  bottom: true,
  right: true,
}))
const bounds = computed(
  () =>
    ({
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      position: 'css',
    } as BoundType)
)
// eslint-disable-next-line vue/no-dupe-keys
const elementSnapDirections = computed(() => {
  return {
    top: true,
    left: true,
    bottom: true,
    right: true,
    center: true,
    middle: true,
  }
})

const { setMovableRef, setTooltipRef } = useMovable()
const wrapperRef = ref()
const isDoubleClicked = ref<boolean>(false)
const isActive = ref<boolean>(true)
const targetRef = ref<HTMLElement>()
const tooltipRef = ref<HTMLElement>()
const moveableRef = ref<any>()
const ableRef = ref()
const groupRef = ref()
const positionRef = ref(
  props.position || {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    preferBottom: false,
    preferRight: false,
  }
)
const scaleRef = ref(props.scale || { scaleX: 1, scaleY: 1 })
const isClicked = ref(false)
const isDragging = ref(false)
const isResizing = ref(false)
const setTargetRef = () => {
  isClicked.value = true
}
const enablePopup = ref(true)

const unsetTargetRef = () => {
  isClicked.value = false
  isDoubleClicked.value = false
  emit(MovableComponentEvents.ON_FOCUS, false)
}

watch(
  props,
  updatedProps => {
    isActive.value = updatedProps.isActiveElement
    if (isActive.value) {
      setTargetRef()
    } else {
      unsetTargetRef()
      moveableRef.value?.updateRect()
    }
    positionRef.value = updatedProps.position
    scaleRef.value = updatedProps.scale
  },
  { immediate: true, deep: true }
)

const showPopup = () => {
  if (props?.isSelectoField && props?.elementGroup) {
    enablePopup.value = false
  } else {
    enablePopup.value = true
  }
}

const onKeyPress = (e: KeyboardEvent) => {
  if (isActive.value) {
    e.stopPropagation()
    let increment = 1
    if (e.ctrlKey) {
      increment = 4
    }
    if (e.code === 'ArrowUp' && e.shiftKey) {
      moveableRef.value.request(
        'draggable',
        {
          deltaY: -increment,
        },
        true
      )
    }
    if (e.code === 'ArrowDown' && e.shiftKey) {
      moveableRef.value.request(
        'draggable',
        {
          deltaY: increment,
        },
        true
      )
    }
    if (e.code === 'ArrowLeft' && e.shiftKey) {
      moveableRef.value.request(
        'draggable',
        {
          deltaX: -increment,
        },
        true
      )
    }
    if (e.code === 'ArrowRight' && e.shiftKey) {
      moveableRef.value.request(
        'draggable',
        {
          deltaX: increment,
        },
        true
      )
    }
  }
}

onMounted(() => {
  window.addEventListener('keydown', onKeyPress)
  setTooltipPosition({
    top: props.position.top,
    left: props.position.left,
    height: (props.dimensions?.height as number) || 20,
    width: (props.dimensions?.width as number) || 130,
  })

  onClickOutside(
    wrapperRef,
    (e: any) => {
      unsetTargetRef()
      emit(MovableComponentEvents.ON_ACTIVE, e, false)
    },
    {
      capture: false,
      ignore: [
        `.v-binder-follower-container`,
        '.moveable-wrapper--ables',
        '#active-elements-drawer',
        '.custom-popover-options',
        '.v-binder-follower-content',
        ...props.ignoreOnClick,
      ],
    }
  )
  setMovableRef(props.id, moveableRef)
  setTooltipRef(props.id, tooltipRef)
  showPopup()
})

onUnmounted(() => {
  window.removeEventListener('keydown', onKeyPress)
})

const onClick = (e: MouseEvent) => {
  e?.stopPropagation()
  if (!props.isDragElement) {
    return
  }

  if (props.isEditable) {
    showPopup()
    setTargetRef()
    emit(MovableComponentEvents.ON_ACTIVE, e, true)
    if (isActive.value && isClicked.value) {
      onDoubleClick()
    }
  }
  adjustAblePosition()
}

const adjustAblePosition = () => {
  if (moveableRef.value) {
    const { top, left, height, width } = moveableRef.value.getRect()
    setTooltipPosition({ top, left, height, width })
  }
}
const offsetX = ref(0)
const offsetY = ref(0)
const initialParentContainerType = ref<string | null>(null)
const onDrag = (e: OnDrag) => {
  e.target.style.width = `${e.width}px`
  e.target.style.height = `${e.height}px`
  e.target.style.transform = e.transform
  emit(MovableComponentEvents.ON_ACTIVE, e, true)

  if (!props.disableNestedMoveable) {
    clone.value?.style.setProperty('opacity', '0.6')
    clone.value?.style.setProperty('width', `${e.width}px`)
    clone.value?.style.setProperty(
      'left',
      `${e.clientX + window.scrollX - offsetX.value}px`
    )
    clone.value?.style.setProperty(
      'top',
      `${e.clientY + window.scrollY - offsetY.value}px`
    )
    clone.value?.style.setProperty('height', `${e.height}px`)
  }
  const elementRect = e.target.getBoundingClientRect()
  offsetX.value = e.clientX - elementRect.left
  offsetY.value = e.clientY - elementRect.top
  e.moveable.forceUpdate()
}

const clone = ref<HTMLElement | null>(null)

const onDragStart = (e: OnDragStart) => {
  if (!props.disableNestedMoveable) {
    e?.inputEvent?.stopImmediatePropagation()
    clone.value = e.target.cloneNode(true) as HTMLElement
    clone.value.style.opacity = '0'
    document.body.appendChild(clone.value)
  }

  emit(MovableComponentEvents.ON_DRAG_UPDATE, true)
  isDragging.value = true
  if (!props.disableNestedMoveable) {
    const parentInfo = getNearestSnap(e.target)
    initialParentContainerType.value = parentInfo.type
  }
  offsetX.value = e.clientX - e.target.getBoundingClientRect().left
  offsetY.value = e.clientY - e.target.getBoundingClientRect().top
}

const getNearestSnap = (targetElement: HTMLElement) => {
  const divs = document.querySelectorAll(`${props.snapContainer} .boundBox`)

  let nearestDiv: HTMLElement | null = null

  const targetRect = targetElement.getBoundingClientRect()
  const {
    top: targetTop,
    left: targetLeft,
    bottom: targetBottom,
    right: targetRight,
  } = targetRect

  const findNearestDiv = (element: HTMLElement) => {
    const rect = element.getBoundingClientRect()

    // Check if the top-left corner of the target element is within this element
    const isInside =
      targetLeft >= rect.left &&
      targetTop + 1 >= rect.top &&
      rect.bottom >= targetBottom + 1 &&
      rect.right >= targetRight

    if (isInside) {
      nearestDiv = element
      element
        .querySelectorAll(`${props.snapContainer}`)
        .forEach(child => findNearestDiv(child as HTMLElement))
    }
  }

  divs.forEach(div => {
    if (div && div.style) {
      div.style.outline = 'none'
    }
    findNearestDiv(div as HTMLElement)
  })

  if (!nearestDiv) {
    nearestDiv = document.querySelector(`${props.snapContainer}`)
  }

  const id = (nearestDiv as unknown as HTMLElement)?.getAttribute('data-id')
  const type = (nearestDiv as unknown as HTMLElement)?.getAttribute('data-type')
  const pageId = (nearestDiv as unknown as HTMLElement)?.getAttribute(
    'data-page-id'
  )
  /**
   * @description This code is only for debugging purpose. DO NO REMOVE
   * Highlight the page element when the target element is a page
   *
  
  if (nearestDiv && (nearestDiv as HTMLElement).style) {
    nearestDiv.style.outlineOffset = '-1px'
    nearestDiv.style.borderRadius = '3px'
    nearestDiv.style.outline = '1px dashed #fad1d1'
  }

  if (
    type === ELEMENTS_LOOKUP.PAGE &&
    (nearestDiv as unknown as HTMLElement).style
  ) {
    nearestDiv.style.outline = '1px dashed #e4fad1'
    nearestDiv.style.outlineOffset = '-1px'
    nearestDiv.style.borderRadius = '3px'
  }
  **/
  return {
    id,
    type,
    pageId,
    div: nearestDiv as unknown as HTMLDivElement,
  }
}

const checkIfPreferBottom = (target: HTMLElement, topPosition: number) => {
  const rect = target?.getBoundingClientRect()
  if (!rect) {
    return false
  }
  const percentage = calculatePercentage(rect?.height, 60)
  return topPosition >= percentage
}

const checkIfPreferRight = (target: HTMLElement, leftPosition: number) => {
  const rect = target?.getBoundingClientRect()
  if (!rect) {
    return false
  }
  const percentage = calculatePercentage(rect?.width, 50)
  return leftPosition >= percentage
}

const onDragEnd = (event: OnDragEnd) => {
  if (!props.disableNestedMoveable) {
    document.body.removeChild(clone.value as HTMLElement)
  }

  if (event.lastEvent) {
    const { target, top, left, height, width, bottom, right } = event.lastEvent
    if (!props.disableNestedMoveable) {
      const parentInfo = getNearestSnap(event?.target as HTMLElement)
      const rect = parentInfo.div?.getBoundingClientRect()
      let snappedTop = 0
      let snappedRight = 0
      let snappedLeft = 0
      let snappedBottom = 0
      if (
        parentInfo.type !== ELEMENTS_LOOKUP.PAGE ||
        initialParentContainerType.value !== ELEMENTS_LOOKUP.PAGE
      ) {
        snappedTop =
          event?.inputEvent?.clientY -
          (rect?.top || 0) +
          (parentInfo.div?.scrollTop || 0) -
          offsetY.value
        snappedLeft =
          event?.inputEvent?.clientX -
          (rect?.left || 0) +
          (parentInfo.div?.scrollLeft || 0) -
          offsetX.value
        snappedBottom = rect?.height - (snappedTop + height)
        snappedRight = rect?.width - (snappedLeft + width)
      } else {
        snappedTop = top
        snappedLeft = left
        snappedBottom = bottom
        snappedRight = right
      }

      if (
        initialParentContainerType.value !== ELEMENTS_LOOKUP.PAGE &&
        parentInfo.type === ELEMENTS_LOOKUP.PAGE
      ) {
        snappedBottom = snappedBottom + OLD_PAGE_MARGIN
        snappedLeft = snappedLeft + OLD_PAGE_MARGIN
        snappedRight = snappedRight + OLD_PAGE_MARGIN
        snappedTop = snappedTop + OLD_PAGE_MARGIN
      }
      emit(MovableComponentEvents.ON_DRAG_END, parentInfo)
      emit(
        MovableComponentEvents.ON_DRAG,
        {
          top: snappedTop < -1 ? 0 : snappedTop,
          left: snappedLeft < 0 ? 0 : snappedLeft,
          bottom: snappedBottom,
          right: snappedRight < 0 ? 0 : snappedRight,
          height,
          width,
          preferBottom: checkIfPreferBottom(parentInfo.div, snappedTop),
          preferRight: checkIfPreferRight(parentInfo.div, snappedLeft),
        },
        target
      )
      offsetX.value = 0
      offsetY.value = 0
      moveableRef?.value?.updateRect()
      showPopup()
      adjustAblePosition()
    } else {
      emit(
        MovableComponentEvents.ON_DRAG,
        {
          top,
          left,
          height,
          width,
          bottom,
        },
        target
      )
      showPopup()
      adjustAblePosition()
    }
    moveableRef?.value?.updateRect()
  }
  isDragging.value = false
  emit(MovableComponentEvents.ON_DRAG_UPDATE, false)
}

const resizeDeltaX = ref(0)
const resizeDeltaY = ref(0)
const onResize = (e: OnResize) => {
  e.target.style.width = `${e.width}px`
  e.target.style.height = `${e.height}px`
  e.target.style.transform = e.drag.transform
  const deltaX = e.width - resizeDeltaX.value
  const deltaY = e.height - resizeDeltaY.value
  if (props.position.preferRight) {
    e.target.style.left = props.position.left + 'px'
    e.target.style.right =
      (deltaX > 0
        ? props.position.right - Math.abs(deltaX)
        : props.position.right + Math.abs(deltaX)) + 'px'
  }
  if (props.position.preferBottom) {
    e.target.style.top = props.position.top + 'px'
    e.target.style.bottom =
      (deltaY > 0
        ? props.position.bottom - Math.abs(deltaY)
        : props.position.bottom + Math.abs(deltaY)) + 'px'
  }

  emit(
    MovableComponentEvents.ON_RESIZE,
    {
      width: e.width,
      height: e.height,
      transform: e.drag.transform,
    },
    e.target
  )
}

const onScale = (e: OnScale) => {
  const { scale } = parseTransformValues(e.transform)
  emit(MovableComponentEvents.ON_SCALE, scale, e.target)
}

const styles = computed(() => {
  const stylesObj: Record<string, string> = {}
  if (scaleRef.value) {
    stylesObj[
      'transform'
    ] = `scale(${scaleRef.value.scaleX}, ${scaleRef.value.scaleY})`
  }
  if (positionRef.value) {
    if (
      Object.hasOwnProperty.call(positionRef.value, 'preferBottom') &&
      positionRef.value.preferBottom
    ) {
      stylesObj['bottom'] = `${positionRef.value?.bottom}px`
    } else {
      stylesObj['top'] = `${positionRef.value.top}px`
    }

    if (
      Object.hasOwnProperty.call(positionRef.value, 'preferRight') &&
      positionRef.value.preferRight
    ) {
      stylesObj['right'] = `${positionRef.value.right}px`
    } else {
      stylesObj['left'] = `${positionRef.value.left}px`
    }
    stylesObj['zIndex'] = '100'
  }
  if (props.dimensions) {
    if (props.dimensions.height !== 'auto') {
      stylesObj['height'] = `${props.dimensions.height}px`
    }
    if (props.dimensions.width !== 'auto') {
      stylesObj['width'] = `${props.dimensions.width}px`
    }
  }
  return stylesObj
})
const elementGuidelines = computed(() => ['.el-wrapper', 'section'])
const onMouseEnter = () => {
  if (!isClicked.value) {
    isActive.value = true
  }
}
const onMouseLeave = () => {
  if (!props.disableNestedMoveable) {
    if (!isClicked.value) {
      isActive.value = false
    }
  }
}
const onScaleStart = (e: OnScaleStart) => {
  e.setMinScaleSize([15, 15])
}
const onResizeStart = (e: OnResizeStart) => {
  e.setMin([props.minWidth || 15, props.minHeight || 15])
  e.setMax([props.maxWidth || '100%', props.maxHeight || '100%'])
  isResizing.value = true
  resizeDeltaX.value = e.moveable.getRect().width
  resizeDeltaY.value = e.moveable.getRect().height
}
const onResizeEnd = (e: OnResizeEnd) => {
  isResizing.value = false
  const { height, top, left, width } = e.moveable.getRect()
  const deltaX = width - resizeDeltaX.value
  const deltaY = height - resizeDeltaY.value
  const right =
    deltaX > 0
      ? props.position.right - Math.abs(deltaX)
      : props.position.right + Math.abs(deltaX)

  const bottom =
    deltaY > 0
      ? props.position.bottom - Math.abs(deltaY)
      : props.position.bottom + Math.abs(deltaY)
  setTooltipPosition({ top, left, height, width })

  e.target.style.removeProperty('inset')
  if (props.position.preferRight) {
    e.target.style.right = right + 'px'
  } else {
    e.target.style.left = props.position.left + 'px'
  }
  if (props.position.preferBottom) {
    e.target.style.bottom = bottom + 'px'
  } else {
    e.target.style.top = props.position.top + 'px'
  }
  emit(
    MovableComponentEvents.ON_DRAG,
    {
      ...props.position,
      right,
      bottom,
    },
    e.target
  )
  resizeDeltaX.value = 0
  resizeDeltaY.value = 0
  offsetX.value = 0
  offsetY.value = 0
  e.moveable.forceUpdate()
}

const onDoubleClick = () => {
  if (isActive.value && targetRef.value) {
    const textareaOrInput = targetRef.value.querySelector('textarea')
    textareaOrInput?.focus()
    emit(MovableComponentEvents.ON_FOCUS, true)
    isDoubleClicked.value = true
  }
}

const setTooltipPosition = ({
  top,
  left,
  height,
  width,
}: {
  top: number
  height: number
  left: number
  width: number
}) => {
  if (tooltipRef.value) {
    tooltipRef.value.style.top = `${top + height + 15}px`
    tooltipRef.value.style.left = `${left + 15}px`
  }
  if (ableRef.value) {
    ableRef.value.style.top = `${top - 15}px`
    ableRef.value.style.right = `calc(100% - ${left + width}px)`
  }

  if (groupRef.value) {
    groupRef.value.style.top = `${top + height + 2}px`
    groupRef.value.style.right = `calc(100% - ${left + width / 2 + 15}px)`
  }
}
const showBorders = computed(() => {
  return isActive.value || isClicked.value ? 'unset' : 'none'
})
</script>

<template>
  <div
    v-if="props.isDragElement"
    :id="`moveable-container-${id}`"
    :class="`moveable__container`"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    ref="wrapperRef"
  >
    <div
      :data-page-index="pageIndex"
      :data-page-id="pageId"
      :data-element-id="id"
      @click="onClick"
      ref="targetRef"
      :style="{
        ...styles,
        opacity: !isDragging ? 1 : 0.6,
      }"
      style="transform-origin': left top;"
      class="moveable-component--wrapper"
      :class="{
        'select-o-field': props.isSelectoField,
        'not-editable': !props.isEditable,
        'flex flex-1': true,
        absolute: isGhost,
        'cursor-move': props.isEditable,
        'shadow-float !cursor-move': isDragging,
        ...(props.withDoubleClick
          ? {
              'not-active': !isDoubleClicked,
            }
          : {
              'not-active': !isClicked,
            }),
      }"
    >
      <slot name="component"></slot>
    </div>

    <div
      ref="ableRef"
      :style="{
        display:
          enablePopup && isClicked && !isDragging && !isResizing
            ? 'flex'
            : 'none',
        zIndex: 101,
        position: 'absolute',
        willChange: 'position',
      }"
      class="moveable-wrapper--ables actions absolute -mt-8 flex"
    >
      <slot name="popup-options"></slot>
    </div>
    <div
      ref="tooltipRef"
      :class="{
        hidden: isClicked || isResizing || isDragging,
      }"
      :style="{
        zIndex: 101,
        position: 'absolute',
        willChange: 'position',
        transform: 'translateX(0%) translateY(100%)',
        transition: 'opacity 0.15s ease 0s',
        boxShadow:
          'rgba(47, 47, 47, 0.04) 0px 0px 2px 0px, rgba(47, 47, 47, 0.12) 0px 2px 8px 0px',
        whiteSpace: 'nowrap',
      }"
      class="moveable--tooltip bg-white actions absolute -mt-8 flex"
    >
      <div class="flex justify-between rounded-sm animate-slideDown">
        <slot name="tooltip"></slot>
      </div>
    </div>

    <div
      ref="groupRef"
      :style="{
        display:
          enablePopup && isClicked && !isDragging && !isResizing
            ? 'flex'
            : 'none',
        zIndex: 101,
        position: 'absolute',
        willChange: 'position',
      }"
      class="moveable-wrapper--ables flex"
    >
      <slot name="group-options"></slot>
    </div>
    <Moveable
      :key="`moveable-component-${id}`"
      ref="moveableRef"
      :className="`moveable ${
        isClicked || isActiveElement ? 'ghl-block' : 'ghl-none'
      }`"
      :target="(targetRef as MoveableRefTargetType)"
      :draggable="isEditable && isActive"
      :throttleDrag="1"
      :scalable="isEditable && isClicked"
      :snapContainer="props.snapContainer"
      :rootContainer="props.snapContainer"
      :edgeDraggable="false"
      :throttleScale="0"
      :snappable="true"
      :snapGap="true"
      :snapDirections="directions"
      :snapThreshold="props.snapThreshold"
      :bounds="bounds"
      :elementSnapDirections="elementSnapDirections"
      :elementGuidelines="elementGuidelines"
      :isDisplaySnapDigit="false"
      :isDisplayInnerSnapDigit="false"
      :origin="false"
      :keepRatio="props.keepRatio"
      :useAccuratePosition="true"
      :resizable="isEditable && resizable"
      @drag="onDrag"
      @resize="onResize"
      @scale="onScale"
      @scaleStart="onScaleStart"
      @resizeStart="onResizeStart"
      @resizeEnd="onResizeEnd"
      @dragEnd="onDragEnd"
      @dragStart="onDragStart"
      :renderDirections="(renderDirections as string[])"
    />
  </div>
  <slot v-else name="component"></slot>
</template>

<style lang="scss">
.moveable__container {
  .moveable-component--wrapper {
    &.not-active {
      .el-wrapper {
        &::before {
          position: absolute;
          content: '';
          background-color: transparent;
          cursor: pointer;
          height: 100%;
          width: 100%;
          z-index: 102;
        }
      }
    }
  }
  .moveable-control-box {
    z-index: 100 !important;
    &.ghl-none {
      .moveable-line {
        background: v-bind('props.inactiveColorOverride') !important;
      }
    }
    .moveable-line {
      background: v-bind('props.colorOverride') !important;
      height: 1.5px;
      width: 1.5px;
      display: v-bind('showBorders');
    }
    .moveable-control.moveable-direction.moveable-resizable {
      background: v-bind('props.colorOverride') !important;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
    }
    &.ghl-block {
      .moveable-control.moveable-direction.moveable-resizable {
        display: block !important;
      }
    }
    &.ghl-none {
      .moveable-control.moveable-direction.moveable-resizable {
        display: none !important;
      }
    }
  }
  &:hover {
    .moveable-control-box.ghl-none {
      .moveable-control.moveable-direction.moveable-resizable {
        display: block !important;
      }
    }
  }
  .moveable--tooltip {
    opacity: 0;
    pointer-events: none;
  }
  &:hover {
    .moveable--tooltip {
      opacity: 1;
      pointer-events: all;
    }
  }
}
</style>
