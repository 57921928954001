<script setup lang="ts"></script>

<template>
  <div class="c-wrapper">
    <slot></slot>
  </div>
</template>

<style lang="scss">
.c-wrapper {
  width: 100%;
}
</style>
