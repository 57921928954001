import { v4 as uuidv4 } from 'uuid'
import {
  ELEMENTS_LOOKUP,
  ELEMENTS_META,
  ELEMENTS_STYLES,
} from '../const/elements'
import {
  ELEMENT_GROUP_LOOKUP_MAP,
  GROUP_LOOKUP,
  GROUP_NAME,
  GROUP_RULE_OPTION_VALUES,
  groupRuleOptions,
} from '../const/groups'
import { GroupFields } from '../types/common'
import {
  GroupFieldsOptions,
  IElement,
  IRecipient,
  ISelectedGroupElement,
} from '../types/elements'
import { getNextIdForGroup } from './fieldCounter'
import { fetchPrimaryRecipient } from './helpers'

interface IGroupChildren {
  pageId: string
  elementId: string
}

export const generateId = () => {
  return uuidv4()
}

export const generateElementId = (): string => generateId()

export const createGroup = ({
  groupType,
  children,
  recipient,
  currentGroups,
  rule,
  ruleValue,
}: {
  groupType: string
  children: { pageId: string; elementId: string }[]
  recipient: IRecipient | null | undefined
  currentGroups: GroupFields<GroupFieldsOptions>[]
  rule?: string
  ruleValue?: string
}): GroupFields<GroupFieldsOptions> => {
  const recipientId = recipient?.id || null
  const entityName = recipient?.entityName || null
  const fieldId = getNextIdForGroup(currentGroups, groupType)
  return {
    type: GROUP_LOOKUP.GROUP_FIELDS,
    version: 1,
    id: generateElementId(),
    component: {
      name: GROUP_NAME[GROUP_LOOKUP.CHECKBOX_GROUP],
      options: {
        groupType,
        [ELEMENTS_META.FIELD_ID]: fieldId,
        [ELEMENTS_META.RECIPIENT]: recipientId,
        [ELEMENTS_META.TIMESTAMP]: null,
        [ELEMENTS_META.ENTITY_NAME]: entityName,
        rule: rule || GROUP_RULE_OPTION_VALUES.SELECT_AT_MOST,
        ruleValue: ruleValue || '1',
      },
    },
    responsiveStyles: {
      large: {
        [ELEMENTS_STYLES.PADDING_TOP]: `0px`,
        [ELEMENTS_STYLES.PADDING_BOTTOM]: `0px`,
        [ELEMENTS_STYLES.PADDING_LEFT]: `0px`,
        [ELEMENTS_STYLES.PADDING_RIGHT]: `0px`,
        [ELEMENTS_STYLES.MARGIN_TOP]: null,
        [ELEMENTS_STYLES.MARGIN_BOTTOM]: null,
        [ELEMENTS_STYLES.MARGIN_LEFT]: null,
        [ELEMENTS_STYLES.MARGIN_RIGHT]: null,
        dimensions: {
          width: 0,
          height: 0,
        },
      },
    },
    children,
  }
}

export const fetchExistingGroup = ({
  currentGroups,
  group,
}: {
  currentGroups: GroupFields<GroupFieldsOptions>[]
  group: ISelectedGroupElement[]
}) => {
  const groupIdMap: Record<
    string,
    Array<{ pageId: string; elementId: string }>
  > = {}
  const f = currentGroups.find(g => {
    groupIdMap[g.id] = g.children
    const cgChild = g.children.map((child: any) => child.elementId)
    const gIds = group.map(element => element.elementId)
    const exist =
      gIds.every(id => cgChild.includes(id)) && cgChild.length === gIds.length
    if (exist) return g
  })
  return f
}

export const getTypeAndChildDataForGroup = (
  params: {
    pageId: string
    pageIndex?: string
    elementId: string
  }[],
  pages: IElement[]
) => {
  const children: { pageId: string; elementId: string }[] = []
  const type = {}
  let elementType = ''
  params.forEach(({ elementId }) => {
    pages?.forEach(page => {
      page?.children?.forEach(element => {
        if (element?.id === elementId) {
          type[element.type] = true
          children.push({ pageId: page?.id, elementId })
        }
      })
    })
  })
  const typeKeys = Object.keys(type)
  if (typeKeys.length === 1) {
    elementType = typeKeys[0]
  }
  return {
    elementType,
    children,
    typeKeys,
    groupType: ELEMENT_GROUP_LOOKUP_MAP[elementType],
  }
}

export const getPageAndElementMap = (
  elements: IElement[],
  pages: IElement[]
): ISelectedGroupElement[] | any => {
  const map =
    elements?.map((elem: IElement) => {
      const page = pages.find(page => {
        return page.children?.some(element => {
          return element.id === elem.id
        })
      })
      const pageIndex = pages.findIndex(p => p.id === page?.id)
      if (page && page?.id && elem && elem?.id) {
        return { pageId: page.id, elementId: elem.id, pageIndex }
      }
    }) || []
  return map
}

export const isElementPartOfAnyGroup = (
  currentGroups: GroupFields<GroupFieldsOptions>[],
  elementId: string
) => {
  const f = currentGroups.find(g => {
    const cgChild = g.children.map((child: any) => child.elementId)
    if (cgChild.includes(elementId)) {
      return g
    }
  })
  return f
}

export const getPageIdForElement = (pages: IElement[], elementId: string) => {
  const page = pages.find(page => {
    return page.children?.some(element => {
      return element.id === elementId
    })
  })
  if (page && page?.id) return page?.id
  return
}

export const getPageIndex = (pages: IElement[], pageId: string) => {
  return pages.findIndex(page => page.id === pageId)
}

export const getGroupInfoWithElements = (
  currentGroups: GroupFields<GroupFieldsOptions>[],
  selected: Element[]
) => {
  const selectedElementsMap = selected?.map(ev => {
    return ev.getAttribute('data-element-id')
  })
  return getGroupInfoWithSelectedElements(currentGroups, selectedElementsMap)
}

export const getGroupInfoWithSelectedElements = (
  currentGroups: GroupFields<GroupFieldsOptions>[],
  selectedElementsMap: string[]
) => {
  let group
  let closestGroup
  if (currentGroups && currentGroups.length > 0) {
    group = currentGroups.find(g => {
      const cgChild = g.children.map((child: any) => child.elementId)
      return (
        selectedElementsMap.every(id => cgChild.includes(id)) &&
        cgChild.length >= selectedElementsMap.length
      )
    })

    const someExist = currentGroups.some(g => {
      const cgChild = g.children.map((child: any) => child.elementId)
      return selectedElementsMap.some(id => cgChild.includes(id))
    })

    if (someExist && !group) {
      closestGroup = currentGroups.find(g => {
        const cgChild = g.children.map((child: any) => child.elementId)
        return selectedElementsMap.some(id => cgChild.includes(id))
      })
    }

    // if the 2 element is part of 2 groups and trying to group.
    const groupConflictMap = currentGroups.reduce((acc, g) => {
      const cgChild = g.children.map((child: any) => child.elementId)
      selectedElementsMap.forEach(value => {
        if (cgChild.includes(value)) {
          if (!acc[g.id]) {
            acc[g.id] = []
          }
          acc[g.id].push(value)
        }
      })
      return acc
    }, {})

    const isConflict = Object.keys(groupConflictMap).length > 1

    return {
      groupConflictMap,
      isConflict,
      group,
      isPartialGroup: someExist,
      selectedElementsMap,
      closestGroup,
    }
  } else {
    return {
      groupConflictMap: {},
      isConflict: false,
      group,
      isPartialGroup: false,
      selectedElementsMap,
      closestGroup,
    }
  }
}

export const isAnyElementPartOfAnyGroup = (
  currentGroups: GroupFields<GroupFieldsOptions>[],
  selectedElements: {
    pageId: string
    elementId: string
    pageIndex?: string
  }[]
) => {
  return currentGroups.find(g => {
    const cgChild = g.children.map((child: any) => child.elementId)
    return selectedElements.some(s => cgChild.includes(s.elementId))
  })
}

export const mergeByElementId = (
  children: IGroupChildren[],
  groupChildren: IGroupChildren[]
): IGroupChildren[] => {
  return [...children, ...groupChildren].filter(
    (elem, index, self) =>
      index === self.findIndex(e => e.elementId === elem.elementId)
  )
}

export const groupRuleValueOptions = (
  group: GroupFields<GroupFieldsOptions>
) => {
  if (group) {
    const options = group?.children?.map((value, index) => {
      return { label: index + 1, value: index + 1 }
    })
    return options
  }
  return []
}

export const getOneRecipientBasedOnAllSelectedElements = (
  groupChildren: IGroupChildren[],
  pages: IElement[],
  recipients: IRecipient[]
) => {
  const elementIds = groupChildren.map(({ elementId }) => elementId)
  const elements = pages.reduce((acc, page) => {
    page.children?.forEach(element => {
      if (elementIds.includes(element.id)) {
        if (
          element.component.options?.recipient &&
          element.component.options?.entityName
        ) {
          acc.push({
            elementId: element.id,
            recipientId: element.component.options.recipient,
            entityName: element.component.options.entityName,
          })
        }
      }
    })
    return acc
  }, [] as any[])

  if (elements.length > 0 && elementIds.length === elements.length) {
    const recipientId = elements[0].recipientId
    const entityName = elements[0].entityName
    const isEqual = elements.every(
      v =>
        v.recipientId === elements[0].recipientId &&
        v.entityName === elements[0].entityName
    )
    if (isEqual) {
      const rec = recipients.find(
        r => r.id === recipientId && r.entityName === entityName
      )
      return rec
    }
  }

  return null
}

export const getRecipientInfo = (
  group: GroupFields<GroupFieldsOptions> | IElement[],
  recipients: IRecipient[]
) => {
  const primaryRecipient = fetchPrimaryRecipient(recipients)
  const recipientId = group?.component?.options?.recipient
  const entityName = group?.component?.options?.entityName
  const isRecipient = recipientId && entityName
  if (isRecipient) {
    return recipients.find(
      r => r.id === recipientId && r.entityName === entityName
    )
  }
  if (primaryRecipient) {
    return primaryRecipient
  }
  return null
}

export const getRules = (
  group: GroupFields<GroupFieldsOptions> | undefined
) => {
  if (group) {
    const rule = group?.component?.options?.rule
    const ruleValue = group?.component?.options?.ruleValue
    if (rule && ruleValue) {
      const options = groupRuleOptions.find(o => o.value === rule)
      if (options) {
        return `${options?.label} ${ruleValue}`
      }
    }
  }
  return ''
}

export const getRequiredValidation = (
  group: GroupFields<GroupFieldsOptions> | null | undefined
) => {
  if (group) {
    const rule = group?.component?.options?.rule
    const ruleValue = group?.component?.options?.ruleValue
    if (rule && ruleValue) {
      if (Number(ruleValue) > 0) {
        if (
          rule === GROUP_RULE_OPTION_VALUES.SELECT_AT_LEAST ||
          rule === GROUP_RULE_OPTION_VALUES.SELECT_EXACTLY
        ) {
          return true
        }
      }
    }
  }
  return false
}

export const isGroupRule = (
  group: GroupFields<GroupFieldsOptions> | null | undefined,
  groupElements: Record<string, IElement[]>
) => {
  if (group) {
    const rule = group?.component?.options?.rule
    const ruleValue = group?.component?.options?.ruleValue
    if (rule && ruleValue && Number(ruleValue) > 0) {
      const elements = groupElements[group.id]
      const completedElements = elements.filter(el => {
        if (
          el.type === ELEMENTS_LOOKUP.CHECKBOX &&
          el.component.options.text &&
          el.component.options.text === 'true'
        ) {
          return el
        }
      })

      if (rule === GROUP_RULE_OPTION_VALUES.SELECT_AT_LEAST) {
        return completedElements.length >= Number(ruleValue) ? true : false
      }

      if (rule === GROUP_RULE_OPTION_VALUES.SELECT_EXACTLY) {
        return completedElements.length === Number(ruleValue) ? true : false
      }

      if (rule === GROUP_RULE_OPTION_VALUES.SELECT_AT_MOST) {
        return completedElements.length <= Number(ruleValue) ? true : false
      }
    }
  }

  return false
}
