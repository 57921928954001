import {
  DEFAULT_PAGE_DIMENSION,
  ELEMENTS_LOOKUP,
  IElement,
  OLD_PAGE_HEIGHT,
  OLD_PAGE_MARGIN,
  PageElementOptions,
  TextFieldElementOptions,
} from '@gohighlevel/ghl-proposals-common'
import { Ref, computed, inject, ref, watch } from 'vue'

import { uniqBy } from 'lodash'

export const useBoundaries = (
  page: IElement<PageElementOptions>,
  pageRef: Ref<HTMLElement | undefined>
) => {
  const pageDOM = ref(pageRef.value)
  watch([pageRef], ([updatedPageRef]) => {
    setTimeout(() => {
      pageDOM.value = updatedPageRef
    }, 200)
  })
  const isPDFView = inject<boolean>('isPDFView')

  const printPageHeight = computed(() =>
    page?.version > 1
      ? DEFAULT_PAGE_DIMENSION.dimensions.height
      : OLD_PAGE_HEIGHT
  )
  const multiple = 20
  const boundaries = computed(() => {
    if (!pageDOM.value) return []
    if (!isPDFView) return []
    return new Array(multiple).fill(0).map((_, i, arr) => {
      if (i === 0) return printPageHeight.value - OLD_PAGE_MARGIN - 22
      if (i === arr.length - 1)
        return printPageHeight.value - OLD_PAGE_MARGIN - 22
      return (printPageHeight.value - OLD_PAGE_MARGIN * 2) * (i + 1) + 22
    })
  })
  return {
    boundaries,
  }
}

export const usePrintAdjustUtils = (
  page: IElement<PageElementOptions>,
  pageRef: Ref<HTMLElement | undefined>,
  parentRef: Ref<HTMLElement | undefined>
) => {
  const isPDFView = inject<boolean>('isPDFView')
  const printPageHeight = computed(() =>
    page?.version > 1
      ? DEFAULT_PAGE_DIMENSION.dimensions.height
      : OLD_PAGE_HEIGHT
  )
  const { boundaries } = useBoundaries(page, pageRef)
  const elRef = ref<HTMLElement | null>(null)
  const pageDOM = ref(pageRef.value)
  const parentDOM = ref(parentRef.value)
  watch(
    [elRef, pageRef, parentRef],
    ([el, updatedPageRef, updatedParentRef]) => {
      setTimeout(() => {
        elRef.value = el
        pageDOM.value = updatedPageRef
        parentDOM.value = updatedParentRef
      }, 200)
    }
  )

  const crossings = computed(() => {
    if (!parentDOM.value) return []
    if (!isPDFView) return []
    const rect = parentDOM?.value?.getBoundingClientRect()
    const type = parentDOM?.value?.getAttribute('data-type')
    const parentPageRect = pageRef?.value?.getBoundingClientRect()
    const crossingsArr: {
      boundary: number
      index: number
      crossingPosition: number
      delta: number
    }[] = []
    boundaries?.value?.forEach((boundary, i) => {
      const parentTop = (rect?.top as number) - (parentPageRect?.top as number)
      const parentBottom = parentTop + (rect?.height as number)

      if (
        (parentTop < boundary && parentBottom > boundary) || // Crosses from top to bottom
        (parentTop > boundary && parentBottom < boundary) // Crosses from bottom to top
      ) {
        const crossingPosition = boundary - parentTop
        // console.log(crossingPosition, getLeafElementFromPoint(0, boundary))
        crossingsArr.push({
          boundary,
          index: i,
          crossingPosition,
          delta: type === ELEMENTS_LOOKUP.TEXT ? 16 : 0,
        })
      }
    })
    return uniqBy(crossingsArr, 'crossingPosition')
  })

  return {
    elRef,
    boundaries,
    crossings,
    printPageHeight,
    parentDOM,
  }
}
export const usePrintAdjust = (
  page: IElement<PageElementOptions>,
  element: IElement<TextFieldElementOptions>,
  pageRef: Ref<HTMLElement | undefined>,
  parentRef: Ref<HTMLElement | undefined>
) => {
  const { crossings, elRef } = usePrintAdjustUtils(page, pageRef, parentRef)

  const shift = computed(() => {
    let displacement = 0
    const h = element?.responsiveStyles.large.dimensions?.height as number
    for (let i = 0; i < crossings.value.length; i++) {
      const top = element?.responsiveStyles.large.position?.top as number
      const { crossingPosition } = crossings.value[i]
      if (top > crossingPosition) {
        if (element?.responsiveStyles.large.position?.preferBottom) {
          displacement = OLD_PAGE_MARGIN * 2 * (i + 1) + h
        } else {
          displacement = OLD_PAGE_MARGIN * 2 * (i + 1) + 11
        }
      }
    }
    return displacement
  })

  const position = computed(() => {
    const top = element?.responsiveStyles.large.position?.top as number
    const preferBottom = element?.responsiveStyles.large.position?.preferBottom
    const elPositionMultiple = Math.ceil((top as number) / OLD_PAGE_HEIGHT)

    if (page?.version > 1) {
      return {
        top: preferBottom
          ? `translate(0px, ${shift.value}px)`
          : `translate(0px, ${shift.value}px)`,
        bottom: preferBottom ? `translateY(-${shift.value}px)` : 'unset',
      }
    } else {
      if (elPositionMultiple > 1) {
        return {
          top: `${
            (top as number) +
            (elPositionMultiple - 1) * OLD_PAGE_MARGIN * 2 +
            (elPositionMultiple - 1) * (OLD_PAGE_MARGIN - 25)
          }px`,
          bottom: 'unset',
        }
      }
    }

    return { top: `${top as number}px` }
  })

  return {
    elRef,
    position,
  }
}
