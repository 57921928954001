<script setup lang="ts">
import { Editor, EditorContent } from '@tiptap/vue-3'
import {
  FontFamily,
  LineHeight,
  Link,
  RequiredExtensions,
  TextAlign,
  TextBlocks,
  TextSize,
  Underline,
  Color,
} from '@gohighlevel/ghl-text-editor'
import Document from '@tiptap/extension-document'
import Gapcursor from '@tiptap/extension-gapcursor'
import Paragraph from '@tiptap/extension-paragraph'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import Text from '@tiptap/extension-text'
import TextStyle from '@tiptap/extension-text-style'
import StarterKit from '@tiptap/starter-kit'
import { PropType, computed, ref } from 'vue'
import { IElement } from '@gohighlevel/ghl-proposals-common'
import { columnResizing, tableEditing } from '@tiptap/pm/tables'

const editor = ref()
const TableCellAddAttributes: any = {
  addAttributes() {
    return {
      ...this.parent?.(),
      backgroundColor: {
        default: null,
        renderHTML: (attributes: any) => {
          if (!attributes.backgroundColor) {
            return {}
          }
          return {
            style: `background-color: ${attributes.backgroundColor}`,
          }
        },
        parseHTML: (element: any) => {
          return element.style.backgroundColor.replace(/['"]+/g, '')
        },
      },
      borderColor: {
        default: null,
        renderHTML: (attributes: any) => {
          if (!attributes.borderColor) {
            return {}
          }

          return {
            style: `border-color: ${attributes.borderColor}`,
          }
        },
        parseHTML: (element: any) => {
          return element.style.borderColor.replace(/['"]+/g, '')
        },
      },
      colwidth: {
        default: null,
        parseHTML: (element: any) => {
          const colwidth = element.getAttribute('colwidth')
          return colwidth ? colwidth.split(',').map(Number) : null
        },
      },
      width: {
        default: null,
        renderHTML: (attributes: any) => {
          if (!attributes.colwidth) {
            return {}
          }
          return {
            style: `width: ${attributes.colwidth[0]}px`,
          }
        },
        parseHTML: (element: any) => {
          return element.style.width.replace(/['"]+/g, '')
        },
      },
    }
  },
}
const props = defineProps({
  element: {
    type: Object as PropType<IElement>,
    required: true,
  },
})

editor.value = new Editor({
  extensions: [
    Document,
    Paragraph,
    Text,
    Gapcursor,
    Table.configure({
      resizable: true,
    }).extend({
      addProseMirrorPlugins() {
        return [
          columnResizing({
            handleWidth: this.options.handleWidth,
            cellMinWidth: this.options.cellMinWidth,
            View: this.options.View,
            lastColumnResizable: this.options.lastColumnResizable,
          }),
          tableEditing({
            allowTableNodeSelection: this.options.allowTableNodeSelection,
          }),
        ]
      },
    }),
    TableRow,
    TableHeader,
    TableCell.extend(TableCellAddAttributes),
    RequiredExtensions,
    TextBlocks,
    StarterKit,
    TextStyle,
    FontFamily,
    TextAlign.configure({
      types: ['heading', 'paragraph'],
    }),
    Underline,
    Link,
    LineHeight,
    TextSize,
    Color,
  ],
  content: props.element.component.options.text,
  editable: false,
  parseOptions: {
    preserveWhitespace: 'full',
  },
  editorProps: {
    attributes: state => {
      return {
        ...state.doc.attrs,
        style: 'font-family: sans-serif', // Default font-family
      }
    },
  },
})

const customStyleForTextParent = computed(() => {
  const styles = props.element?.responsiveStyles.large
  return {
    marginTop: styles?.marginTop,
    marginBottom: styles?.marginBottom,
    marginLeft: styles?.marginLeft,
    marginRight: styles?.marginRight,
    paddingTop: styles?.paddingTop,
    paddingBottom: styles?.paddingBottom,
    paddingLeft: styles?.paddingLeft,
    paddingRight: styles?.paddingRight,
  }
})
</script>

<template>
  <div style="width: inherit">
    <div
      :style="customStyleForTextParent"
      style="overflow: auto; display: flex"
    >
      <div class="el-wrapper table-el">
        <EditorContent :editor="editor" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.editor-wrapper {
  .tiptap.ProseMirror[contenteditable='false'] {
    white-space: break-spaces !important;
  }
}

.ProseMirror {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 1px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}
.tableWrapper {
  // padding: 1rem 0;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
</style>
