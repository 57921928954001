<script setup lang="ts">
import {
  Download01Icon,
  CheckCircleIcon,
} from '@gohighlevel/ghl-icons/24/outline'

import BaseDocument from './BaseDocument.vue'
import ViewerLayout from '@/components/layouts/ViewerLayout.vue'
import { UIButton, UITextMdNormal, UITextSmRegular } from '@gohighlevel/ghl-ui'
import { useDocument, useInvoice } from '@/composable'
import { onMounted } from 'vue'

const { loading: pdfLoading, downloadProposalPdf } = useDocument()

const {
  loading,
  data,
  checkDocumentStatus,
  downloadInvoicePdf,
  loadingInvoice,
} = useInvoice()

onMounted(() => {
  checkDocumentStatus()
})
</script>

<template>
  <BaseDocument
    :child-loading="loading"
    loaderText="Verifying..."
    :skip-document-fetch="true"
  >
    <ViewerLayout>
      <div class="flex flex-col flex-1 justify-center">
        <img
          src="../assets/payment-success.svg"
          alt="Status Success"
          :style="{ marginBottom: '4em' }"
        />
        <div class="flex flex-col gap-4 items-center">
          <div class="flex items-center">
            <CheckCircleIcon class="h-5 w-5 text-green-500 mr-2" />
            <UITextMdNormal>{{ $t('documentViewer.allDone') }}</UITextMdNormal>
          </div>
          <div class="flex">
            <UIButton
              id="download-pdf"
              @click="
                () =>
                  downloadProposalPdf({
                    documentId: data.documentId,
                    altId: data.locationId,
                    altType: 'location',
                    isPublicRequest: true,
                  })
              "
              :loading="pdfLoading"
            >
              <Download01Icon class="h-4 w-4 mr-2" />
              <UITextSmRegular>
                {{ $t('documentViewer.downloadSignedDocument') }}
              </UITextSmRegular>
            </UIButton>
          </div>
          <div
            class="flex"
            v-if="data.invoiceId && data.invoicePaymentStatus === 'paid'"
          >
            <UIButton
              id="download-pdf"
              @click="
                () =>
                  downloadInvoicePdf({
                    invoiceId: data.invoiceId as string,
                    locationId: data.locationId,
                  })
              "
              :loading="loadingInvoice"
            >
              <Download01Icon class="h-4 w-4 mr-2" />
              <UITextSmRegular>{{
                $t('documentViewer.downloadInvoice')
              }}</UITextSmRegular>
            </UIButton>
          </div>
        </div>
      </div>
    </ViewerLayout>
  </BaseDocument>
</template>
