import { ELEMENTS_LOOKUP } from './elements'

export const GROUP_LOOKUP = {
  GROUP_FIELDS: 'GroupFields',
  CHECKBOX_GROUP: 'CheckboxGroup',
}

export const GROUP_NAME = {
  [GROUP_LOOKUP.CHECKBOX_GROUP]: 'Checkbox Group',
}

export const ELEMENT_GROUP_LOOKUP_MAP = {
  [ELEMENTS_LOOKUP.CHECKBOX]: GROUP_LOOKUP.CHECKBOX_GROUP,
}

export const GROUP_RULE_OPTION_VALUES = {
  SELECT_AT_LEAST: 'select-at-least',
  SELECT_AT_MOST: 'select-at-most',
  SELECT_EXACTLY: 'select-exactly',
}

export const groupRuleOptions = [
  { label: 'Select at least', value: GROUP_RULE_OPTION_VALUES.SELECT_AT_LEAST },
  { label: 'Select at most', value: GROUP_RULE_OPTION_VALUES.SELECT_AT_MOST },
  { label: 'Select exactly', value: GROUP_RULE_OPTION_VALUES.SELECT_EXACTLY },
]

export const GROUP_META = {
  GROUP_TYPE: 'groupType',
  RULE: 'rule',
  RULE_VALUE: 'ruleValue',
}
