import { ObjectForLinkedField } from '../../const'
import { IElement } from '../elements'

export interface ICustomFieldsLink {
  contactId?: string
  type: string
  customFields: ICustomFieldsLinkItem[]
}

export interface ICustomFieldsLinkItem {
  id: string
  fieldKey: string
  fieldLabel: string
  value: string
  dataType: string
  objectType: string
  category: string
}

export interface ICustomFieldsLinkageForm {
  elementId: string
  elementType: string
  documentFieldKey: string
  objectName: ObjectForLinkedField
  customFieldKey: string
  customFieldLabel: string
  customFieldId: string
  customFieldCategory: string
  customFieldType: string
  contactId: string
  entityName: string
  operationType: string
  operationValue?: {
    element?: IElement
    data?: ICustomFieldsLinkItem
    type?: string
  }
}

export enum CustomValueLinkageOperationsEvents {
  CUSTOM_VALUE_LINKAGE_DELETE = 'custom-value-linkage-delete',
  CUSTOM_VALUE_LINKAGE_EDIT = 'custom-value-linkage-edit',
}
