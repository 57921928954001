import {
  DiscountType,
  IElement,
  ITax,
  LineItem,
  ProductListElementOptions,
  calculator,
  getTaxItemName,
} from '@gohighlevel/ghl-proposals-common'

import { computed } from 'vue'

export const useLineItems = (element: IElement<ProductListElementOptions>) => {
  const selectedLineItems = computed(() => {
    if (element?.version > 1) {
      return element?.component?.options?.lineItems.filter(
        ({ selected }) => selected
      )
    }
    return element?.component?.options?.lineItems
  })

  const productListVersion = computed(() => element?.version)

  const subTotal = computed(() => {
    return Number(
      selectedLineItems.value
        ?.reduce((acc: number, { price, qty }: LineItem) => {
          acc += price * qty
          return acc
        }, 0)
        ?.toFixed(2)
    )
  })

  const totalDiscount = computed(() => {
    if (element?.component?.options?.discountType === DiscountType.PERCENTAGE) {
      const { percentageOf } = calculator(subTotal.value, element?.version)
      return Number(
        percentageOf(
          Number(element?.component?.options?.discountValue?.toFixed(2))
        )
      )
    }
    return Number(element?.component?.options?.discountValue?.toFixed(2))
  })

  const amountDue = computed(() => {
    const value = Number(
      (
        subTotal.value -
        totalDiscount.value +
        appliedTax.value.reduce((acc, { tax, taxInclusive }) => {
          return taxInclusive ? acc : acc + tax
        }, 0)
      )?.toFixed(2)
    )
    return value
  })

  const paymentSchedules = computed(
    () => element?.component?.options?.paymentSchedules
  )

  const getDiscountAmountPerItem = (itemPrice: number) => {
    if (element?.component?.options?.discountType === DiscountType.PERCENTAGE) {
      const { percentageOf } = calculator(itemPrice, element?.version)
      return (
        itemPrice -
        percentageOf(Number(element?.component?.options?.discountValue))
      )
    } else {
      const totalDiscount = Number(element?.component?.options?.discountValue)
      const discountRatio = itemPrice / subTotal.value
      const itemDiscount = discountRatio * totalDiscount
      const discountedPrice = itemPrice - itemDiscount
      return discountedPrice
    }
  }

  const appliedTax = computed(() => {
    return Object.values(
      selectedLineItems.value?.reduce((acc, lineItem) => {
        const isTaxInclusive = !!lineItem?.taxInclusive
        let itemPrice = lineItem.price

        if (isTaxInclusive) {
          const totalTaxRate =
            lineItem.taxes?.reduce((acc, tax) => acc + tax.rate, 0) || 0
          const { percentageOf } = calculator(itemPrice, element?.version)

          // Calculate the included tax on the item based on the total tax rate
          const includedTaxOnItem = percentageOf(
            (totalTaxRate / (100 + totalTaxRate)) * 100
          )

          // Calculate the actual item price by subtracting the included tax from the item price
          const actualItemPrice = itemPrice - includedTaxOnItem
          itemPrice = actualItemPrice
        }

        const itemSubTotal = getDiscountAmountPerItem(itemPrice * lineItem.qty)

        const { percentageOf } = calculator(itemSubTotal, element?.version)
        lineItem.taxes?.forEach((tax: ITax) => {
          const taxKey = `${tax._id}_taxInclusive_${isTaxInclusive}`
          if (acc[taxKey]) {
            acc[taxKey] = {
              ...acc[taxKey],
              name: getTaxItemName(tax, isTaxInclusive),
              tax: acc[taxKey]['tax'] + percentageOf(tax.rate),
              taxInclusive: isTaxInclusive,
            }
          } else {
            acc[taxKey] = {
              name: getTaxItemName(tax, isTaxInclusive),
              description: tax.description,
              _id: tax._id,
              rate: tax.rate,
              tax: percentageOf(tax.rate),
              taxInclusive: isTaxInclusive,
            }
          }
        })
        return acc
      }, {} as Record<string, Pick<ITax, '_id' | 'description' | 'name' | 'rate'> & { tax: number; taxInclusive: boolean }>) ||
        []
    )
  })

  return {
    subTotal,
    amountDue,
    totalDiscount,
    paymentSchedules,
    appliedTax,
    productListVersion,
  }
}
