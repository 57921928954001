<script setup lang="ts">
import { UIEmpty } from '@gohighlevel/ghl-ui'
import {
  AlertTriangleIcon,
  RefreshCcw04Icon,
  Pencil02Icon,
} from '@gohighlevel/ghl-icons/24/outline'
import { useRoute } from 'vue-router'
import { router } from '@/router'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const route = useRoute()

defineProps({
  type: {
    type: String,
    default: '404',
  },
  message: {
    type: String,
    default:
      'We apologize for the inconvenience, but we are currently unable to process your request. Please try again later.',
  },
})

const reloadViewerPage = () => {
  router.push({
    name: 'document',
    params: {
      documentId: route?.query?.documentId?.toString(),
      recipientId: route?.query?.recipientId?.toString(),
    },
  })
}
</script>

<template>
  <template v-if="type === '404'">
    <div class="w-screen h-screen flex justify-center items-center">
      <UIEmpty
        id="empty-msg-block"
        :title="route?.query?.message?.toString() || message"
        :icon="AlertTriangleIcon"
      />
    </div>
  </template>
  <template v-if="type === 'draft_state'">
    <div class="w-screen h-screen flex justify-center items-center">
      <UIEmpty
        id="empty-msg-block"
        :title="t('documentViewer.documentInProgress')"
        :description="t('documentViewer.documentUnavailable')"
        :icon="Pencil02Icon"
        :positiveIcon="RefreshCcw04Icon"
        :positiveText="t('documentViewer.reload')"
        @positiveClick="reloadViewerPage"
      />
    </div>
  </template>
</template>
