import { reactive, ref } from 'vue'

import { DocumentServices } from '@/service/DocumentService'
import { DocumentStatus } from '@gohighlevel/ghl-proposals-common'
import { useRoute } from 'vue-router'

const loading = ref(false)
const loadingInvoice = ref(false)
const data = reactive<{
  invoicePaymentStatus: string
  documentStatus: DocumentStatus
  invoiceId?: string
  documentId: string
  locationId: string
  invoiceName?: string
}>({
  invoicePaymentStatus: '',
  documentStatus: DocumentStatus.WAITING_FOR_PAYMENT,
  invoiceId: undefined,
  documentId: '',
  locationId: '',
  invoiceName: '',
})
export const useInvoice = () => {
  const route = useRoute()

  const checkDocumentStatus = async () => {
    try {
      loading.value = true
      const response = await DocumentServices.checkDocumentStatus({
        referenceId: route.params.referenceId as string,
        ...(route?.query?.invoice
          ? { invoiceId: route.query.invoice.toString() }
          : {}),
      })
      data.invoiceId = response.data.invoiceId
      data.documentStatus = response.data.documentStatus
      data.locationId = response.data.locationId
      data.invoicePaymentStatus = response.data.invoicePaymentStatus
      data.documentId = response.data.documentId
      data.invoiceName = response.data?.invoiceName
    } catch (error) {
      console.error(error)
    } finally {
      loading.value = false
    }
  }

  const downloadInvoice = async ({
    locationId,
    invoiceId,
  }: {
    locationId: string
    invoiceId: string
  }) => {
    try {
      loadingInvoice.value = true
      const response = await DocumentServices.downloadInvoice({
        altId: locationId,
        invoiceId,
        altType: 'location',
      })
      return response.data
    } catch (error) {
      console.error(error)
    } finally {
      loadingInvoice.value = false
    }
  }

  const downloadInvoicePdf = async (options: {
    locationId: string
    invoiceId: string
  }) => {
    const response = await downloadInvoice(options)
    if (response?.url) {
      const { url } = response
      const anchor = document.createElement('a')
      anchor.setAttribute('href', url)
      anchor.setAttribute('download', `${data?.invoiceName}.pdf`)
      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(url)
          removeEventListener('click', clickHandler)
        }, 150)
      }
      anchor.addEventListener('click', clickHandler, false)
      anchor.click()
    }
  }

  return {
    loading,
    data,
    checkDocumentStatus,
    downloadInvoicePdf,
    loadingInvoice,
  }
}
