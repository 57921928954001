import { RRuleEndBy, RRuleFrequency } from '../enums'

export const RRuleFrequencyOptions = [
  {
    label: 'Minutely',
    value: RRuleFrequency.MINUTELY,
  },
  {
    label: 'Hourly',
    value: RRuleFrequency.HOURLY,
  },
  {
    label: 'Daily',
    value: RRuleFrequency.DAILY,
  },
  {
    label: 'Weekly',
    value: RRuleFrequency.WEEKLY,
  },
  {
    label: 'Monthly',
    value: RRuleFrequency.MONTHLY,
  },
  {
    label: 'Yearly',
    value: RRuleFrequency.YEARLY,
  },
]

export const RRuleWeekDayOptions = [
  {
    label: '1st',
    value: 1,
  },
  {
    label: '2nd',
    value: 2,
  },
  {
    label: '3rd',
    value: 3,
  },
  {
    label: '4th',
    value: 4,
  },
]

export const RRuleDayOptions = [
  {
    label: 'Mon',
    value: 'monday',
  },
  {
    label: 'Tue',
    value: 'tuesday',
  },
  {
    label: 'Wed',
    value: 'wednesday',
  },
  {
    label: 'Thu',
    value: 'thursday',
  },
  {
    label: 'Fri',
    value: 'friday',
  },
  {
    label: 'Sat',
    value: 'saturday',
  },
  {
    label: 'Sun',
    value: 'sunday',
  },
]

export const RRuleEndByOptions = [
  {
    label: 'Never',
    value: RRuleEndBy.NEVER,
  },
  {
    label: 'By',
    value: RRuleEndBy.AFTER,
  },
  {
    label: 'After',
    value: RRuleEndBy.AFTER,
  },
]
