import { PageSize, PageSizesConfig, Rotation } from '../types'

const inchesToPixels = (inches: number): number => {
  return inches * 96 * 1 // 1 inch = 96 pixels (standard screen DPI)
}

const defaultMargin = 0.5 // in inches

const pageSizesConfig: PageSizesConfig = {
  [PageSize.A4]: {
    dimensions: { width: inchesToPixels(8.25), height: inchesToPixels(11.75) },
    margins: {
      top: inchesToPixels(defaultMargin),
      right: inchesToPixels(defaultMargin),
      bottom: inchesToPixels(defaultMargin),
      left: inchesToPixels(defaultMargin),
    },
    rotation: Rotation.Portrait,
  },
  [PageSize.A5]: {
    dimensions: { width: inchesToPixels(5.8), height: inchesToPixels(8.3) },
    margins: {
      top: inchesToPixels(defaultMargin),
      right: inchesToPixels(defaultMargin),
      bottom: inchesToPixels(defaultMargin),
      left: inchesToPixels(defaultMargin),
    },
    rotation: Rotation.Portrait,
  },
  [PageSize.A3]: {
    dimensions: { width: inchesToPixels(11.75), height: inchesToPixels(16.5) },
    margins: {
      top: inchesToPixels(defaultMargin),
      right: inchesToPixels(defaultMargin),
      bottom: inchesToPixels(defaultMargin),
      left: inchesToPixels(defaultMargin),
    },
    rotation: Rotation.Portrait,
  },
  [PageSize.Letter]: {
    dimensions: {
      width: inchesToPixels(8.5),
      height: inchesToPixels(11),
    },
    margins: {
      top: inchesToPixels(defaultMargin),
      right: inchesToPixels(defaultMargin),
      bottom: inchesToPixels(defaultMargin),
      left: inchesToPixels(defaultMargin),
    },
    rotation: Rotation.Portrait,
  },
  [PageSize.Legal]: {
    dimensions: { width: inchesToPixels(8.5), height: inchesToPixels(14) },
    margins: {
      top: inchesToPixels(defaultMargin),
      right: inchesToPixels(defaultMargin),
      bottom: inchesToPixels(defaultMargin),
      left: inchesToPixels(defaultMargin),
    },
    rotation: Rotation.Portrait,
  },
  [PageSize.Tabloid]: {
    dimensions: { width: inchesToPixels(11), height: inchesToPixels(17) },
    margins: {
      top: inchesToPixels(defaultMargin),
      right: inchesToPixels(defaultMargin),
      bottom: inchesToPixels(defaultMargin),
      left: inchesToPixels(defaultMargin),
    },
    rotation: Rotation.Portrait,
  },
}

export { PageSize, Rotation, pageSizesConfig }
